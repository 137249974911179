<template>
<div class="welcome">
    <img class="img" src="../assets/wel2.png" alt="">
</div>
</template>

<script>

export default {
    created() {
        setTimeout(() => {
            this.$router.push('/home')
        }, 2500);
    },

    methods: {

    }
}
</script>

<style lang="less" scoped>
.welcome {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/wel1.png');
    background-size: 100% 100%;

    .img {
        width: 10rem;
        height: 10rem;
        position: absolute;
        top: 20rem;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>