import i18n from "../i18n/index"

export const menu = [
    {
        text: i18n.t('my.txt3'),
        img: require('../assets/m1.png')
    },
    {
        text: i18n.t('my.txt4'),
        img: require('../assets/m2.png')
    },
    {
        text: i18n.t('my.txt5'),
        img: require('../assets/m3.png')
    },
    {
        text: i18n.t('my.txt6'),
        img: require('../assets/m4.png')
    },
    {
        text: i18n.t('my.txt7'),
        img: require('../assets/m5.png')
    },
    {
        text: i18n.t('my.txt8'),
        img: require('../assets/m6.png')
    },
    {
        text: i18n.t('my.txt9'),
        img: require('../assets/m7.png')
    },
    {
        text: i18n.t('my.txt10'),
        img: require('../assets/m8.png')
    },
]