<template>
  <van-tabbar
    v-model="active"
    route
    active-color="#FE6719"
    inactive-color="#D6D6D6"
    :border="true">
    <van-tabbar-item replace to="/home">
      <img
      style="width: 2.05rem; height: 1.9rem;"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav1.active : icon_nav1.normal"
      />
      {{$t('foot.home')}}
    </van-tabbar-item>
    <van-tabbar-item replace to="/receive">
      <img
      style="width: 2.05rem; height: 1.9rem;"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav2.active : icon_nav2.normal"
      />
      {{$t('foot.yx')}}
    </van-tabbar-item>
    <van-tabbar-item replace to="/racecar">
      <img
        style="width: 2.05rem; height: 1.6rem;"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav3.active : icon_nav3.normal"
      />
      {{$t('foot.jy')}}
      <!-- <div class="."></div> -->
    </van-tabbar-item>
    <van-tabbar-item replace to="/gift">
      <img
      style="width: 2.05rem; height: 1.9rem;"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav4.active : icon_nav4.normal"
      />
      {{$t('foot.tm')}}
    </van-tabbar-item>
    <van-tabbar-item replace to="/my">
      <img
      style="width: 2.05rem; height: 1.9rem;"
        slot="icon"
        slot-scope="props"
        :src="props.active ? icon_nav5.active : icon_nav5.normal"
      />
      {{$t('foot.my')}}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'foOt',
  props: ['back'],
  components: {},
  data () {
    return {
      active: 0,
      icon_nav1: {
        normal: require('./imgimg/home.png'),
        active: require('./imgimg/homes.png')
      },
      icon_nav2: {
        normal: require('./imgimg/active.png'),
        active: require('./imgimg/actives.png')
      },
      icon_nav3: {
        normal: require('./imgimg/game.png'),
        active: require('./imgimg/games.png')
      },
      icon_nav4: {
        normal: require('./imgimg/team.png'),
        active: require('./imgimg/teams.png')
      },
      icon_nav5: {
        normal: require('./imgimg/my.png'),
        active: require('./imgimg/mys.png')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.van-tabbar {
  width: 37.5rem;
  margin: 0 auto !important;
  height: 4.9rem;
  margin-bottom: 1rem;
  // background-color: #1f1f1f;
  box-shadow: 0px -3px 12px 1px rgba(89,121,251,0.1);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  .van-tabbar-item {
    background-color: #fff;
  }
}

.van-hairline--top-bottom::after {
  border: none !important;
}
.van-tabbar--fixed {
  left: 50%;
  transform: translateX(-50%);
}
</style>
