<template>
  <!-- 我的 -->

  <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text='Pull down to refresh' loosing-text="Let it go and update" loading-text='Loading...' pull-distance='100'>
    <div style="min-height: 100vh;"> -->
  <!-- <div class="mybj">
      <img src="../assets/mybj.png" alt="" />
    </div> -->
  <!-- <div class="mybk"> -->
  <!-- 设置和客服-->
  <!-- <div class="mytop"> -->
  <!-- <img src="../assets/mytop1.png" /> -->
  <!--        <img src="../assets/indexkefu.png" @click="tosignin" />
        <img src="../assets/mytop2.png" @click="toinstall" />
      </div>
      <div class="pos">
        <div class="userinfoone">
          <div class="avatar">
            <img :src="imgsrc" alt="" />
          </div>
          <div style="margin-left: 2rem;">
            <div style="font-size: 2rem;">
              {{ user.nickname }}
               <span class="ids">ID:{{ user.id }}</span> -->
  <!-- </div>
            <div style="display: flex;align-items: center;margin-top: .5rem;">
              <div class="id_vip">ID: {{user.id}}</div>
              <div :class="[vip?'vipbox':'vipboxs']">
                <div class="vipleft">
                  <div>LV{{user.level}}</div>
                </div>
                <div class="vipright" v-show="vip">
                  2022-10-10
                </div>
              </div>
            </div>
          </div> -->
  <!-- <div class="service">
            <img src="../assets/service.png" alt=""/>
          </div> -->
  <!--  </div>

      </div>
    </div>
    <div style="background: #fff;padding-top: 2rem;padding-bottom: 8rem;border-top-left-radius: 2.5rem;border-top-right-radius: 2.5rem;" class="toppos"> -->
  <!-- <div style="display: flex;flex-wrap: wrap;justify-content: center;">
        <div class="userinfo">
          <div class="Income">
            <div class="box">
              <div class="title">{{ $t("my.Accumulated") }}</div>
              <div class="green color">{{ parseFloat(user.award_profit) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">{{ $t("my.Today") }}</div>
              <div class="green color">{{ parseFloat(user.today_earn) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">Total <br />revenue</div>
              <div class="title">Income</div>
              <div class="green color">{{ parseFloat(user.profit_return) }}</div>
            </div>
            <div class="border"></div>
            <div class="box">
              <div class="title">{{ $t("my.available") }}</div>
              <div class="green color">{{ parseFloat(user.money) }}</div>
            </div>
          </div>
        </div>
      </div> -->
  <!--      <div class="mywallet">
        <div class="qbtitle">
          <span>{{$t('addmy.mywyi')}}</span><span class="two">{{parseFloat(user.total_balance).toFixed(2)}}</span><span>USDT</span>
        </div> -->
  <!-- <div class="qbcon">
          <div class="conone">
            <div>Today's Earnings：<br />{{parseFloat(user.today_earn)}}</div>
            <div>Total Rewards：<br />{{parseFloat(user.award_profit)}}</div>
          </div>
          <div class="conone" style="margin-left: 2rem;">
            <div>Total Revenue：<br />{{parseFloat(user.profit_return)}}</div>
            <div>Available Balance：<br />{{parseFloat(user.money)}}</div>
          </div>
        </div> -->
  <!--        <div class="qbconer">
          <div class="cononeer">
            <div>{{$t('addmy.mywer')}}{{parseFloat(user.today_earn).toFixed(2)}}</div>
            <div>{{$t('addmy.mywsan')}}{{parseFloat(user.profit_return).toFixed(2)}}</div>
          </div>
          <div class="cononeer">
            <div>{{$t('addmy.mywsi')}}{{parseFloat(user.award_profit).toFixed(2)}}</div>
            <div>{{$t('addmy.mywwu')}}{{parseFloat(user.money).toFixed(2)}}</div>
          </div>
        </div>
        <div class="qbbtn">
          <div class="btn" @click="$router.push('/recharges')">
            <img src="../assets/wallet1.png" />
            {{$t('addmy.mywliu')}}
          </div>
          <div class="btn" @click="towithdraw">
            <img src="../assets/wallet2.png" />
            {{$t('addmy.mywqi')}}
          </div>
        </div>
      </div>
      <div class="Positioning"> -->
  <!-- <div class="install" @click="toinstall">
          <img src="../assets/install.png" alt="" />
        </div> -->
  <!--        <div class="Notice" style="height: 2rem;">
          <div class="img">
            <img src="../assets/notice.png" alt="" />
          </div>
          <van-swipe
            class="my-swipe"
            :autoplay="3000"
            indicator-color="white"
            :show-indicators="false">
            <van-swipe-item v-for="(i, v) in announcement" :key="v">{{
              i
            }}</van-swipe-item>
          </van-swipe>
        </div>
        <div class="content">
          <div class="content-box" @click="tomypurse">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/purse.png" alt="" /></div>
              <div class="huang">{{ $t("my.wallet") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="toproduct">
            <div class="myflex">
              <div class="img imgmargin" style="padding-top: 0.5rem;"><img src="../assets/gpt.png" alt="" /></div>
              <div class="huang">{{ $t("my.mykj") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="tobangzhu">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/mark.png" alt="" /></div>
              <div class="huang">{{ $t("my.center") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="toaddress">
            <div class="myflex">
              <div class="img imgmargin" style="margin-top: 2.5rem;"><img src="../assets/address.png" alt="" /></div>
              <div class="huang" style="margin-top: 0.5rem;">{{ $t("my.address") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="toredemption">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/envelope.png" alt="" style="height: 2.9rem;" /></div>
              <div class="huang">{{ $t("my.envelope") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="tovideo">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Video.png" alt="" /></div>
              <div class="huang">{{ $t("my.Video") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="appxiazai">
            <div class="myflex">
              <div class="img imgmargin" style="padding-top: 0.5rem;"><img src="../assets/app.png" alt="" /></div>
              <div class="huang">{{ $t("my.Download") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="tocompanycetails">
            <div class="myflex">
              <div class="img" style="margin-top: 2.5rem;width: 4rem;height: 4rem;margin-left: 3rem;"><img src="../assets/company.png" alt="" /></div>
              <div class="huang">{{ $t("my.company") }}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="$router.push('/Activity')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/Task.png" alt="" /></div>
              <div class="huang">{{$t('addhome.rw')}}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
          <div class="content-box" @click="$router.push('/Duration')">
            <div class="myflex">
              <div class="img imgmargin"><img src="../assets/timedrown.png" alt="" /></div>
              <div class="huang">{{$t('addmy.myba')}}</div>
            </div> -->
  <!-- <div>
              <img src="../assets/myright.png" style="width: 0.65rem;height: 1.125rem" />
            </div> -->
  <!--          </div>
        </div>
        <div class="bottom" @click="tologin">{{ $t("my.Sign") }}</div>
      </div>
    </div>
    </div>
    </van-pull-refresh> -->
  <div class="my">
    <div class="bg">
      <div class="m-i">
        <div class="yuan">
          <img :src="user.avatar" alt="" class="img" />

        </div>
        <div class="m-r">
          <div class="m-t">
            <div class="name">{{ user.nickname }}</div>
            <div class="vBg" @click="$router.push('/vipLevel')">VIP {{ user.level }}</div>
          </div>
          <div class="id">ID:{{ user.id }}</div>
          <div class="lj">
            <div class="yq">{{ $t("my.txt17") }}：{{ zsinevit }}></div>
            <div
              v-clipboard:copy="inevit"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <img src="../assets/fz.png" alt="" class="img" />
            </div>
          </div>
        </div>
      </div>
      <div class="tx">
        <div class="t-i">
          <div class="bodyone" @click="toRecord()">
            <div class="bodyonediv">
              <img src="../assets/inbodys.png" alt="" />
            </div>
            <div>{{ $t("my.txt1") }}</div>
          </div>
          <div class="bodyones" @click="toWithdraw">
            <div class="bodyonediv">
              <img src="../assets/inbody2.png" alt="" />
            </div>
            <div>{{ $t("my.txt2") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <!-- <div class="income" :style="{ backgroundImage: `url(${bgImage})` }"> -->
        <!-- <van-grid :border="false" :column-num="4" >
          <van-grid-item
            v-for="(item, index) in menuList"
            :key="item.text"
            :border="false"
            @click="onMenu(index)"
          >
            <div class="grid-img">
              <img  :src="item.img" alt="" />
            </div>
            <span class="grid-text">{{ item.text }}</span>
          </van-grid-item>
        </van-grid> -->
        <div class="inbody" :style="{ backgroundImage: `url(${bgImage})` }">
          <div class="bodyone">
            <div class="bodyonediv" @click="$router.push('/mypurse')">
              <img src="../assets/m1.png" />
              <div>{{ $t("my.txt3") }}</div>
            </div>
            <div class="bodyonediv" @click="$router.push('/product')">
              <img src="../assets/m2.png" />
              <div>{{ $t("my.txt4") }}</div>
            </div>
            <div class="bodyonediv" @click="openEmail()">
              <img src="../assets/m3.png" />
              <div>{{ $t("my.txt5") }}</div>
            </div>
            <div class="bodyonediv">
              <img src="../assets/m4.png" @click="toaddress()" />
              <div>{{ $t("my.txt6") }}</div>
            </div>
          </div>
          <div class="bodyone">
            <div class="bodyonediv" @click="$router.push('/share')">
              <div class="b-img"><img src="../assets/m5.png" /></div>
              <div>{{ $t("my.txt7") }}</div>
            </div>
            <div class="bodyonediv" @click="$router.push('/party')">
              <div class="b-img"><img src="../assets/m6.png" /></div>
              <div style="font-size: 1.1rem">{{ $t("my.txt8") }}</div>
            </div>
            <div class="bodyonediv"  @click="$router.push('/redemption')">
              <div class="b-img"><img src="../assets/m7.png" /></div>
              <div>{{ $t("my.txt9") }}</div>
            </div>
            <div class="bodyonediv"  @click="$router.push('/video')">
              <div class="b-img"><img src="../assets/m8.png" /></div>
              <div >{{ $t("my.txt10") }}</div>
            </div>
          </div>
        </div>
      <!-- </div> -->

      <!-- <div class="inbody">
        <div class="bodyone">
          <div class="bodyonediv" @click="$router.push('/mypurse')">
            <div class="img-box">
              <img src="../assets/m1.png" />
            </div>
            <div>{{ $t("my.txt3") }}</div>
          </div>
          <div class="bodyonediv" @click="$router.push('/product')">
            <div class="img-box">
              <img src="../assets/m2.png" />
            </div>
            <div>{{ $t("my.txt4") }}</div>
          </div>
          <div class="bodyonediv">
            <div class="img-box">
              <img src="../assets/m3.png" />
            </div>
            <div>{{ $t("my.txt5") }}</div>
          </div>
          <div class="bodyonediv" @click="toaddress">
            <div class="img-box">
              <img src="../assets/m4.png" />
            </div>
            <div>{{ $t("my.txt6") }}</div>
          </div>
        </div>
        <div class="bodyone" style="margin-top: 1.5rem">
          <div class="bodyonediv">
            <img src="../assets/m5.png" @click="$router.push('/share')" />
            <div>{{ $t("my.txt7") }}</div>
          </div>
          <div class="bodyonediv" @click="$router.push('/party')">
            <img src="../assets/m6.png" />
            <div>{{ $t("my.txt8") }}</div>
          </div>
          <div class="bodyonediv" @click="$router.push('/redemption')">
            <img src="../assets/m7.png" />
            <div>{{ $t("my.txt9") }}</div>
          </div>
          <div class="bodyonediv" @click="$router.push('/video')">
            <img src="../assets/m8.png" />
            <div>{{ $t("my.txt10") }}</div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="fun">
      <div class="funItem">
        <div class="f-l" @click="toinstall">
          <div class="ll">
            <img src="../assets/m10.png" alt="" class="img" />
          </div>
          <div class="lr">{{ $t("my.txt11") }}</div>
        </div>
        <div class="f-r">
          <img src="../assets/tdright.png" alt="" class="img" />
        </div>
      </div>
      <div class="funItem">
        <div class="f-l" @click="rechangelang">
          <div class="ll">
            <img src="../assets/m11.png" alt="" class="img" />
          </div>
          <div class="lr">{{ $t("my.txt12") }}</div>
        </div>
        <div class="f-r">
          <img src="../assets/tdright.png" alt="" class="img" />
        </div>
      </div>
      <div class="funItem">
        <div class="f-l" @click="appxiazai">
          <div class="ll">
            <img src="../assets/m12.png" alt="" class="img" />
          </div>
          <div class="lr">{{ $t("my.txt13") }}</div>
        </div>
        <div class="f-r">
          <img src="../assets/tdright.png" alt="" class="img" />
        </div>
      </div>
      <div class="funItem">
        <div class="f-l" @click="tocompanycetails">
          <div class="ll">
            <img src="../assets/m13.png" alt="" class="img" />
          </div>
          <div class="lr">{{ $t("my.txt14") }}</div>
        </div>
        <div class="f-r">
          <img src="../assets/tdright.png" alt="" class="img" />
        </div>
      </div>
      <div class="funItem">
        <div class="f-l" @click="tobangzhu">
          <div class="ll">
            <img src="../assets/m14.png" alt="" class="img" />
          </div>
          <div class="lr">{{ $t("my.txt15") }}</div>
        </div>
        <div class="f-r">
          <img src="../assets/tdright.png" alt="" class="img" />
        </div>
      </div>
    </div>
    <div class="out">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        @click="tologin"
        >{{ $t("my.txt16") }}</van-button
      >
    </div>
    <van-popup v-model="newshow" position="bottom" :style="{ height: '30%' }">
      <!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
      <van-picker
        title=""
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        confirm-button-text="Confirmar"
        cancel-button-text="Cancelar"
      />
    </van-popup>
    <TabBar></TabBar>
  </div>
</template>

<script>
import { menu } from "../data/myData";
import {
  withdrawalapi,
  myuserapi,
  loginoutapi,
  getlanguage,
} from "@/api/index";
import { Toast, Dialog } from "vant";
export default {
  name: "MY",
  data() {
    return {
      company_email: "",
      menuList: menu,
      address: "",
      user: {},
      imgsrc: "",
      emali: "",
      announcement: [],
      isLoading: false,
      newshow: false,
      columns: [],
      vip: 0,
      dwstatus: 0,
      download: {},
      inevit: "",
      zsinevit: "",
      support:'',
      bgImage:''
    };
  },
  created() {
    // this.withdrawal()
    this.myuser();
    this.getlanguage();
  },
  methods: {
    async getlanguage() {
      const res = await getlanguage();
      res.data.forEach((i) => {
        this.columns.push(i.name);
      });
    },
    onConfirm(e) {
      console.log(e, "666");
      if (e == "English") {
        localStorage.setItem("newlang", "en");
      }
      if (e == "Español") {
        localStorage.setItem("newlang", "es");
      }
      localStorage.setItem("lang", e);

      this.$i18n.locale = e;
      this.newshow = false;
    },
    onCancel() {
      this.newshow = false;
    },
    onChange() {},
    rechangelang() {
      this.newshow = true;
    },
    onError() {
      console.error("复制失败");
    },
    // 复制
    onCopy() {
      // this.$message.success("复制成功");
      Toast({
        message: this.$t("party.Success"),
        duration: 1200,
        className: "noticeWidth", // 所加样式名
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    torecharge() {},
    // 去帮助中心
    tobangzhu() {
      window.location.href = this.support
      // this.$router.push("/helpcenter");
    },
    toWithdraw() {
      this.$router.push("/Withdraw");
    },
    toRecord() {
      this.$router.push("/Recharges");
    },
    // 去产品状态
    toproduct() {
      this.$router.push("/product");
    },
    // 去设置详情页面
    toinstall() {
      this.$router.push("/install");
    },
    // 去视频介绍
    tovideo() {
      this.$router.push("/video");
    },
    // 去我的钱包
    tomypurse() {
      this.$router.push("/mypurse");
    },
    // 去设置地址
    toaddress() {
      this.$router.push({
        path: "/address",
        query: {
          emali: this.emali,
        },
      });
    },
    // 去红包页面
    toredemption() {
      this.$router.push("/redemption");
    },
    // 去公司介绍
    tocompanycetails() {
      this.$router.push("/companycetails");
    },
    // 下载app
    appxiazai() {
      console.log(this.$imgUrl + this.download.IOS.url);
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        window.open(this.$imgUrl + this.download.IOS.url);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        window.open(this.$imgUrl + this.download.Android.url);
      } else {
        // client = 'PC'
      }
      // return client
    },
    // 退出登录
    async tologin() {
      const res = await loginoutapi();
      // console.log('111', res)
      localStorage.removeItem("token");
      sessionStorage.removeItem("show");
      sessionStorage.removeItem("gonggaoshow");
      localStorage.removeItem("lang");
      localStorage.removeItem("tink_var");
      localStorage.removeItem("is_email");
      localStorage.removeItem("userInfo");
      Toast({
        message: res.msg,
        duration: 1200,
        className: "noticeWidth", // 所加样式名
      });
      setTimeout(() => {
        this.$router.push("/login");
      }, 1500);
    },
    // 获取地址
    async withdrawal() {
      const res = await withdrawalapi();
      this.address = res.data.wallet_address;
    },
    async myuser() {
      const res = await myuserapi();
      console.log(res, "my");
      this.user = res.data.user;
      this.inevit = this.user.invite_url;
      this.truncateMiddle(this.user.invite_url, 20);
      this.support = res.data.support
      this.bgImage = res.data.personal_bg
      // this.download = res.data.download
      // this.user.award_profit = parseFloat(this.user.award_profit).toFixed(2)
      // this.user.today_earn = parseFloat(this.user.today_earn).toFixed(2)
      // this.user.profit_return = parseFloat(this.user.profit_return).toFixed(2)
      // this.user.money = parseFloat(this.user.money).toFixed(2)
      // this.imgsrc = res.data.user.avatar
      this.emali = res.data.user.email;
      this.company_email = res.data.company_email
      // this.announcement = res.data.notice
      // this.dwstatus = res.data.dw_status
    },
    truncateMiddle(s, max_length) {
      if (s.length <= max_length) {
        return s;
      }
      const ellipsis = "...";
      const charsToShow = max_length - ellipsis.length;
      const frontChars = Math.ceil(charsToShow / 2);
      const backChars = Math.floor(charsToShow / 2);

      this.zsinevit =
        s.substr(0, frontChars) + ellipsis + s.substr(s.length - backChars);
    },
    // 去签到页面
    tosignin() {
      this.$router.push("/signin");
    },
    // 去体现
    towithdraw() {
      this.$router.push("/withdraw");
      // if(this.dwstatus == 1){
      //   this.$router.push('/withdraw')
      // }else{
      //   Toast({
      //     message: 'Please activate the machine first',
      //     duration: 2000,
      //     className: 'noticeWidth' // 所加样式名
      //   })
      //   this.$router.push('/mining')
      // }
    },
    openEmail(){
      Dialog.confirm({
        message: this.company_email,
        confirmButtonText: this.$t('register.txt11'),
        showCancelButton: false,
      })
    },
    onMenu(index) {
      switch (index) {
        case 0:
          this.$router.push("/mypurse");
          break;
        case 1:
          this.$router.push("/product");
          break;
        case 2:
          Dialog.confirm({
                  message: this.company_email,
                  confirmButtonText: this.$t('register.txt11'),
                  showCancelButton: false,
                })
          break;
        case 3:
          this.toaddress();
          break;
        case 4:
          this.$router.push("/share");
          break;
        case 5:
          this.$router.push("/party");
          break;
        case 6:
          this.$router.push("/redemption");
          break;
        case 7:
          this.$router.push("/video");
          break
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.my {
  // background-color: #060606;
  // background-image: url("../assets/indexback.png");
  background-size: 100% 90rem;
  background-repeat: no-repeat;
  width: 100%;
  // min-height: 100vh;
  padding-bottom: 4.9rem;
  .bg {
    width: 100%;
    height: 22rem;
    background: linear-gradient(to bottom, #ffe1d1, #f9f9f9);
    padding: 5rem 2rem 0 2rem;
    .m-i {
      display: flex;
      .yuan {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: #ffffff;
        margin-right: 1rem;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
      }
      .m-r {
        .m-t {
          display: flex;
          align-items: center;
          .name {
            font-size: 1.5rem;
            margin-right: 1rem;
          }
          .vBg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 5rem;
            height: 1.3rem;
            background-image: url("../assets/dj.png");
            background-repeat: no-repeat;
            background-size: 100%;
            padding-left: 1.5rem;
            color: #fff;
            font-size: 0.8rem;
          }
        }
        .id {
          font-size: 1.1rem;
          font-weight: 600;
          margin: 0.8rem 0;
          color: #FE6719;
        }
        .lj {
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          color: #FE6719;
          font-weight: 500;
          .img {
            width: 0.9rem;
            height: 0.9rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .tx {
      padding: 3rem;
      .t-i {
        padding: 1rem;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 1rem;
        .bodyone {
          width: 50%;
          text-align: center;
          border-right: 1px solid #e8e8e8;
          height: 100%;
          .bodyonediv {
            img {
              width: 3rem;
              margin-bottom: 0.5rem;
            }
          }
        }
        .bodyones {
          width: 50%;
          text-align: center;
          .bodyonediv {
            height: 100%;
          }
          img {
            width: 3rem;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
  // 11.14修改
  .cont {
    padding: 0 1.6rem;
    .inbody {
      width: 100%;
      min-height: 15rem;
      background-size:100% 100%;
      background-repeat: no-repeat;
      margin-top: 1.5rem;
      border-radius: 1rem;
      .bodyone {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        font-weight: 700;

        .bodyonediv {
          width: 25%;
          padding: 16px 8px;
          .b-img{
            width: 100%;
            text-align: center;
            height: 3rem;
            margin-bottom: 0.5rem;
            img {
              width: 2.5rem;
            }
          }
          img {
            width: 2.5rem;
            width: 2.5rem;
            margin-bottom: 0.5rem;
          }
        }
        div {
          text-align: center;
        }
      }
    }
  }
  .fun {
    padding: 2rem;
    .funItem {
      padding: 1.5rem 0;
      border-bottom: 1px solid #dcdcdc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .f-l {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        .ll {
          width: 2rem;
          height: 2rem;
          margin-right: 0.7rem;
          .img {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      .f-r {
        width: 1.3rem;
        height: 1.3rem;
        .img {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
  }
  ::v-deep .van-button {
    border-radius: 1rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #fe6719;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .out {
    padding: 2rem;
  }

  .mybj {
    width: 100%;
  }
  .Positioning {
    padding: 0rem 1.6rem;
    // padding-top: 5rem;
    position: relative;
    .install {
      z-index: 99;
      width: 2rem;
      height: 1.9rem;
      position: absolute;
      top: -15rem;
      right: 1.6rem;
    }
    .Notice {
      display: flex;
      color: #333;
      // margin-top: 5.5rem;
      .img {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1.3rem;
        margin-top: 0.4rem;
      }
      ::v-deep .my-swipe {
        width: 30rem;
      }
      ::v-deep .van-swipe-item {
        color: #333;
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 0.5rem;
        width: 30rem;
        height: 2rem;
        // line-height: 2rem;
        text-align: center;
        // background-color: #39a9ed;
      }
    }
    .content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .content-box {
        width: 10.2rem;
        height: 10.2rem;
        text-align: center;
        background-color: #fff;
        border-radius: 0.5rem;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        font-size: 1.2rem;
        color: #fff;
        margin-top: 1.8rem;
        .img {
          width: 2.91rem;
          height: 3.27rem;
          margin-left: 3.7rem;
          margin-top: 2rem;
          // margin: 0 auto;
          // margin-top: 1.8rem;
          // margin-bottom: 1.5rem;
        }
        .imgmargin {
          margin-top: 3rem;
        }
        // &:last-child {
        //   background-color: #060606;
        // }
      }
      .huang {
        color: #f6ad66;
        // margin-left: 1rem;
      }
    }
    .bottom {
      width: 100%;
      height: 4.4rem;
      background-color: #5979fb;
      text-align: center;
      line-height: 4.4rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
      border-radius: 2.75rem;
      font-size: 1.6rem;
      color: #fff;
    }
  }
}

/* 修改的*/
.mytop {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  // margin: 2.3rem 1.5rem;
}
.mytop img {
  width: 2.5rem;
  height: 2.5rem;
}
.mytop img:nth-child(2) {
  height: 2.5rem;
  margin-left: 2.5rem;
}
.userinfoone {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  align-items: center;
  // position: absolute;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  .avatar {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    // background-color: #fff;
    // margin: 0 auto;
    // margin-top: -3.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
.color {
  color: #02d79a !important;
}
.mybk {
  background-image: url("../assets/mybj.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2.3rem 1.5rem;
  padding-bottom: 5.3rem;
}
.userinfo {
  // position: absolute;
  // top: -7rem;
  // left: 1.6rem;
  width: 34.3rem;
  // // height: 15.85rem;
  background-color: #01061a;
  color: #fff;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  .name {
    // display: flex;
    // justify-content: center;
    color: #fff;
    margin-top: 1.55rem;
    // margin-left: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    // span {
    //   font-size: 0.7rem;
    //   margin-left: 0.5rem;
    //   color: #ccc;
    // }
  }
  .avatar {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    // background-color: #fff;
    // margin: 0 auto;
    // margin-top: -3.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .service {
    position: absolute;
    top: 1.3rem;
    right: 1.3rem;
    width: 1.9rem;
    height: 1.88rem;
  }
  .name {
    color: #000;
    margin-top: 1.55rem;
    // margin-left: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;

    // span {
    //   font-size: 0.7rem;
    //   margin-left: 0.5rem;
    //   color: #ccc;
    // }
  }
  .vipbox {
    width: 18rem;
    padding: 0.6rem 1.5rem;
    border-radius: 2rem;
    margin: 1rem auto;
    background: linear-gradient(
      130deg,
      #d1ae7a 0%,
      #fce8ca 34%,
      #e2c49b 72%,
      #edd9bc 100%
    );
    box-shadow: 0px 1px 3px 1px rgba(248, 226, 194, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    .vipleft {
      display: flex;
      align-items: center;
      .vipimg {
        width: 2.4rem;
        margin-top: -0.3rem;
        margin-right: 0.5rem;
      }
    }
  }

  .Income {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: #26272E;
    // margin-top: 2.5rem;
    padding: 1rem 1rem;
    padding-bottom: 0.5rem;
    border-radius: 2.5rem;
    .box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // width: 25%;
      // height: 7rem;
      text-align: center;
      color: #333;
      font-size: 1.6rem;
      padding: 0 0.5rem;
      padding-top: 0.5rem;
      // margin-top: 1rem;
      &:nth-child(3) {
        border-right: 0px solid #fff;
      }
      .title {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0.9rem;
      }
    }
    .green {
      color: #15fbb5;
    }
    .border {
      width: 1px;
      height: 6.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      // margin-top: 2.4rem;
    }
  }
}
.daibi {
  display: flex;
  // justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0.5rem 2rem;
  font-size: 1.5rem;
  border-top: 2px solid #f6f6f6;
  border-bottom: 2px solid #f6f6f6;
  // font-weight: 700;
  color: #333;
  padding: 1rem 0;
  .dbleft {
    margin-right: 10rem;
  }
}
// 11.14 修改
.id_vip {
  display: flex;
  justify-content: space-between;
  // margin: 0 5rem;
}
.vipboxs {
  width: 10.4rem;
  // padding: .6rem 1.5rem;
  padding-left: 3.5rem;
  padding-bottom: 0.2rem;
  height: 2.3rem;
  margin-left: 1rem;
  border-radius: 2rem;
  // margin: 1rem auto;
  // background: linear-gradient(130deg, #D1AE7A 0%, #FCE8CA 34%, #E2C49B 72%, #EDD9BC 100%);
  background-image: url("../assets/myvip.png");
  background-size: contain;
  background-repeat: no-repeat;
  // color: #fff;
  // box-shadow: 0px 1px 3px 1px  #939393;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  .vipleft {
    display: flex;
    align-items: center;
    .vipimg {
      width: 2.4rem;
      margin-top: -0.3rem;
      margin-right: 0.3rem;
    }
  }
}
.toppos {
  position: relative;
  bottom: 2rem;
}

.menu-container {
  display: flex;
  .menu-item {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .menu-icon {
      width: 3rem;
      height: 3rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
/* 11.17修改我的钱包*/
.mywallet {
  margin: 2rem;
  margin-top: 0;
  background-image: url("../assets/chartbk.png");
  background-size: cover;
  background-repeat: no-repeat;
  // color: #fff;
  // box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
  border-radius: 10px 10px 10px 10px;
  // opacity: 1;
  padding: 1.5rem;
  color: #fff;
  font-size: 1.3rem;
  .qbtitle {
    .two {
      font-size: 1.5rem;
      font-weight: 700;
      color: #ffb31c;
      margin-right: 0.5rem;
    }
  }
  .qbcon {
    border-bottom: 1px solid #f6f6f6;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .conone {
      // padding-right: 4rem;
      margin-top: 0.7rem;
    }
  }
  .qbbtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #02d7c1;
      img {
        width: 2rem;
        margin-right: 0.5rem;
      }
    }
  }
}

.qbconer {
  font-size: 10px;
  .cononeer {
    display: flex;
    margin-top: 0.5rem;
    justify-content: space-between;
    div {
      width: 100%;
    }
  }
}

.grid-img {
  display:flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
}

.grid-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
</style>
