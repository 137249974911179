<template>
  <!-- 矿机状态 -->
  <div class="record">
    <!--  <van-pull-refresh v-model="isLoading" @refresh="onRefresh"
    :pulling-text="$t('addpull.pullyi')" :loosing-text="$t('addpull.puller')" :loading-text="$t('addpull.pullsan')" pull-distance='100'>
      <div style="min-height: 100vh;">
    <div class="product-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"
      />
      <div class="title">{{ $t("product.title") }}</div>
      <div class="img" @click="topurchaserecord">
        <img src="../assets/jilu.png" alt="" />
      </div>
    </div>
    <van-tabs
      v-model="active"
      color="#333"
      title-active-color="#333"
      title-inactive-color="#fff"
      background="#CA91FF"
      style="margin-top: 3rem;"
    >
      <van-tab :title="$t('product.running')">
        <div
          class="Content"
          v-for="(item, index) in Miningmachinelist"
          :key="index"
          v-show="item.status == 1"
        >
          <div class="top">
            <div class="img">
              <img :src="item.pic" alt="" />
            </div>
            <div class="Content-title">
              <div>{{ item.mining_name }}</div>
              <div class="green">
                {{ item.status_text }}
              </div>
            </div>
            <div class="number">{{ $t("Mining.purchases") }} : 1</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.price") }}</div>
            <div class="right">{{ item.mining_price }} USDT</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.Daily") }}</div>
            <div class="right">{{ item.daily_income }} USDT</div>
          </div>

          <div class="Content-info">
            <div class="left">{{ $t("product.start") }}</div>
            <div class="right">{{ item.starttime }}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.end") }}</div>
            <div class="right">{{ item.endtime }}</div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="$t('product.expired')">
        <div
          class="Content"
          v-for="(item, index) in Miningmachinelist"
          :key="index"
          v-show="item.status == 2"
        >
          <div class="top">
            <div class="img">
              <img :src="item.pic" alt="" />
            </div>
            <div class="Content-title">
              <div>{{ item.mining_name }}</div>
              <div class="hui">
                {{ $t("product.Expired") }}
              </div>
            </div>
            <div class="number">{{ $t("Mining.purchases") }} : 1</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.price") }}</div>
            <div class="right">{{ item.mining_price }} USDT</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.Daily") }}</div>
            <div class="right">{{ item.daily_income }} USDT</div>
          </div>

                    <div class="Content-info">
            <div class="left">{{ $t("product.start") }}</div>
            <div class="right">{{item.starttime}}</div>
          </div>
          <div class="Content-info">
            <div class="left">{{ $t("product.end") }}</div>
            <div class="right">{{ item.endtime }}</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
    </div>
    </van-pull-refresh> -->
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="Desliza hacia abajo para refrescar"
      loosing-text="Suelta para refrescar"
      loading-text="Cargando..."
    >
      <div style="min-height: 100vh;">
        <div class="record-title">
          <van-icon
            name="arrow-left"
            size="2rem"
            color="#000"
            @click="$router.back()"
          />
          <div class="title">{{ $t("Product.title") }}</div>
          <div></div>
        </div>
        <van-tabs @change="changeTab" v-model="tabActive">
          <van-tab name="1" :title="$t('Product.txt6')"></van-tab>
          <van-tab name="2" :title="$t('Product.txt7')"></van-tab>
        </van-tabs>
        <div class="top" v-if="false">
          <div>{{ $t("Product.txt1") }}</div>
          <div>{{ $t("Product.txt2") }}</div>
          <div>{{ $t("Product.txt3") }}</div>
          <!-- <div>{{$t("Rechargerecord.State")}}</div> -->
          <div>{{ $t("Product.txt4") }}</div>
          <div>{{ $t("Product.txt5") }}</div>
        </div>
        <!-- 分页 -->
        <van-list
          v-model="listLoading"
          :finished="finished"
          :finished-text="$t('Mypurse.Theres')"
          :loading-text="$t('Mypurse.Loading')"
          @load="onLoad"
        >
          <div class="productList">
            <div class="product-item" v-for="item in list">
              <div class="co-l">
                <div class="i-l">
                  <img :src="url + item.pic" alt="" />
                </div>
                <div class="i-c">
                  <div class="i-c-o">{{ item.mining_name }}</div>
                  <div>{{ $t("home.txt23") }}：{{ item.mining_price }}</div>
                  <div>{{ $t("home.txt18") }}：{{ item.daily_income }}</div>
                  <div>{{ $t("home.txt19") }}：{{ item.endtime }}</div>
                  <!-- <div>
                    {{ $t("home.txt20") }}：<span
                      style="color: #fe6719; font-size: 1.2rem"
                      >{{ item.total_revenue }}</span
                    >
                  </div> -->
                </div>
              </div>
              <div class="btn">
                <van-button size="small" round color="#28C73D" v-if="item.status == 1">{{ item.status_text }}</van-button>
                <van-button size="small" round color="#f7f7f7" v-if="item.status == 2">{{ item.status_text }}</van-button>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <div style="height: 4rem;"></div>
    <div class="botImg" v-if="list.length<=8">
      <img src="../assets/homeBg.png" alt="" class="img" />
    </div>
  </div>
</template>

<script>
import { Productpurchaseapi, Purchaserecordapi } from "@/api/index";
import { Toast } from "vant";
export default {
  name: "proDuct",
  data() {
    return {
      tabActive: 1,
      active: 0,
      show: false,
      Miningmachinelist: [],
      checked: false,
      id: 0,
      status: "",
      isLoading: false,
      list: [],
      loading: false,
      finished: true,
      total: 0,
      page: "1", // 页码
      limit: "12", // 一页请求多少条,
      listLoading: false,
      url: "https://mxxai.com",
    };
  },
  created() {
    this.Purchaserecord();
    // this.onLoad()
  },

  methods: {
    changeTab(name) {
      this.Purchaserecord(name);
    },
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    async onLoad() {
      this.isLoading = true;
      await this.Purchaserecord();
      this.page++;
      this.isLoading = false;
      this.listLoading = true;
    },
    // 矿机购买记录
    async Purchaserecord(name = 1) {
      const res = await Purchaserecordapi(name);
      this.list = res.data.list;
      // this.loading = false
    },
    async showcell(id) {
      // console.log(id)..
      this.id = id;
      this.show = true;
      // console.log('0000000000', this.list)
    },
    // 产品购买
    async Purchase() {
      if (this.checked) {
        const data = {
          pro_id: this.id,
        };
        const res = await Productpurchaseapi(data);
        // console.log('0123', res)

        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        this.show = false;
        this.checked = false;
        // 购买之后重新获取数据
        this.Miningmachine();
      } else {
        Toast({
          message: this.$t("Mining.first"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
      }
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      //   Toast(item.name)
    },
    torecharge() {
      // 去充值页面
      this.$router.push("/recharges");
    },
    // 去购买记录
    topurchaserecord() {
      this.$router.push("/purchaserecord");
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  background-color: #f9f9f9;
  // background-image: url('../assets/suoyou.png');
  background-size: 100% 25rem;
  background-repeat: no-repeat;
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .botImg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14rem;
    .img {
      width: 100%;
      height: 16rem;
    }
  }
  .record-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    padding: 2.5rem 1.6rem 0 1.6rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .top,
  .bottom {
    color: #333333;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 1.2rem;
    div {
      width: 25%;
      text-align: center;
    }
  }
  .bottom {
    font-size: 1.3rem;
    border-bottom: 0px solid rgba(255, 255, 255, 0.1);
    color: #333333;
  }
  .green {
    color: #15fbb5;
  }
}

.productList {
  margin-top: 2rem;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;

  .product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 0.5px solid #e1e1e1;
    .co-l {
      display: flex;
      align-items: center;
      .i-l {
        width: 6rem;
        height: 6rem;
        background-color: #14305b;
        border-radius: 0.5rem;
        margin-right: 1rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .i-c {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .i-c-o {
          font-size: 1.3rem;
          font-weight: bold;
        }
        font-size: 1.1rem;
      }
    }
    .co-r {
      .num {
        text-align: center;
        font-size: 1.5rem;
      }
      .goum {
        display: flex;
        justify-content: flex-end;
        .but {
          width: 6rem;
          height: 2.5rem;
          line-height: 2.5rem;
          text-align: center;
          color: #ffffff;
          background-color: #fe6719;
          border-radius: 0.6rem;
          font-size: 1.1rem;
        }
      }
    }
  }
}
</style>
