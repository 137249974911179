<template>
  <div class="RedList" @scroll="handleScroll">
    <div class="Mypurse-title">
      <van-icon
        @click="$router.push('/redemption')"
        name="arrow-left"
        size="2rem"
        color="#000"/>
      <div class="title">{{$t('hongbao.listtitle')}}</div>
      <div class="img">
        <img src="" alt="" />
      </div>
    </div>
    <div class="body">
      <van-tabs v-model="active" @change="change">
        <!-- <van-tab :title="$t('hongbao.listyi')">
          <div class="content">
            <div class="one">
              <p>Send user: 123456</p>
              <p class="oneright">2023-05-29 15：00：00</p>
            </div>
            <div class="two greed">
              <p>Quiero enviar USDT</p>
              <p>-0.01</p>
            </div>
          </div>
        </van-tab> -->
        <van-tab :title="$t('hongbao.lister')">
          <div class="content" v-for="(item,index) in list" :key="index">
            <div class="one">
              <p>{{$t('RedList.txt1')}} usuario: {{item.send_user}}</p>
              <p class="oneright">{{item.createtime}}</p>
            </div>
            <div class="two greed">
              <p>{{ $t('RedList.txt2') }} USDT</p>
              <p>+{{item.money}}</p>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('hongbao.listsan')">
          <div class="content" v-for="(item,index) in list" :key="index">
            <div class="one">
              <p>{{$t('RedList.txt1')}}: {{item.send_user}}</p>
              <p class="oneright">{{item.createtime}}</p>
            </div>
            <div class="two red">
              <p>{{$t('RedList.txt3')}} USDT</p>
              <p>-{{item.money}}</p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { send_red_record } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      active: 0,
      listdata: {
        page: 1,
        page_size: 15,
        type: 1
      },
      list: [],
      listtotal: 0
    }
  },
  created () {
    this.getrecord()
  },
  methods: {
    handleScroll (event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        // 触发刷新操作
        // console.log(111)
        if (this.active == 0) { // 收到
          if (this.listtotal < this.listdata.page*this.listdata.page_size) {
            this.listdata.page++
          } else {
            Toast({
              message: this.$t('hongbao.daodil'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
          }
        } else if (this.active = 1) { // 发送
          if (this.listtotal < this.listdata.page*this.listdata.page_size) {
            this.listdata.page++
          } else {
            Toast({
              message: this.$t('hongbao.daodil'),
              duration: 1200,
              className: 'noticeWidth' // 所加样式名
            })
          }
        }
      }
    },
    async getrecord () {
      const res = await send_red_record(this.listdata)
      if (res.code == 1) {
        this.list = [...this.list,...res.data.rows]
        this.listtotal = res.data.total
        console.log(this.list, this.listtotal)
      } else {
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    change (e) {
      console.log(e)
      if (e==1) {
        this.listdata.type = 2
      } else {
        this.listdata.type = 1
      }
      this.listdata.page = 1
      this.list = []
      this.getrecord()
    }
  }
}
</script>

<style scoped lang="less">
.RedList{
  height: 100vh; /* 设置容器高度为视口高度，确保容器有滚动条 */
  overflow-y: auto; /* 设置y轴滚动 */
  .Mypurse-title {
    padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  ::v-deep .van-tabs__line{
    background-color: #5979FB;
  }
  .body{
    .content{
      margin: 2rem;
      border-bottom: 2px solid #f6f6f6;
      padding-bottom: 2rem;
      .one,.two{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        .oneright{
          color: #848484;
        }
      }
      .two{
        margin-top: 1.5rem;
        font-weight: bold;
      }
      .greed{
        color: #02D79A !important;
      }
      .red{
        color: #FE5C5F !important;
      }
    }
  }
}
</style>
