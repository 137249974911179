<template>
  <div class="racecar" v-if="period">
    <div class="header">
      <div class="title">
        <van-icon
          name="arrow-left"
          size="2rem"
          color="#000"
          @click="$router.push('home')"
        />
        <van-tabs @change="changeTabs" v-model="typeActive" style="flex: 1">
          <van-tab name="1" :title="$t('racecar.txt3')"></van-tab>
          <van-tab name="2" :title="$t('racecar.txt4')"></van-tab>
        </van-tabs>
      </div>
      <div class="header-main">
        <div class="title-box">
          <div class="title-desc">{{ $t("racecar.txt6") }}</div>
          <div class="title-period">
            {{ $t("racecar.txt7") }}<br />
            N.º {{ period.period }}
          </div>
        </div>
        <div class="countDown-box">
          <div class="cocuntDown-desc">{{ $t("racecar.txt5") }}</div>
          <van-count-down
            ref="countDownRef"
            format="mm:ss:SS"
            millisecond
            :time="period.seconds"
            @finish="countDownFinish"
          >
            <template #default="timeData">
              <span class="countDown-bg">{{
                timeData.minutes.toString().padStart(2, "0")
              }}</span>
              <!-- <span class="colon">:</span> -->
              <span class="countDown-bg">{{
                timeData.seconds.toString().padStart(2, "0")
              }}</span>
              <!-- <span class="colon">:</span> -->
              <span class="countDown-bg">{{
                String(Math.floor(timeData.milliseconds / 10)).padStart(2, "0")
              }}</span>
            </template>
          </van-count-down>

          <van-popover
          :offset="[10, 20]"
            placement="bottom-end"
            v-model="isRecord"
            trigger="click"
            :actions="actions"
            @select="onSelect"
          >
            <template #reference>
              <img class="countMore" src="../assets/countDownMore.png" alt="" />
            </template>
          </van-popover>
        </div>
      </div>
      <div class="racecarbg">
        <div class="period">
          Sorteo<br />
          N.º {{ period.old_period }}
        </div>
        <div class="num-box">
          <div
            class="num-item"
            :style="{ background: getNumColor(item) }"
            v-for="item in period.lottery"
          >
            {{ item }}
          </div>
        </div>
        <van-popover
          :offset="[0, 20]"
          placement="bottom-end"
          v-model="showPopover"
          trigger="click"
        >
          <div class="history-container">
            <div class="history-item" v-for="item in period.history">
              <div class="period">
                {{ $t("racecar.txt7") }}<br />
                N.º {{ item.period }}
              </div>
              <div class="num-box">
                <div
                  class="num-item"
                  :style="{ background: getNumColor(item) }"
                  v-for="item in item.lottery"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <template #reference>
            <img
              style="width: 1.2rem; height: 0.7rem"
              class="more"
              src="../assets/more.png"
              alt=""
            />
          </template>
        </van-popover>
      </div>
    </div>

    <div class="gameType">
      <van-tabs v-model="active" :line-width="60">
        <van-tab :title="$t('racecar.txt1')"></van-tab>
        <van-tab title="" disabled></van-tab>
        <van-tab title="" disabled></van-tab>
      </van-tabs>
    </div>

    <div class="main">
      <div class="gameInfo">
        <span class="info-desc">{{ $t("racecar.txt2") }}</span>
        <img class="info-icon" src="../assets/wenhao.png" alt="" @click="open"/>
      </div>
      <van-divider :hairline="false" style="color: #e2e3e7" />
      <div class="gameMain">
        <div class="box">
          <div
            class="option"
            @click="changeRank(index)"
            v-for="(item, index) in rankList"
          >
            <div>{{ item }}</div>
            <van-icon
              v-if="rankActiveIndex == index"
              name="success"
              style="margin-left: 0.3rem"
              color="#D82A4D"
            />
          </div>
        </div>
        <van-divider :hairline="false" style="color: #e2e3e7" />
        <div class="box" style="grid-template-columns: 1fr 1fr 1fr 1fr">
          <div
            class="option option1"
            :class="rank2ActiveIndex === index ? 'active' : ''"
            @click="changeRank2(index)"
            v-for="(item, index) in period.bet_profit.big_arr"
          >
            <div class="option1-desc">{{ $t(`racecar.boleto[${index}]`) }}</div>
            <div class="option1-desc-bottom">{{ item }}</div>
          </div>
        </div>
        <van-divider :hairline="false" style="color: #e2e3e7" />
        <div class="box" style="grid-template-columns: 1fr 1fr 1fr 1fr">
          <div
            class="option option1"
            :class="betActive.includes(index + 1) ? 'active' : ''"
            @click="changeBet(index,item)"
            v-for="(item, index) in period.bet_profit.bet_arr"
          >
            <div class="option1-desc">{{ index + 1 }}</div>
            <div class="option1-desc-bottom">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="top">
        <div class="left">
          <img class="leftimg" src="../assets/edit.png" alt="" />
          <div class="option-box">
            <div
              class="option"
              @click="changeMoneyOption(item, i)"
              v-for="(item, i) in moneyList"
            >
              <div
                class="option-item"
                :class="moneyActiveIndex == i ? 'active' : ''"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <van-divider
            class="option-divider"
            :hairline="false"
            style="color: #e2e3e7"
          />
          <div class="option-box">
            <div
              class="option"
              @click="changeMoneyOption(item, i + 4)"
              v-for="(item, i) in moneyList2"
            >
              <div
                class="option-item"
                :class="moneyActiveIndex == i + 4 ? 'active' : ''"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <van-field
            type="number"
            class="cellgroup"
            v-model="moneyValue"
            :placeholder="$t('racecar.txt8')"
            @input="moneyInput"
          />
        </div>
      </div>
       <!-- @click="$router.push('/periodReord')" -->
      <div class="bottom">
        <van-button
          @click="$router.push('/periodReord')"
          type="default"
          class="b-btn1"
          >{{ $t("racecar.txt9") }}</van-button
        >
        <van-button @click="onPay" type="default" class="b-btn2">
          <span>{{ $t("racecar.txt10") }}</span
          ><br />
          <span>${{ fullPrice }}</span>
        </van-button>
      </div>
    </div>

    <van-popup
      v-model="show"
      z-index="99"
      style="border-radius: 2rem;width: 94%;"
    >
      <div class="czbodys" v-html="period.rule">

      </div>
    </van-popup>

  </div>
</template>

<script>
import { getPeriod, postBtcBet } from "../api/index";

import { Dialog, Toast } from "vant";

import es from "@/i18n/es.js";
export default {
  data() {
    return {
      isRecord: false,
      tabName: 1,
      typeActive: 1,
      active: 0,
      rankList: es.racecar.rank,
      rank2ActiveIndex: "",
      moneyValue: "",
      big_arr2: ["Grande", "Pequeño", "Doble", "Simple"],
      rankActiveIndex: 0,
      betActiveIndex: "",
      moneyList: [1, 5, 10, 50],
      moneyList2: [100, 200, 1000, 2000],
      moneyActiveIndex: "0",
      payMoney: "1",
      period: "",
      show:false,
      rule:'',
      colorArr: [
        {
          text: "1",
          color: "#FDFF54",
        },
        {
          text: "2",
          color: "#4189F7",
        },
        {
          text: "3",
          color: "#4D4D4D",
        },
        {
          text: "4",
          color: "#EB7B31",
        },
        {
          text: "5",
          color: "#A7FAFE",
        },
        {
          text: "6",
          color: "#4B1DF9",
        },
        {
          text: "7",
          color: "#B8B8B8",
        },
        {
          text: "8",
          color: "#EC321B",
        },
        {
          text: "9",
          color: "#6B0F12",
        },
        {
          text: "10",
          color: "#61BF33",
        },
      ],
      betActive: [],
      big:'',
      bets:'',
      set: new Set([]),
      showPopover: false,
      actions: [{ text: this.$t('racecar.txt18'), index: 1 }, { text: this.$t('racecar.txt9'),index: 2 }],
    };
  },

  created() {
    this.getData(this.tabName);
  },

  methods: {
    onSelect(action) {
      if(action.index == 2) {
        this.$router.push('/periodReord')
      } else if(action.index == 1) {
        this.$router.push('/periodChart')
      }
    },
    changeRank(index) {
      this.rankActiveIndex = index;
    },
    open(){
      this.show = true
    },
    async getData(category) {
      const res = await getPeriod(category);
      this.period = res.data;
    },

    changeRank2(index) {
      console.log(111)
      if (index === this.rank2ActiveIndex) {
        this.rank2ActiveIndex = "";
        console.log(222)
      } else {
        this.rank2ActiveIndex = index;
        console.log(333)
      }
      this.big = this.period.bet_profit.big_arr[this.rank2ActiveIndex]
    },

    changeBet(index,item) {
      index++;
      if (this.set.has(index,item)) {
        this.set.delete(index,item);
      } else {
        this.set.add(index,item);
      }
      this.updataArr();
    },

    updataArr() {
      this.betActive = [...this.set];
      console.log(this.betActive);
    },

    changeTabs(name) {
      this.tabName = name;
      this.getData(name);
    },

    async onPay() {
      // if (this.rank2ActiveIndex || this.betActive.length) {
        let rankType;
        let betNum = '';
        rankType = this.rank2ActiveIndex + 11
        if (this.rank2ActiveIndex && this.betActive.length != 0) {
          console.log(rankType,22222)
          for (let i = 0; i < this.betActive.length; i++) {
            if (i >= 1) {
              betNum += "," + this.betActive[i];
            } else {
              betNum += this.betActive[i];
            }
          }
          if(betNum == ''){
             rankType = this.rank2ActiveIndex + 11
          }else{
             rankType = `${betNum},${this.rank2ActiveIndex + 11}`;
          }

        } else if (!this.rank2ActiveIndex) {

          for (let i = 0; i < this.betActive.length; i++) {
            if (i >= 1) {
              betNum += "," + this.betActive[i];
            } else {
              betNum += this.betActive[i];
            }
          }
          if(betNum){
            rankType = betNum;
          }
        } else if (this.betActive.length != 0) {
          rankType = this.rank2ActiveIndex + 11;
        }
        console.log(rankType)
        Dialog.confirm({
          title:
            this.$t("racecar.txt16") +
            this.fullPrice +
            this.$t("racecar.txt17"),
            confirmButtonText:this.$t("racecar.txt77"),
            cancelButtonText:this.$t("racecar.txt88"),
        })
          .then(async () => {
            const res = await postBtcBet({
              type: rankType,
              amount: this.fullPrice,
              category: this.typeActive,
              rank: this.rankActiveIndex + 1,
            });

            if(res.code == 0) {
              Toast.fail(res.msg)
            } else {
              Toast.success(this.$t("racecar.txt15"));
              setTimeout(() => {
                this.rankActiveIndex = 0
                this.rank2ActiveIndex = null
                this.betActive = []
                this.set.clear()
                this.updataArr()
                this.payMoney = 0
                this.moneyActiveIndex = 0
                // this.$router.go(0);
              }, 1000);
            }
          })
          .catch(() => {});
      // } else {
      //   Toast({
      //     message: this.$t("racecar.txt55"),
      //     duration: 1200,
      //     // className: 'noticeWidth' // 所加样式名
      //   });
      //   return;
      // }

      // const res = await postBtcBet()
    },

    changeMoneyOption(item, index) {
      this.moneyActiveIndex = index;
      this.payMoney = item;
      this.moneyValue = "";
    },

    moneyInput(e) {
      this.moneyActiveIndex = null;
      this.payMoney = e;
    },

    async countDownFinish() {
      setTimeout(async () => {
        await this.getData(this.tabName);
      }, 1000);
    },

    getNumColor(item) {
      if (item == 0) return;
      return this.colorArr[item - 1].color;
    },
  },

  computed: {
    fullPrice() {
      let rank2Acitve;
      let numSize;
      rank2Acitve = this.rank2ActiveIndex !== "" ? 1 : 0;
      numSize = this.betActive.length == 0 ? 0 : this.betActive.length;
      return rank2Acitve * this.payMoney + numSize * this.payMoney;
    },
  },
};
</script>

<style lang="less" scoped>
.racecar {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .czbodys{
    width: 96%;
    margin: 0 2%;
    height: 50vh;
    padding: 2rem;
    box-sizing: border-box;
  }
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 23rem;
    background: linear-gradient(180deg, #fdb77a 0%, #f7deb4 100%);
    padding-bottom: 1.5rem;

    .title {
      display: flex;
      align-items: center;
      margin-top: 2.5rem;
      margin: 2.5rem 1rem 0;
    }

    .racecarbg {
      display: flex;
      align-items: center;
      justify-content: center;
      background: url("../assets/racecar.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 5.5rem;

      .period {
        text-align: center;
        font-size: 1.2rem;
        color: #333;
      }

      .num-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1.4rem;

        .num-item {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          font-size: 1.6rem;
          color: #fff;
          border-radius: 4px;
        }

        .num-item:last-child {
          margin-right: unset;
        }
      }
    }

    .header-main {
      flex: 1;
      margin-top: 2rem;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-box {
        margin-left: 3rem;
        .title-desc {
          font-size: 1.8rem;
          color: #333;
          font-weight: bold;
        }

        .title-period {
          text-align: center;
          margin-top: 0.2rem;
          font-size: 1.5rem;
          color: #333;
        }
      }

      .countDown-box {
        display: flex;
        align-items: center;

        .cocuntDown-desc {
          font-size: 1.7rem;
          margin-right: 1.2rem;
        }
        .countMore {
          margin-left: 1rem;
          width: 2.5rem;
          height: 2rem;
        }
      }
    }
  }

  .gameType {
    height: 3.8rem;
    background-color: #fff;
  }

  .main {
    flex: 1;
    width: 100%;
    padding: 1rem 2rem;
    background: #f0eeeb;

    .gameInfo {
      margin-top: 0.5rem;
      .info-desc {
        font-size: 1.1rem;
      }

      .info-icon {
        margin-left: 0.5rem;
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .gameMain {
      .box {
        display: grid;
        column-gap: 2rem;
        row-gap: 1.5rem;
        grid-template-columns: 1fr 1fr 1fr;
        .option {
          display: flex;
          align-items: center;
          justify-content: center;
          //   width: 7rem;
          height: 3rem;
          border-radius: 0.5rem;
          color: #fe6719;
          border: 1px solid #fe6719;
          font-size: 1.2rem;
        }

        .option1 {
          display: flex;
          flex-direction: column;
          height: 4.4rem;
          line-height: unset;
          color: #000;
          font-weight: 700;
          border: unset;
          background-color: #fff;

          .option1-desc {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
          }

          .option1-desc-bottom {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            color: #989898;
          }
        }

        .option1.active {
          background-color: #fe6719;
          color: #fff;
        }

        .option1.active .option1-desc-bottom {
          color: #fff;
        }
      }
    }
  }

  .footer {
    padding: 1.5rem 1.2rem;
    height: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      .left {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-left: 2.5rem;
        padding-top: 0.5rem;
        width: 23.5rem;
        height: 8rem;
        border: 1px solid #e7e5e1;

        .leftimg {
          position: absolute;
          top: 2rem;
          left: 1rem;
          width: 1rem;
          height: 1rem;
        }
        .option-divider {
          margin: 0.5rem;
        }
        .option-box {
          display: flex;
          height: 100%;
          .option {
            flex: 1;
            width: 100%;
            height: 100%;

            border-right: 1px solid #e7e5e1;
            .option-item {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .active {
              border: 1px solid gold;
            }
          }

          .option:nth-child(4n) {
            border-right: unset;
          }
        }
      }

      .right {
        margin-left: 1.4rem;
      }
    }

    .bottom {
      display: flex;
      margin-top: 1.35rem;

      .b-btn1 {
        white-space: wrap;
        width: 7.5rem;
        height: 5rem;
        font-size: 1.2rem;
        color: #000;
        margin-right: 1rem;
      }

      .b-btn2 {
        color: #fff;
        background-color: #fe6719;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.history-container {
  width: 95vw;
  padding: 1rem 0.8rem;
  background: linear-gradient(180deg, #fdb77a 0%, #f7deb4 100%);

  .history-item {
    display: flex;
    align-items: center;
    height: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #f7f7f7;

    .period {
      text-align: center;
      font-size: 1.2rem;
      color: #333;
    }

    .num-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1.4rem;

      .num-item {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        font-size: 1.6rem;
        color: #fff;
        border-radius: 4px;
      }

      .num-item:last-child {
        margin-right: unset;
      }
    }
  }

  .history-item:last-child {
    border-bottom: unset;
    margin-bottom: unset;
  }
}

::v-deep .van-tab {
  text-align: center;
}

::v-deep .van-field__control::-webkit-input-placeholder {
  font-size: 1rem;
  text-align: center;
  color: #fe6719;
}

::v-deep .cellgroup {
  width: 8.5rem;
  border: 1px solid #e7e5e1;
}

::v-deep .van-field__control {
  color: #fe6719;
}

::v-deep .countDown-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3rem;
  background: url("../assets/countDown.png");
  background-size: contain;
  background-repeat: no-repeat;
}

::v-deep .van-count-down {
  display: flex;
  align-items: center;
}

::v-deep .van-tabs__nav {
  background-color: unset;
}

::v-deep .van-popover__content {
  text-align: center !important;
}
</style>
