import { render, staticRenderFns } from "./gifter.vue?vue&type=template&id=5375d478&scoped=true&"
import script from "./gifter.vue?vue&type=script&lang=js&"
export * from "./gifter.vue?vue&type=script&lang=js&"
import style0 from "./gifter.vue?vue&type=style&index=0&id=5375d478&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5375d478",
  null
  
)

export default component.exports