<template>
  <!-- 首页 -->
  <div class="homevue">
    <div class="conte">
      <div class="top">
        <div class="topone">
          <div class="toponeLeft">
            <div class="tou">
              <img :src="user.avatar" alt="" class="img" />
            </div>
            <div class="l-r">
              <div class="name" style="margin-left: 0.5rem;" v-if="income">{{ user.nickname }}</div>
              <div class="bk"></div>
            </div>
            <!-- {{ $t('home.top') }} -->
          </div>
          <div class="toponeright">
            <img src="../assets/hbjl.png" @click="$router.push('/signin')" />
            <!-- <img src="../assets/indextop.png" @click="appxz" />  rechangelang-->
            <img src="../assets/kefu.png" @click="tokefu" />
            <!-- <img src="../assets/indextop3.png" @click="tokefu" style="margin-right: 0;" /> -->
          </div>
        </div>
        <!-- <div class="toptwo">
        		{{username}}
        	</div> -->
      </div>

      <div class="titbg">
        <div class="tt-item">
          <div class="t-f">
            <div class="zong" v-if="income">
              {{ $t("home.txt1") }}：{{
                isBlock ? "***" :  income.total_money || 0
              }}
            </div>
            <div class="eye">
              <!-- <img src="../assets/eye.png" alt="" class="img"/> -->
              <van-icon
                v-if="!isBlock"
                @click="isBlock = !isBlock"
                size="2.4rem"
                class="img"
                name="eye-o"
              />
              <van-icon
                v-if="isBlock"
                @click="isBlock = !isBlock"
                size="2.4rem"
                class="img"
                name="closed-eye"
              />
            </div>
          </div>
          <div class="s-f">
            <div class="s-i">
              <div class="s-l">
                {{ $t("home.txt2") }}：{{
                  isBlock ? "***" : income.today_income || "0"
                }}
              </div>
              <div class="s-r">
                {{ $t("home.txt3") }}：{{
                  isBlock ? "***" : income.total_income || "0"
                }}
              </div>
            </div>
            <div class="s-i">
              <div class="s-l">
                {{ $t("home.txt4") }}：{{
                  isBlock ? "***" : income.prize_income || "0"
                }}
              </div>
              <div class="s-r">
                {{ $t("home.txt5") }}：{{
                  isBlock ? "***" : income.withdraw_money || "0"
                }}
              </div>
            </div>
            <div class="s-i">
              <div class="s-l">
                {{ $t("home.txt28") }}：{{
                  isBlock ? "***" : income.coin || "0"
                }}
              </div>
              <div class="s-r">

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 11.14修改one-->
      <div class="inbody" :style="{ backgroundImage: `url(${bgImage})` }">
        <div class="bodyone">
          <div class="bodyonediv" @click="$router.push('/Recharges')">
            <img src="../assets/inbodys.png" />
            <div>{{ $t("home.txt6") }}</div>
          </div>
          <div class="bodyonediv" @click="towithdraw">
            <img src="../assets/inbody2.png" />
            <div>{{ $t("home.txt7") }}</div>
          </div>
          <!-- @click="$router.push('/recharges')" -->
          <!-- <div class="bodyonediv" @click="towithdraw">
        			<img src="../assets/inbody3.png" />
        			<div>{{$t('addhome.inbodysan')}}</div>
        		</div> -->
          <div class="bodyonediv" @click="$router.push('/Share')">
            <img src="../assets/inbody3.png" />
            <div>{{ $t("home.txt8") }}</div>
          </div>
          <div class="bodyonediv">
            <img src="../assets/inbody4.png" @click="$router.push('/party')" />
            <div>{{ $t("home.txt9") }}</div>
          </div>
          <!-- <div class="bodyonediv" v-if="dwstatus == 0" @click="torecharge">
              <img src="../assets/inbody3er.png" />
              <div>Withdrawal</div>
            </div>  -->
        </div>
        <!-- <div class="bodyone" style="margin-top: 1.5rem; height: 4rem">
          <div
            class="bodyonediv"
            style="height: 3rem"
            @click="$router.push('/companycetails')"
          >
            <img src="../assets/inbody5.png" />
            <div>{{ $t("home.txt10") }}</div>
          </div>
          <div
            class="bodyonediv"
            style="height: 4rem"
            @click="toAnnouncement(0)"
          >
            <img src="../assets/inbody6.png" />
            <div style="font-size: 1.1rem">{{ $t("home.txt11") }}</div>
          </div>
          <div
            class="bodyonediv"
            style="height: 3rem"
            @click="$router.push('/RunningLog')"
          >
            <img src="../assets/232.png" />
            <div>{{ $t("home.txt12") }}</div>
          </div>
          <div class="bodyonediv" style="height: 3.5rem" @click="toStart">
            <img src="../assets/inbody7.png" style="margin-bottom: 0.7rem" />
            <div style="color: #fe6719">{{ $t("home.txt13") }}</div>
          </div>
        </div> -->

        <div class="bodyone">
          <div class="bodyonediv" @click="$router.push('/companycetails')">
            <div class="b-img"><img src="../assets/inbody5.png" /></div>
            <div>{{ $t("home.txt10") }}</div>
          </div>
          <div class="bodyonediv" @click="toAnnouncement(0)">
            <div class="b-img"><img src="../assets/inbody6.png" /></div>
            <div style="font-size: 1.1rem">{{ $t("home.txt11") }}</div>
          </div>
          <div class="bodyonediv"  @click="$router.push('/RunningLog')">
            <div class="b-img"><img src="../assets/232.png" /></div>
            <div>{{ $t("home.txt12") }}</div>
          </div>
          <div class="bodyonediv"  @click="toStart">
            <div class="b-img"><img src="../assets/inbody7.png" /></div>
            <div style="color: #fe6719">{{ $t("home.txt13") }}</div>
          </div>
        </div>
      </div>

      <div class="infos" @click="toAnnouncement(0)">
        <div class="megaphone">
          <img src="../assets/horn.png" alt="" />
        </div>
        <div class="gonggao">
          <marquee class="announcement" style="margin: 0.4rem 0.625rem">
            <span
              style="
                font-size: 1.4rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #000000;
              "
              >{{ announcement }}</span
            >
          </marquee>
        </div>
        <!-- <div class="arrow">
        		<img src="../assets/arrowheads.png" alt="" />
        	</div> -->
      </div>
    </div>
    <div class="tabbar">
      <van-tabs
        v-model="activefour"
        :border="true"
        background="#ffffff"
        title-inactive-color="#000000"
        title-active-color="#000000"
        color="#FE6719"
        class="tabarmagin"
        @change="chanminute"
      >
        <van-tab :title="$t('home.txt14')">
          <div class="icont">
            <div class="null" v-if="twoList.length == 0">
              {{ $t("home.txt21") }}
            </div>
            <div class="cont-i" v-for="(item, index) in twoList" :key="index">
              <div class="co-l">
                <div class="i-l">
                  <img :src="url + item.pic" alt="" />
                </div>
                <div class="i-c">
                  <div class="i-c-o"> <div class="i-c-l">{{ item.title }}</div> <div class="status" v-if="item.status!='1'"> {{item.status_text}}</div></div>
                  <div class="i-c-f">{{ $t("home.txt23") }}：<span v-html="item.show_price"></span> </div>
                  <!-- <div>{{ $t("home.txt23") }}：{{ item.show_price }}</div> -->
                 <!-- <div>
                    {{ $t("home.txt18") }}：{{ item.show_daily_income }}<span v-html="item.show_price"></span>
                  </div> -->
                  <div class="i-c-f">
                    {{ $t("home.txt18") }}：<span v-html="item.show_daily_income"></span>
                  </div>
                  <div>{{ $t("home.txt19") }}：{{ item.days }}</div>
                  <div>
                    {{ $t("home.txt20") }}：<span
                      style="color: #fe6719; font-size: 1.2rem"
                      >{{ item.total_revenue }} USDT</span
                    >
                  </div>
                </div>
              </div>
              <div class="co-r">
                <div class="goum" @click="showcell2(item)">
                  <div class="but">{{ $t("home.txt22") }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('home.txt15')">
          <div class="icont">
            <div class="null" v-if="oneList.length == 0">
              {{ $t("home.txt21") }}
            </div>
            <div class="cont-i" v-for="(item, index) in oneList" :key="index">
              <div class="co-l">
                <div class="i-l">
                  <img :src="url + item.pic" alt="" />
                </div>
                <div class="i-c">
                  <div class="i-c-o"> <div class="i-c-l">{{ item.title }}</div> <div class="status" v-if="item.status!='1'"> {{item.status_text}}</div></div>
                  <div class="i-c-f">{{ $t("home.txt23") }}：<span v-html="item.show_price"></span> </div>
                  <!-- <div>{{ $t("home.txt23") }}：{{ item.show_price }}</div> -->
                 <!-- <div>
                    {{ $t("home.txt18") }}：{{ item.show_daily_income }}<span v-html="item.show_price"></span>
                  </div> -->
                  <div class="i-c-f">
                    {{ $t("home.txt18") }}：<span v-html="item.show_daily_income"></span>
                  </div>
                  <div>{{ $t("home.txt19") }}：{{ item.days }}</div>
                  <div>
                    {{ $t("home.txt20") }}：<span
                      style="color: #fe6719; font-size: 1.2rem"
                      >{{ item.total_revenue }} USDT</span
                    >
                  </div>
                </div>
              </div>
              <div class="co-r">
                <div class="goum" @click="showcell1(item)">
                  <div class="but">{{ $t("home.txt22") }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('home.txt16')">
          <div class="icont">
            <div class="null" v-if="discountList.length == 0">
              {{ $t("home.txt21") }}
            </div>
            <div
              class="cont-i"
              v-for="(item, index) in discountList"
              :key="index"
            >
              <div class="co-l">
                <div class="i-l">
                  <img :src="url + item.pic" alt="" />
                </div>
                <div class="i-c">
                  <div class="i-c-o"> <div class="i-c-l">{{ item.title }}</div> <div class="status" v-if="item.status!='1'"> {{item.status_text}}</div></div>
                  <div>{{ $t("home.txt23") }}：{{ item.show_price }}</div>
                  <div>
                    {{ $t("home.txt18") }}：{{ item.show_daily_income }}
                  </div>
                  <div>{{ $t("home.txt19") }}：{{ item.days }}</div>
                  <div>
                    {{ $t("home.txt20") }}：<span
                      style="color: #fe6719; font-size: 1.2rem"
                      >{{ item.total_revenue }} USDT</span
                    >
                  </div>
                </div>
              </div>
              <div class="co-r">
                <div class="goum" @click="showcell3(item.id)">
                  <div class="but">{{ $t("home.txt22") }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <!-- 弹出框 -->
    <van-action-sheet
      v-model="show"
      @select="onSelect"
      @close="closeActionSheet"
    >
      <div class="boxcontent">
        <div class="title">{{ $t("Mining.Experience") }}</div>
        <div class="power">
          <div class="Price">{{ $t("Mining.Price") }}</div>
          <div>{{ list.price * add_num }} USDT</div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.profit") }}</div>
          <div v-html="list.show_daily_income"></div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("home.txt19") }}</div>
          <div>{{ list.days }}</div>
        </div>
        <!-- <div class="power">
          <div class="Price">{{ $t("Mining.End") }}</div>
          <div>{{ list.end }}</div>
        </div> -->
        <div class="power">
          <div class="Price">{{ $t("Mining.gmsl") }}</div>
          <div style="display: flex; align-items: center">
            <span class="addnum" @click="jiannum">-</span>
            {{ add_num }}
            <span class="addnum" @click="addnum" style="margin-right: 0"
              >+</span
            >
          </div>
        </div>
        <div class="power">
          <div class="Price">{{ $t("Mining.yjsy") }}</div>
          <div>{{ totalPrice }} USDT</div>
        </div>
        <div class="tishi">
          {{ $t("addmin.tcyi") }}: {{ list.daily_output }} * {{ list.days }} *
          {{ add_num }} + {{ list.price }}*{{ add_num }}
        </div>
        <div class="agreement">
          <van-checkbox
            icon-size="2rem"
            v-model="checked"
            shape="square"
          ></van-checkbox>
          <div class="Agree">
            {{ $t("Mining.Agree") }} 《{{ $t("Mining.Escrow") }}》
          </div>
        </div>
        <div class="boxinfo" ref="boxinfo"></div>
        <div class="btn" @click="Purchase">{{ $t("Mining.Confirm") }}</div>
      </div>
    </van-action-sheet>

    <!-- <van-popup round v-model="show">
			<div class="SignIn-content">
				<div class="content-box">
					<div class="box" :class="{ bgcolor: bgcolor >= 1 }">
						<div class="day">{{ $t("home.SignIn1") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 2 }">
						<div class="day">{{ $t("home.SignIn2") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 3 }">
						<div class="day">{{ $t("home.SignIn3") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 4 }">
						<div class="day">{{ $t("home.SignIn4") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 5 }">
						<div class="day">{{ $t("home.SignIn5") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>
					<div class="box" :class="{ bgcolor: bgcolor >= 6 }">
						<div class="day">{{ $t("home.SignIn6") }}</div>
						<div class="img">
							<img src="../assets/Venus.png" alt="" />
						</div>
					</div>

					<div class="Seven" :class="{ bgSeven: bgcolor >= 7 }"
						style="padding: 0.5rem;align-items: center;padding-left: 1rem;">
						<div class="info" style="text-align: center;">
							<div class="tian">
								<div class="day" style="width: 100%;margin-bottom: 1rem;">{{ $t("SignIn.SignIn7") }}
								</div>
							</div>
							<div class="title" style="margin-top: 0;">
								{{$t('addhome.tcyi')}}<br />{{$t('addhome.tcer')}}
							</div>
						</div>
						<div class="img">
							<img src="../assets/gift.png" alt="" />
						</div>
					</div>
				</div>
				<div class="button">
					<button class="btn" @click="receive">{{ chick }}</button>
				</div>
			</div>
		</van-popup> -->
    <van-popup round v-model="gonggaoshow">
      <div class="gonggaobox" @click="togaonggao">
        <img class="gonggao" src="../assets/gonggao.png" alt="" />
        <div class="boxtitle">{{ $t("addhome.tcsan") }}</div>
        <div class="info">
          <!-- <div class="neirong" v-for="(v, i) in gonggaolist" :key="i" @click="togaonggao">
            <div class="neirongtitle">{{ v.title }}</div>
            <div class="right">
              <img src="../assets/RightArrow.png" alt="" />
            </div>
          </div> -->
          <div ref="detils" @click="todetils"></div>
        </div>
      </div>
    </van-popup>
    <!-- 公告图片放大-->
    <van-overlay :show="bigimgshow" @click="bigimgshow = false" z-index="2024">
      <div class="wrapper" @click.stop>
        <img :src="bigimg" />
      </div>
    </van-overlay>

    <van-popup v-model="newshow" position="bottom" :style="{ height: '30%' }">
      <!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
      <van-picker
        title=""
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        confirm-button-text="Confirmar"
        cancel-button-text="Cancelar"
      />
    </van-popup>

    <!-- 气泡拖拽-->
    <BaoXiang
      v-if="turntable == 1"
      :turn_table="turntable"
      @changecjx="cjx"
    ></BaoXiang>
    <!-- /气泡拖拽-->
    <suspended v-if="telegram || whatsapp"></suspended>
    <TabBar></TabBar>

    <Miner ref="minerRef" :questions="question"></Miner>
  </div>
  <!-- </div> -->
</template>

<script>
import {
  getreceive,
  getSignin,
  gethomeapi,
  Noticelistapi,
  gethomeListapi,
  assistdetailsapi,
  getlanguage,
  startdeviceapi,
  Productpurchaseapi,
  getIndexService,
  confirmdeviceapi,
  myuserapi
} from "@/api/index";
// import { gethomeapi } from '@/api/index'
import { Toast } from "vant";
import Suspended from "../components/Suspended.vue";
import BaoXiang from "../components/BaoXiang.vue";
import Miner from '../components/Miner.vue'
export default {
  name: "HoMe",
  components: {
    Suspended,
    BaoXiang,
    Miner
  },
  data() {
    return {
      user: undefined,
      isBlock: false,
      url: "https://mxxai.com",
      columns: [],
      activefour: "",
      discountList: [],
      oneList: [],
      twoList: [],
      newshow: false,
      gonggaoshow: false,
      show: false,
      bgcolor: 0,
      startStatus: false,
      bannerlist: [
        {
          id: 77,
          thumb_style: "",
          url: "/uploads/20230807/d5337373d4f168822352fec6b6c9fe79.png",
        },
      ],
      announcement: "",
      btclist: [],
      funList: [{ name: "初级" }, { name: "高级" }, { name: "促销" }],
      username: "", // 用户名
      coin: "",
      time: 0,
      day: 0,
      shi: 0,
      fen: 0,
      miao: 0,
      times: null,
      chick: this.$t("SignIn.Sign"),
      float: 1,
      telegram: "1",
      whatsapp: "1",
      isLoading: false,
      // bfb: '-0.01%',
      statustype: 0,
      gonggaolist: [],
      detils: "", // 公告具体内容
      detilsid: 0, // 公告id
      turntable: 0,
      dwstatus: 0, // 提款状态
      walletarr: {}, // 我的内容
      profit_return: 0,
      award_profit: 0,
      today_earn: 0,
      money: 0,
      rate: 0,
      bangzhulist: [], // 帮助列表
      kfurl: "",
      total_revenue: "",
      offset: {
        x: 200,
        y: 400,
      },
      income: '',
      download: {},
      sigintype: true,
      bigimgshow: false, // 公告图片放大
      bigimg: "", // 公告图片
      bgImage:'',
      active: 0,
      Miningmachinelist: [],
      checked: true,
      list: {},
      id: 0,
      typelist1: [],
      typelist2: [],
      Experience: "",
      Advanced: "",
      add_num: 1,
      question: [],
    };
  },
  created() {
    this.getUserInfo()
    // 首页数据
    this.homelist();
    this.homelists();
    // this.username = JSON.parse(localStorage.getItem("userInfo")).nickname || "";

  },
  computed: {
      totalPrice() {
        return this.list.total_revenue * this.add_num
      }
  },
  destroyed() {
    // clearInterval(this.times)
  },
  methods: {
    async getUserInfo() {
      // this.user = JSON.parse(localStorage.getItem('userInfo'))
      const res = await myuserapi();
      // console.log(res, "my");
      this.user = res.data.user;
      console.log(1111)
    },
    async getBuy(data) {
      const res = await Productpurchaseapi({
        buy_num: this.add_num,
        pro_id: this.id
      });
      console.log(res);

      this.show = true;
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.checked = true;
    },
    addnum() {
      // +
      // console.log(this.list.can_buy)
      if (
        this.list.total_revenue == 0 ||
        this.add_num >= this.list.member_can_buy
      ) {
        Toast({
          message: this.$t("addmin.tsyi"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      this.add_num++;
    },
    jiannum() {
      if (this.add_num == 1) {
        Toast({
          message: this.$t("addmin.tser"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      this.add_num--;
    },
    async Purchase() {
      if (this.list.can_buy == 0) {
        Toast({
          message: this.$t("addmin.tssan"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      if (this.add_num == 0) {
        Toast({
          message: this.$t("addmin.tssi"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      if (this.checked) {
        const data = {
          pro_id: this.id,
          buy_num: this.add_num,
        };
        const res = await Productpurchaseapi(data);
        // console.log('0123', res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        this.show = false;
        this.checked = false;
        this.typelist1 = [];
        this.typelist2 = [];
        this.homelist()
        // 购买之后重新获取数据
        //   this.Miningmachine()
      } else {
        Toast({
          message: this.$t("Mining.first"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
      }
    },
    async showcell1(item) {
      this.checked = true;
      this.id = item.id;
      this.show = true;
      this.oneList.forEach(async (i) => {
        if (i.id === item.id) {
          this.list = await i;
          // console.log('6666', this.Miningmachinelist[v])
          this.$refs.boxinfo.innerHTML = await this.list.detail;
        }
      });
      const data = {
        pro_id: item.id,
      };
      // await DeviceHitsapi(data)
    },
    async showcell2(item) {

      this.checked = true;
      this.id = item.id;
      this.show = true;
      this.twoList.forEach(async (i) => {
        if (i.id === item.id) {
          this.list = await i;

          // console.log('6666', this.Miningmachinelist[v])
          this.$refs.boxinfo.innerHTML = await this.list.detail;
        }
      });
      const data = {
        pro_id: item.id,
      };
      // await DeviceHitsapi(data)
    },
    async showcell3(id) {
      this.checked = true;
      this.id = id;
      this.show = true;
      this.Miningmachinelist.forEach(async (i) => {
        if (i.id === id) {
          this.list = await i;
          // console.log('6666', this.Miningmachinelist[v])
          this.$refs.boxinfo.innerHTML = await this.list.detail;
        }
      });
      const data = {
        pro_id: id,
      };
      await DeviceHitsapi(data);
    },
    async toStart() {
      if(this.startStatus) return

      this.startStatus = true
      const res = await startdeviceapi();
      if (res.code == 0) {
        Toast({
          message: res.msg,
          duration: 1200,
        });
      } else if (res.code == 1) {
        Toast({
          message: res.msg,
          duration: 1200,
        });
      }else if(res.code == 200) {
          this.question = res.data.question
          console.log(this.question)
          if(this.question.length==0){
            this.onSubmits()
          }else{
            this.$refs.minerRef.showDialog()
          }
      }
      this.startStatus = false
    },
    async onSubmits() {
      const res = await confirmdeviceapi();
    },
    async homelists() {
      const res = await gethomeListapi();
      this.discountList = res.data.discount;
      this.oneList = res.data.one;
      this.twoList = res.data.two;

    },
    async getlanguage() {
      const res = await getlanguage();
      res.data.forEach((i) => {
        this.columns.push(i.name);
      });
    },
    chanminute() {},
    onConfirm(e) {
      console.log(e, "666");
      if (e == "English") {
        localStorage.setItem("newlang", "en");
      }
      if (e == "Español") {
        localStorage.setItem("newlang", "es");
      }
      localStorage.setItem("lang", e);

      this.$i18n.locale = e;
      this.newshow = false;
    },
    onCancel() {
      this.newshow = false;
    },
    onChange() {},
    rechangelang() {
      this.newshow = true;
    },
    // gonggaoclcik () {
    // console.log(111)
    // 公告弹窗
    //  this.gonggaoshow = true
    //  sessionStorage.setItem('gonggaoshow', 1)
    // },
    togaonggao() {
      this.$router.push("/Announcement");
    },
    // // 公告
    async Noticelistapi() {
      const data = {
        type: "2",
      };
      const res = await Noticelistapi(data);
      console.log(res, "公告,111111111111111");
      this.announcement = res.data.content;
      // this.detils = res.data.content
      // this.detilsid = res.data.id
      // this.$refs.detils.innerHTML = this.detils
      // this.gonggaolist = res.data
      // this.gonggaolist = this.gonggaolist.slice(0, 2)
    },
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    // 去抽奖
    tocj() {
      // if (this.turntable == 0) {
      // 	Toast({
      // 		message: this.$t('newhome.title'),
      // 		duration: 1200,
      // 		className: 'noticeWidth' // 所加样式名
      // 	})
      // 	return false
      // }
      this.$router.push("/turntable");
    },
    // 去活动页面
    togift(href) {
      this.$router.push(href);
    },
    // 去官网
    toguanwang() {
      // console.log(111)
    },
    async homelist() {
      const res = await gethomeapi();
      this.income = res.data;
      this.announcement = res.data.notice;
      this.bgImage = res.data.index_bg
      console.log(res.data);

      this.kfurl = res.data.service
      // this.Noticelistapi()
      if (parseInt(res.data.notice) === 1) {
        // 公告
        this.gonggaoshow = true;
      } else {
        this.gonggaoshow = false;
      }
      // console.log('6666666', this.btclist)
      // this.times = setInterval(() => {
      // 	const sjchuo = Date.now() - this.time * 1000
      // 	this.formatDuring(sjchuo)
      // }, 1000)
    },

    // 时间戳转天，时分秒
    formatDuring(mss) {
      const days = parseInt(mss / (1000 * 60 * 60 * 24));
      const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = ((mss % (1000 * 60)) / 1000).toFixed(0);
      // console.log(days, hours, minutes, seconds)
      this.day = days;
      this.shi = hours;
      this.fen = minutes;
      this.miao = seconds;
    },
    // 签到天数接口
    async Signin() {
      const res = await getSignin();
      console.log(res, "签到");
      this.bgcolor = res.data.sign_count;
      this.statustype = res.data.status;
      if (res.data.status === 0) {
        this.chick = this.$t("SignIn.Sign");
      } else {
        this.chick = this.$t("SignIn.chick");
      }
    },
    // 签到按钮
    async receive() {
      // this.sigintype = true
      if (this.sigintype) {
        this.sigintype = false;
        const res = await getreceive();
        if (res.code !== 1) {
          Toast({
            message: res.msg,
            duration: 1200,
            className: "noticeWidth", // 所加样式名
          });
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: "noticeWidth", // 所加样式名
          });
          this.Signin();
        }
        setTimeout(() => {
          this.show = false;
          this.sigintype = true;
        }, 1000);
      }
      // this.sigintype = false
      // setTimeout(() => {
      //   // 公告弹窗
      //   if (sessionStorage.getItem('gonggaoshow')) {
      //     this.gonggaoshow = false
      //   } else {
      //     this.gonggaoshow = true
      //     sessionStorage.setItem('gonggaoshow', 1)
      //   }
      // }, 1600)
    },
    // 去公告页面
    toAnnouncement(id) {
      // this.$router.push("/announcement");
      this.$router.push({
        path: "/announcement",
        query: {
          id,
        },
      });
    },
    // 去签到页面
    tosignin() {
      this.$router.push("/signin");
    },
    // 去k线图
    todiagram(name, bfb, zf) {
      // console.log(name)
      // console.log(bfb)
      this.$router.push({
        path: "/diagram",
        query: {
          name,
          bfb,
          zf,
        },
      });
      // this.$router.push({
      //   path: '/gift'
      // })
    },
    //11.15
    // 去提现
    towithdraw() {
      this.$router.push("/withdraw");
      // if(this.dwstatus == 1){
      //   this.$router.push('/withdraw')
      // }else{
      //   Toast({
      //     message: 'Please activate the machine first',
      //     duration: 2000,
      //     className: 'noticeWidth' // 所加样式名
      //   })
      //   this.$router.push('/mining')
      // }
    },
    torecharge() {
      // 去充值页面
      this.$router.push("/recharges");
    },
    //get 我的钱包
    async getmyuserapi() {
      const res = await myuserapi();
      this.walletarr = res.data.user;
      this.download = res.data.download;
      this.profit_return = parseFloat(this.walletarr.profit_return).toFixed(2);
      this.award_profit = parseFloat(this.walletarr.award_profit).toFixed(2);
      this.today_earn = parseFloat(this.walletarr.today_earn).toFixed(2);
      this.money = parseFloat(this.walletarr.money).toFixed(2);
      this.rate = parseFloat(res.data.rate).toFixed(2);
    },
    // get帮助列表
    async assistdetails() {
      const data = {
        type: 2,
      };
      const res = await assistdetailsapi(data);
      // console.log(res)
      this.bangzhulist = res.data;
    },
    tohelp(id) {
      // 跳转帮助详情
      this.$router.push({
        path: "/bangzhudetails",
        query: {
          id,
        },
      });
    },
    tokefu() {
      // window.location.href = window.location.protocol + "//" + this.kfurl
      // window.location.href = this.kfurl;
      window.open(this.kfurl)
      // window.open(this.kfurl, "_blank");
    },
    cjx(e) {
      // console.log(e)
      this.turntable = e;
    },
    appxz() {
      console.log(this.$imgUrl + this.download.IOS.url);
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        window.open(this.$imgUrl + this.download.IOS.url);
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        window.open(this.$imgUrl + this.download.Android.url);
      } else {
        // client = 'PC'
      }
    },
    todetils(e) {
      const target = e.target.nodeName;
      // console.log(target)
      // return false
      if (target && target == "IMG") {
        const targetsrc = e.target.src;
        console.log(111, targetsrc);
        this.bigimgshow = true;
        this.bigimg = targetsrc;
      } else {
        // console.log(id)
        const id = this.detilsid;
        this.$router.push({
          path: "/gonggaodetails",
          query: {
            id,
          },
        });
      }
    },

    closeActionSheet() {
      setTimeout(() => {
        this.add_num = 1;
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
html {
  background-color: #f9f9f9;
  .homevue {
    width: 100%;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    .conte {
      padding: 0rem 1.6rem;
    }
    .top {
      .topone {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.625rem;
        .toponeLeft {
          display: flex;
          align-items: center;
          .tou {
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            margin-right: 0.3rem;
            .img {
              width: 3.8rem;
              height: 3.8rem;
              border-radius: 50%;
            }
          }
          .l-r {
            .name {
              font-size: 1.2rem;
              margin-bottom: 0.2rem;
              font-weight: bold;
            }
            .bk {
              font-size: 1rem;
              color: #989898;
            }
          }
        }
        .toponeright {
          img {
            margin-left: 1.5rem;
          }
        }
      }

      .toptwo {
        color: #000;
        font-size: 2.125rem;
        font-weight: 700;
      }
    }

    .top .topone img {
      width: 2rem;
      height: 2rem;
    }

    .titbg {
      margin-top: 1rem;
      padding: 0;
      .tt-item {
        background-image: url("../assets/titbg.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 3rem 2rem 2rem 2rem;
        border-radius: 2rem;
        .t-f {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .zong {
            font-size: 1.5rem;
            font-weight: bold;
          }
          .eye {
            width: 2.5rem;
            height: 1.5rem;
            .img {
              width: 2.5rem;
              height: 1.5rem;
            }
          }
        }
        .s-f {
          margin-top: 1rem;
          font-size: 1.1rem;
          .s-i {
            display: flex;
            align-items: center;
            color: #6d6d6d;
            .s-l {
              width: 50%;
              text-align: left;
              height: 3rem;
              line-height:3rem;
              overflow: hidden; /* 隐藏溢出的内容 */
              white-space: nowrap; /* 防止文本换行 */
              text-overflow: ellipsis; /* 显示省略号来表示溢出的内容 */
            }
            .s-r {
              width: 50%;
              text-align: left;
              height: 3rem;
              line-height: 3rem;
              overflow: hidden; /* 隐藏溢出的内容 */
              white-space: nowrap; /* 防止文本换行 */
              text-overflow: ellipsis; /* 显示省略号来表示溢出的内容 */
            }
          }
        }
      }
    }

    .tabbar {
      margin-top: 1rem;
      .icont {
        padding: 2rem;
        .null {
          min-height: 30vh;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.3rem;
        }
        .cont-i {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 0.5px solid #e1e1e1;
          .co-l {
            display: flex;
            align-items: center;
            .i-l {
              width: 8rem;
              height: 8rem;
              background-color: #14305b;
              border-radius: 0.5rem;
              margin-right: 0.6rem;
              img {
                width: 8rem;
                height: 8rem;
              }
            }
            .i-c {
              .i-c-o {
                font-size: 1.3rem;
                font-weight: bold;
                margin-bottom: .3rem;
                padding-bottom: .3rem;
                display: flex;
                align-items: center;
                .i-c-l{
                  padding-top: .3rem;
                }
                .status{
                  margin-left: 1rem;
                  background-color: #1d9afe;
                  padding: .3rem;
                  color: #ffffff;
                  font-size: .8rem;
                  border-radius: .3rem;
                }
              }
              .i-c-f{
                display: flex;align-items: center;
              }
              font-size: 1.1rem;
            }
          }
          .co-r {
            .num {
              text-align: center;
              font-size: 1.5rem;
            }
            .goum {
              display: flex;
              justify-content: flex-end;
              .but {
                width: 6rem;
                height: 2.5rem;
                line-height: 2.5rem;
                text-align: center;
                color: #ffffff;
                background-color: #fe6719;
                border-radius: 0.6rem;
                font-size: 1.1rem;
              }
            }
          }
        }
      }
      // padding: 0 2rem;
      .fun {
        width: 30%;
        .name {
        }
      }
    }

    .Broadcast {
      margin-top: 2.5rem;
      z-index: 9;

      ::v-deep .van-swipe {
        z-index: 9;
      }

      ::v-deep .van-swipe-item {
        width: 34.3rem;
        height: 16.8rem;
        line-height: 16, 8rem;
        text-align: center;
        z-index: 9;

        .hornimg {
          width: 100%;
          height: 100%;
          border-radius: 0.5rem !important;
          z-index: 9;
        }
      }
    }

    .infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fe6719;
      margin-top: 2rem;
      padding: 0.5rem 1rem;
      border-radius: 10px;

      .gonggao {
        // margin-top: 0.5rem;

        .announcement {
          width: 28rem;
          // height: 100%;
        }
      }

      .megaphone {
        width: 1.5rem;
        height: 1.5rem;
        // margin-top: 1rem;
      }

      // .arrow {
      // 	width: 1.5rem;
      // 	height: 1.5rem;
      // 	// margin-top: 1rem;
      // }
    }

    .content {
      // width: 33rem;
      // height: 12.1rem;
      // border: 1px solid #0A3D2D;
      // margin: 0 auto;
      // background-color: rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      margin-top: 1.825rem;
      background: #f4f6f8;
      padding: 1.5rem 0;
      color: #9b9c9d;
      // padding: 1rem;
      // box-shadow: 0rem 0.1rem 0.5rem 0.5rem rgba(255, 255, 255, 0.2);

      .content-top {
        display: flex;
        margin-top: 0.5rem;
        // margin-left: 1.8rem;

        .Money {
          width: 2.05rem;
          height: 2.05rem;
        }

        .content-title {
          line-height: 2.2rem;
          font-size: 1.4rem;
          margin-left: 0.5rem;
        }
      }

      .time {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        color: #4065ff;

        // margin-top: 2.25rem;
        .time-DAY,
        .time-MIN,
        .time-HOUR,
        .time-SEC {
          width: 25%;
          text-align: center;
          border-right: 1px solid #e1e3e5;
          font-size: 1.5rem;

          .time-title {
            margin-bottom: 0.6rem;
            color: #9b9c9d;
            font-size: 1.2rem;
          }
        }

        .time-SEC {
          border-right: 0px solid #262626;
        }
      }
    }

    .indexbody {
      // background: #26272E;
      border-radius: 0.625rem;
      // padding: 1.875rem 0;
      margin-top: 1rem;
      // .indexbodyx{
      //   width: 27rem;
      //   margin-left: 4rem;
      //   border-bottom: 0.125rem solid #3C3D43;
      // }
    }

    .currencies {
      // margin-top: 2rem;
      color: #333;
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .Bitcoin,
    .Bitcoin1,
    .Bitcoin2 {
      // width: 34.2rem;
      height: 5.8rem;
      // background-color: #2b2b2b;
      // background: url("../assets/btc.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 1rem;
      // margin-top: 1.5rem;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 1.5rem;

      .Bitcoin-left {
        display: flex;
        align-items: center;

        .Bitcoin-img {
          // margin-top: 0.5rem;
          width: 2.84rem;
          height: 2.84rem;
          margin-right: 1rem;
        }

        .Bitcoin-name {
          padding-top: 0.3rem;
        }
      }

      .Bitcoin-right {
        text-align: right;

        .Price {
          color: #333;
          margin-top: 0.4rem;
          font-weight: bold;
        }

        .increase,
        .red {
          color: #fd1050;
          font-size: 1.3rem;
          // margin-right: 1.5rem;
          margin-top: 0.2rem;
        }

        .increase {
          color: #7c7d81 !important;
        }

        .grend {
          color: #2dbe64;
          font-size: 1.3rem;
          margin-top: 0.2rem;
        }
      }
    }

    .Bitcoin1 {
      // background: url("../assets/eth.png") no-repeat;
      background-size: 100% 100%;
    }

    .Bitcoin2 {
      // background: url("../assets/doge.png") no-repeat;
      background-size: 100% 100%;
    }

    .guanwang {
      width: 100%;
      height: 14rem;
      margin-top: 3rem;
      border-radius: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
      }
    }

    ::v-deep .van-popup {
      background-color: #1f1f1f;
    }
    .addnum {
      font-size: 2rem;
      font-weight: 700;
      color: #fe6719;
      margin: 0 1.5rem;
    }
    ::v-deep .van-action-sheet {
      background-color: #fff;
      // padding: 0rem 1.6rem;
      .boxcontent {
        background-image: url('../assets/sbg.png');
        background-size: cover;
        width: 100%;
        // margin: 0 auto;
        padding: 0 2rem;
        .title {
          // margin-top: 2rem;
          color: #333;
          padding-top: 2.5rem;
          font-size: 1.6rem;
          text-align: center;
          font-weight: 600;
          margin-bottom: 2.5rem;
        }
        .power {
          padding: 0.7rem 0rem;
          display: flex;
          justify-content: space-between;
          color: #333;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          font-size: 1.2rem;
          .Price {
            font-size: 1.4rem;
            color: #333;
          }
        }
        .agreement {
          display: flex;
          color: #333;
          font-size: 1.4rem;
          margin-top: 1.9rem;
          .Agree {
            margin-left: 1.3rem;
          }
        }
        .boxinfo {
          width: 100%;
          color: #333;
          font-size: 1.4rem;
          margin-top: 3rem;
          padding-bottom: 5rem;
          p {
            // margin: 1.5rem 0rem;
            span {
              background-color: #1f1f1f !important;
            }
          }
        }

        .btn {
          position: fixed;
          bottom: 1.5rem;
          left: 50%;
          transform: translateX(-50%);
          width: 34.3rem;
          height: 4.4rem;
          background-color: #fe6719;
          margin: 0 auto;
          // margin-top: 5rem;
          border-radius: 2.75rem;
          text-align: center;
          line-height: 4.4rem;
          color: #fff;
          // margin-bottom: 5rem;
        }
      }
    }

    .SignIn-content {
      width: 34.3rem;
      height: 29.15rem;
      background-color: #f4f6f8;
      // margin-top: 2.5rem;
      padding: 1.7rem 1rem;
      border-radius: 1rem;

      .content-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .box,
        .bgcolor {
          width: 7.4rem;
          height: 9rem;
          background-color: #d5d5d5;
          text-align: center;
          margin-bottom: 0.9rem;
          border-radius: 0.5rem;

          .day {
            color: #333333;
            font-weight: 600;
            margin-top: 1rem;
          }

          .img {
            width: 3.4rem;
            height: 3.45rem;
            margin: 0 auto;
            margin-top: 1.1rem;
          }
        }

        .bgcolor {
          background-color: #f68520;
          color: #fff;
        }

        .Seven,
        .bgSeven {
          position: relative;
          width: 15.7rem;
          height: 9rem;
          background-color: #d5d5d5;
          padding: 0.8rem;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          border-radius: 0.5rem;

          .info {
            font-size: 1.2rem;
            color: #000;
            font-weight: 600;

            .day {
              width: 4rem;
            }

            .tian {
              display: flex;
              justify-content: space-between;
            }

            .title {
              width: 80%;
              // font-weight: 400;
              color: #969696;
              margin-top: 1rem;
            }
          }

          .img {
            position: absolute;
            bottom: 2.2rem;
            right: 1rem;
            width: 35%;
            height: 5rem;
          }
        }

        .bgSeven {
          background-color: #f68520;
        }
      }

      .button {
        width: 100%;
        text-align: center;
        margin-top: 1.3rem;

        .btn {
          background-color: #f68520;
          border: none;
          width: 25.9rem;
          height: 4.4rem;
          border-radius: 2.2rem;
          color: #fff;
          font-size: 1.4rem;
          text-align: center;
          font-weight: bold;
          line-height: 4.4rem;
        }
      }
    }

    .gonggaobox {
      position: relative;
      text-align: center;

      .gonggao {
        width: 29rem;
      }

      .boxtitle {
        position: absolute;
        top: 9rem;
        left: 50%;
        transform: translateX(-50%);
        color: #000;
        font-size: 1.5rem;
        font-weight: 700;
        // margin-top: 2rem;
      }

      .info {
        position: absolute;
        top: 12rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 20rem;
        color: #000;
        text-align: left;
        padding: 0rem 1.5rem;
        padding-top: 1rem;
        overflow: auto;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-wrap: break-word;

        .neirong {
          padding-top: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.2rem;

          .neirongtitle {
            width: 24rem;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // -o-text-overflow: ellipsis;
          }

          .right {
            width: 0.8rem;
          }
        }
      }
    }
  }
}

/* 修改*/
.usdt {
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  background: #333;
  border-radius: 1rem;
  padding: 1.5rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  .usdtleft {
    // display: flex;
    // align-items: center;
    width: 100%;

    // border-right: 1px solid #ccc;
    img {
      width: 3rem;
      margin-bottom: 1rem;
    }
  }

  .ustright {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
}

.usdtleft div:nth-child(1) {
  width: 2rem;
  height: 2rem;
  background: #fff;
  border-radius: 50%;
  margin-right: 2rem;
}

.ustright div:nth-child(1) {
  font-size: 2rem;
  color: #1fab87;
  font-weight: 700;
}

.indexcj {
  margin-top: 2rem;

  .indexbx {
    display: flex;

    // justify-content: end;
    .cjX {
      width: 2rem;
      height: 2rem;
      margin-top: 2rem;
    }

    .bx {
      width: 6rem;
    }
  }
}

// 11.14修改
.inbody {
  width: 100%;
  min-height: 15rem;
  // box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
  // border-radius: 10px 10px 10px 10px;
  // opacity: 1;
  // padding: 2rem;
  background-size:100% 100%;
  background-repeat: no-repeat;
  margin-top: 1.5rem;
  border-radius: 1rem;
  .bodyone {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .bodyonediv {
      width: 25%;
      padding: 16px 8px;
      .b-img{
        width: 100%;
        text-align: center;
        height: 3rem;
        margin-bottom: 0.5rem;
        img {
          width: 2.5rem;
        }
      }
      img {
        width: 2.5rem;
        width: 2.5rem;
        margin-bottom: 0.5rem;
      }
    }
    div {
      text-align: center;
    }
  }
}

// 11.14修改my wallet
.wallet {
  .wallettitle {
    margin: 2rem 0;
    font-size: 2rem;
    color: #333;
    font-weight: 700;
  }

  .walletbody {
    box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 1.5rem 2rem;
    font-size: 1.2rem;

    .walletmargin {
      margin-top: 1.5rem;
    }

    .wallzione {
      color: #acacac;
    }

    .wallzitwo {
      color: #02d79a;
    }
  }
}

// 11.14 修改插图img
.ctimg {
  margin-top: 2rem;
}

// 11.14 修改help
.help {
  .helptitle {
    margin: 2rem 0;
    font-size: 2rem;
    color: #333;
    font-weight: 700;
  }

  .helpbody {
    box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 1.5rem 2rem;
    font-size: 1.4rem;

    .helpbodyone {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #fbfbfb;
      padding-bottom: 1rem;
      margin-top: 1rem;

      div {
        font-weight: 700;
      }

      img {
        width: 0.5rem;
      }
    }
  }
}

// 11.14修改bottom
.bottomone {
  margin-top: 2rem;
  color: #333;
  font-size: 1.3rem;
}

// 11.14修改bottomer
.bottomer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  font-weight: 700;

  .left {
    text-align: center;
  }
}

.bottomer .left div:nth-child(1) {
  color: #333;
}

.bottomer .left div:nth-child(2) {
  color: #5979fb;
  margin-top: 1rem;
}

.bibody {
  box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 1.5rem 2rem;
}

// 11.20合作平台
.hezuo {
  margin: 1rem 0;
  margin-top: 2rem;

  .hztitle {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .hzbody {
    box-shadow: 0px 3px 20px 1px rgba(89, 121, 251, 0.2);
    border-radius: 10px 10px 10px 10px;
    // opacity: 1;
    padding: 1.5rem 2rem;
    font-size: 1.3rem;
    font-weight: 700;
    margin-top: 2rem;

    .bodyflex {
      display: flex;
      justify-content: space-between;

      .onediv {
        margin-top: 2rem;
        text-align: left;
      }

      // .onedivright{
      //   margin-left: 0.1rem !important;
      // }
    }

    img {
      width: 2rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
