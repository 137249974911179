<template>
  <div class="Share">
    <!-- 自定义nav-->
    <div class="Phonenumber-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.push('/Home')"
      />
      <div class="title">{{ $t("addtask.titleer") }}</div>
      <div></div>
    </div>
    <!-- 内容-->
    <!-- <div class="body">
      <div class="bodytitle">{{$t('addtask.shareyi')}}</div>
      <div class="bodyimg">
        <van-uploader
          v-model="fileList"
          :max-count="1"
          :after-read="afterRead"
          preview-size="15rem"
          :before-delete="afetdelete"
        /> -->
    <!-- <div class="imgdiv">
          +
        </div>
        <img src="" style="display: none;" /> -->
    <!-- </div>
      <div class="tishi">*{{$t('addtask.shareer')}}</div>
      <div class="btn" @click="btn">{{$t('addtask.sharesan')}}</div>
    </div> -->
    <div class="titbg">
      <div class="tt-item">
        <div class="t-f">
          <div class="zong">
            {{ $t("Share.txt1") }}
            <div class="vBg">VIP {{level}}</div>
          </div>
        </div>
        <div class="t-title">
          <div class="cont">
            <div class="fx">{{ $t("Share.txt2") }}</div>
            <div class="ljz">
              <div class="lj">{{ shareUrl }}</div>
              <div
                v-clipboard:copy="shareUrl"
                v-clipboard:success="onCopy"
                class="copy"
              >
                {{ $t("Share.txt3") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group">
      <div class="g-t">{{ $t("Share.txt4") }}</div>
      <div class="g-img">
        <div class="g-f">
          <a href="https://www.telegram.org/">
            <img src="../assets/f1.png" alt="" class="img" />
          </a>
        </div>
        <div class="g-f">
          <a href="https://www.instagram.com/">
            <img src="../assets/f2.png" alt="" class="img" />
          </a>
        </div>
        <div class="g-f">
          <a href="https://www.facebook.com">
            <img src="../assets/f3.png" alt="" class="img" />
          </a>
        </div>
        <div class="g-f">
          <a href="https://www.youtube.com/">
            <img src="../assets/f4.png" alt="" class="img" />
          </a>
        </div>
        <div class="g-f">
          <a href="https://twitter.com/">
            <img src="../assets/f5.png" alt="" class="img" />
          </a>
        </div>
        <div class="g-f">
          <a href="https://www.whatsapp.com/">
            <img src="../assets/whatsapp.png" alt="" class="img" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Uploadpicturesapi, upshare,getTeamIndex } from "@/api/index";
import { Toast } from "vant";
export default {
  name: "ShAre",
  data() {
    return {
      lj: "https://4g.net?invitecode=315900",
      shareUrl:'',
      headimg: "",
      fileList: [],
      level:'',
      groupList: [
        "../assets/f1.png",
        "../assets/f2.png",
        "../assets/f3.png",
        "../assets/f4.png",
        "../assets/f5.png",
      ],
      updata: {
        id: "",
        image: "",
      },
    };
  },
  created() {
    this.updata.id = this.$route.query.id;
    this.toHome()
  },
  methods: {
     async toHome(){
       // const res = await getpartyapi()
       const res = await getTeamIndex()
       console.log(res)
       this.level = res.data.level
       this.shareUrl = res.data.share_url
     },
    // 文件删除
    afetdelete(detail) {
      this.fileList = [];
      this.url = "";
      // console.log('3232', this.fileList)
    },
    // 图片事件
    async afterRead(file) {
      // console.log('66666', file)
      // 文件读取完成后的回调函数
      const formData = new FormData();
      formData.append("file", file.file);
      // console.log(formData.get('file'))
      this.files = file.file;
      const res = await Uploadpicturesapi(formData);
      // console.log(res)
      this.url = res.data.fullurl;
      this.updata.image = res.data.url;
    },
    async btn() {
      console.log(this.updata);
      if (this.updata.image == "") {
        Toast({
          message: this.$t("addtask.sharets"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      const res = await upshare(this.updata);
      if (res.code == 1) {
        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        this.$router.push("/Activity");
      } else {
        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
      }
    },

    onCopy() {
      // this.$message.success("复制成功");
      Toast({
        message: this.$t("party.Success"),
        duration: 1200,
        className: "noticeWidth", // 所加样式名
      });
    },
  },
};
</script>
<style lang="less" scoped>
.Share {
  // background: #000;
  min-height: 100vh;
  .Phonenumber-title {
    padding-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      // color: #fff;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .titbg {
    margin-top: 1rem;
    padding: 0;
    .tt-item {
      background-image: url("../assets/titbg.png");
      background-size: cover;
      background-repeat: no-repeat;
      padding: 2rem;
      border-radius: 2rem;
      .t-f {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .zong {
          font-size: 1.5rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          .vBg {
            width: 5rem;
            height: 1.3rem;
            line-height: 1.3rem;
            background-image: url("../assets/dj.png");
            background-size: 100%;
            text-align: center;
            padding-left: 0.7rem;
            color: #fff;
            font-size: 0.8rem;
          }
        }
      }
      .cont {
        padding: 0.5rem;
        .fx {
          // color: #ffffff;
          margin-bottom: 0.8rem;
        }
        .ljz {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #ac5d3b;
          border-radius: 0.5rem;
          margin-bottom: 1rem;
          padding: 0 0.5rem 0 0.8rem;
          height: 4rem;
          .lj {
            color: #ffffff;
          }
          .copy {
            color: #4e200c;
            background: #f6fe19;
            width: 5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            border-radius: 0.5rem;
          }
        }
        .yq {
          color: #ffffff;
        }
      }
      .s-f {
        margin-top: 1rem;
        font-size: 1.1rem;
        .s-i {
          display: flex;
          align-items: center;
          color: #6d6d6d;
          .s-l {
            width: 50%;
            text-align: left;
            height: 4rem;
            line-height: 4rem;
          }
          .s-r {
            width: 50%;
            text-align: left;
            height: 4rem;
            line-height: 4rem;
          }
        }
      }
    }
  }
  .group {
    margin-top: 1rem;
    padding: 0.8rem;
    .g-t {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 0.8rem;
    }
    .g-img {
      display: flex;
      .g-f {
        .img {
          width: 3.5rem;
          height: 3.5rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .body {
    color: #fff;
    margin: 0 4rem;
    .bodytitle {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .bodyimg {
      width: 15rem;
      height: 15rem;
      // background: #ccc;
      margin: 0 auto;
      margin-top: 3rem;
      .imgdiv {
        width: 100%;
        height: 100%;
        border: 5px dashed #ccc;
        font-size: 8rem;
        text-align: center;
      }
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .tishi {
      color: red;
      font-weight: 700;
      margin-top: 1.5rem;
    }
    .btn {
      background: #5979fb;
      color: #fff;
      font-weight: 700;
      text-align: center;
      padding: 1rem;
      border-radius: 3rem;
      margin-top: 4rem;
    }
  }
}
</style>
