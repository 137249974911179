<template>
  <div class="periodRecord">
    <div class="header">
      <div class="title">
        <van-icon
          name="arrow-left"
          size="2rem"
          color="#000"
          @click="$router.go(-1)"
        />
      </div>
      <van-tabs v-model="active" style="flex: 1">
        <van-tab :title='$t("racecar.txt11")'></van-tab>
        <van-tab :title='$t("racecar.txt12")'></van-tab>
      </van-tabs>
    </div>
    <div class="main">
      <div v-if="active == 0">
        <van-dropdown-menu>
          <van-dropdown-item
            @change="changeDropDown"
            v-model="category"
            :options="option1"
          />
        </van-dropdown-menu>
      </div>
      <div v-if="active == 0">
        <van-list
        style="margin-top: 1rem;"
          v-model="loading"
          :finished="finished"
          :finished-text='$t("racecar.txt13")'
          :loading-text='$t("racecar.txt14")'
          @load="getData"
        >
        <div class="history-item" v-for="item in PeriodHistoryList">
              <div class="period">
                {{$t("racecar.txt7")}}N.º {{ item.period }}
              </div>
              <div class="num-box">
                <div
                  class="num-item"
                  :style="{ background: getNumColor(item.lottery) }"
                >
                  {{ item.lottery }}
                </div>
              </div>
              <div class="end">
                <div class="price">{{ item.profit }}</div>
                <div class="date">{{ item.createtime }}</div>
              </div>
            </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getPeriodHistory } from "@/api";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      active: 0,
      category: 1,
      option1: [
        { text: this.$t("racecar.txt3"), value: 1 },
        { text: this.$t("racecar.txt4"), value: 2 },
      ],

      PeriodHistoryList: [],
      page: 1,
      colorArr: [
        {
          text: "1",
          color: "#FDFF54",
        },
        {
          text: "2",
          color: "#4189F7",
        },
        {
          text: "3",
          color: "#4D4D4D",
        },
        {
          text: "4",
          color: "#EB7B31",
        },
        {
          text: "5",
          color: "#A7FAFE",
        },
        {
          text: "6",
          color: "#4B1DF9",
        },
        {
          text: "7",
          color: "#B8B8B8",
        },
        {
          text: "8",
          color: "#EC321B",
        },
        {
          text: "9",
          color: "#6B0F12",
        },
        {
          text: "10",
          color: "#61BF33",
        },
      ],
    };
  },

  methods: {
    getNumColor(item) {
      if (item == 0) return;
      return this.colorArr[item - 1].color;
    },
    async getData(category = 1) {
      this.loading = true;
      const res = await getPeriodHistory({
        page: this.page,
        limit: 20,
        category: category,
      });
      this.PeriodHistoryList = [...this.PeriodHistoryList, ...res.data.data]
      this.loading = false
      
      
      if (this.page < res.data.last_page) {
        this.page++;
      } else {
        this.finished = true
      }
    },

    changeDropDown() {
      this.page = 1
      this.PeriodHistoryList = []
      this.finished = false
      this.getData(this.category);
    },
  },

  created() {
    // this.getData();
  }
};
</script>

<style lang="less" scoped>
.periodRecord {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    height: 9rem;
    background: linear-gradient(180deg, #fdb77a 0%, #f7deb4 100%);
  }
}


  .history-item {
    height: 7rem;
    border-bottom: 1px solid #000;
  }

  
  .history-item {
    display: flex;
    align-items: center;
    height: 5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 0 1.4rem;

    .period {
        text-align: center;
        font-size: 1.6rem;
        color: #333;
      }

      .num-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 1.4rem;

        .num-item {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          font-size: 1.6rem;
          color: #fff;
          border-radius: 4px;
        }

        .num-item:last-child {
          margin-right: unset;
        }
      }

      .end {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;

        .price {
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          color: #fe7128;
          font-weight: bold;
        }

        .date {
          color: #333;
        }
      }
  }

  .history-item:last-child {
    border-bottom: unset;
    margin-bottom: unset;
  }

::v-deep .van-tabs__nav {
  background-color: unset;
}

::v-deep .van-tabs__line {
  background-color: #000;
}
</style>
