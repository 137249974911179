import request from "@/utils/require";

// 注册
export function register(data) {
  return request({
    url: "/api/user/register",
    method: "post",
    data,
  });
}
// 登录
export function getlogin(data) {
  return request({
    url: "/api/user/login",
    method: "post",
    data,
  });
}
// 忘记密码
export function forgotpasswordapi(data) {
  return request({
    url: "/api/user/resetpwd",
    method: "post",
    data,
  });
}
// 签到按钮
export function getreceive() {
  return request({
    url: "/api/index/signin",
    method: "post",
  });
}
// 签到天数页面
export function getSignin() {
  return request({
    url: "/api/index/sign",
    method: "post",
  });
}
// 签到日志
export function getCheckinlog() {
  return request({
    url: "/api/index/signRecord",
    method: "post",
  });
}
// 首页详情数据
export function gethomeapi() {
  return request({
    url: "/api/index/index",
    method: "post",
  });
}
// 首页详情数据
export function gethomeListapi() {
  return request({
    url: "/api/Mining/products",
    method: "post",
  });
}
// 公告数据
export function Noticelistapi(data) {
  return request({
    url: "/api/index/notice",
    method: "post",
    data,
  });
}
// 团队页面
export function getpartyapi(data) {
  return request({
    url: "/api/team/my_team",
    method: "post",
    data,
  });
}
// 帮助详情
export function assistdetailsapi(data) {
  return request({
    url: "/api/index/helpList",
    method: "post",
    data,
  });
}
// 购买矿机
export function Miningmachineapi() {
  return request({
    url: "/api/mining/products",
    method: "post",
  });
}
// 产品购买
export function Productpurchaseapi(data) {
  return request({
    url: "/api/mining/buy",
    method: "post",
    data,
  });
}
// 开启设备
export function startdeviceapi() {
  return request({
    url: "/api/mining/startMiner",
    method: "post",
  });
}
// 确认答题
export function confirmdeviceapi() {
  return request({
    url: "/api/mining/confirm_answer",
    method: "post",
  });
}

// 开始倒计时
export function startCountdownapi() {
  return request({
    url: "/api/mining/runningStatus",
    method: "post",
  });
}
// 团队详情
export function teamdetailsapi(params) {
  return request({
    url: "/api/team/levelDetail",
    method: "post",
    params,
  });
}
// usdt充值
export function rechargeapi(data) {
  return request({
    url: "/api/recharge/do_recharge",
    method: "post",
    data,
  });
}
// 银行卡页面的所有充值通道
export function bankapi(data, url) {
  return request({
    url,
    method: "post",
    data,
  });
}
// // 投注猫充值
export function openapi (data) {
  return request({
    url: '/api/active/is_open',
    method: 'post',
    data
  })
}
// 充值记录
export function RechargeRecordapi(data) {
  return request({
    url: "/api/recharge/history",
    method: "post",
    data,
  });
}
// 提现页面
export function withdrawalapi() {
  return request({
    url: "/api/withdraw/index",
    method: "post",
  });
}
// 提现接口
export function withdrawalinfoapi(data) {
  return request({
    url: "/api/withdraw/do_withdraw",
    method: "post",
    data,
  });
}
// 提现记录
export function Withdrawalsrecordapi(data) {
  return request({
    url: "/api/withdraw/history",
    method: "post",
    data,
  });
}
// 修改会员
export function modifyvipapi() {
  return request({
    url: "/api/user/updateMemberData",
    method: "post",
  });
}
// 新增提现地址
export function addressapi(data) {
  return request({
    url: "/api/user/updateBindWalletAddress",
    method: "post",
    data,
  });
}
// 购买记录
export function Purchaserecordapi(data) {
  return request({
    url: "/api/mining/myMiner",
    method: "post",
    data: {
      status: data
    }
  });
}
// 我的页面
export function myuserapi() {
  return request({
    url: "/api/user/getdata",
    method: "post",
  });
}
// 修改信息
export function Modifyinformationapi(data) {
  return request({
    url: "/api/user/updateMemberData",
    method: "post",
    data,
  });
}
// 修改密码
export function Changepasswordapi(data) {
  return request({
    url: "/api/user/changepwd",
    method: "post",
    data,
  });
}
// 修改电话
export function Changenumberapi(data) {
  return request({
    url: "/api/user/changemobile",
    method: "post",
    data,
  });
}
// 获取验证码
export function obtaincodeapi(data) {
  return request({
    url: "/api/Sms/send",
    method: "post",
    data,
  });
}
// k线图
export function Candlestickchartapi(data) {
  return request({
    url: "/api/huobi/get_trade",
    method: "post",
    data,
  });
}
// 获取图片地址
export function Uploadpicturesapi(data) {
  return request({
    url: "/api/common/upload",
    method: "post",
    data,
  });
}
// 上传图片接口
export function Uploadimgapi(data) {
  return request({
    url: "/api/recharge/upload_image",
    method: "post",
    data,
  });
}
// 上传头像
export function headimgapi(data) {
  return request({
    url: "/api/user/upAvatar",
    method: "post",
    data,
  });
}
// 领取红包
export function Receiveredpacketsapi(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 所有信息
export function allrecordsapi(data) {
  return request({
    url: "/api/user/funds_record",
    method: "post",
    data,
  });
}
// 视频介绍
export function Videointroductionapi() {
  return request({
    url: "/api/index/viewList",
    method: "post",
  });
}
// 公司介绍
export function CompanyProfileapi() {
  return request({
    url: "/api/index/aboutGs",
    method: "post",
  });
}
// 注册接口
export function registerapi(data) {
  return request({
    url: "/api/user/register",
    method: "post",
    data,
  });
}
// 团队页面领取工资
export function Receivesalaryapi() {
  return request({
    url: "/api/team/receive",
    method: "post",
  });
}
// 活动详情
export function activitylistapi() {
  return request({
    url: "/api/active/get_lists",
    method: "post",
  });
}
// 热门矿机
export function Hotminingapi() {
  return request({
    url: "/api/active/hot_products",
    method: "post",
  });
}
// 折扣矿机
export function discountedproductsapi() {
  return request({
    url: "/api/active/discount_products",
    method: "post",
  });
}
// 大转盘抽奖
// export function bigturntableapi () {
//   return request({
//     url: '/api/active/discount_products',
//     method: 'post'
//   })
// }
// 余额宝
export function balancepreciousapi() {
  return request({
    url: "/api/yuebao/products",
    method: "post",
  });
}
// 余额宝购买
export function PurchaseonYuebaoapi(data) {
  return request({
    url: "/api/yuebao/buy",
    method: "post",
    data,
  });
}
// 余额宝购买记录
export function yebpurchaserecordapi(data) {
  return request({
    url: "api/yuebao/myProducts",
    method: "post",
    data,
  });
}
// 设备点击量
export function DeviceHitsapi(data) {
  return request({
    url: "api/mining/click",
    method: "post",
    data,
  });
}
// 新增银行卡
export function postbankapi(data) {
  return request({
    url: "api/user/updateBindWalletBank",
    method: "post",
    data,
  });
}
// 退出登录
export function loginoutapi(data) {
  return request({
    url: "/api/user/logout",
    method: "post",
    data,
  });
}
// 绑定应用程序
export function bangdingapi(data) {
  return request({
    url: "/api/user/updateMemberData",
    method: "post",
    data,
  });
}
// 充值通道
export function Rechargechannelapi() {
  return request({
    url: "/api/Recharge/index",
    method: "post",
  });
}
// 大转盘数据
export function turntablelistapi() {
  return request({
    url: "/api/Turntable/data",
    method: "post",
  });
}
// 转盘开始游戏
export function Turntablestartapi(data) {
  return request({
    url: "/api/Turntable/run",
    method: "post",
    data,
  });
}
// 转盘记录
export function turntablerecordapi(data) {
  return request({
    url: "/api/Turntable/history",
    method: "post",
    data,
  });
}
// 发送邮箱验证码
export function scode(data) {
  return request({
    url: "/api/ems/send",
    method: "post",
    data,
  });
}
// 绑定邮箱
export function emailbind(data) {
  return request({
    url: "/api/user/emailbind",
    method: "post",
    data,
  });
}
// 获取今日利润
export function getMyBonus() {
  return request({
    url: "/api/team/getMyBonus",
    method: "post",
  });
}
// 审核充值信息上传图片
export function uploadimg(data) {
  return request({
    url: "/api/common/upload",
    method: "post",
    data,
  });
}
// 请求获取信息充值
export function getoffline(url) {
  return request({
    url,
    method: "get",
  });
}
// 发送充值
export function postoffline(data) {
  return request({
    url: "/api/recharge/offline",
    method: "post",
    data,
  });
}
// 获取绑定电报url
export function getservice(url) {
  return request({
    url,
    method: "get",
  });
}
// 团队收取佣金
export function dailyTeamIncome(url) {
  return request({
    url,
    method: "get",
  });
}
// 获取充值通道外链
export function geturl(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 获取当前期数接口
export function get_period(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 参数--K线
export function prop(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 下注
export function bet(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 下注记录
export function bet_records(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
//往期中奖历史记录
export function period_histroy(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// k线图--title
export function get_huobi(url) {
  return request({
    url,
    method: "get",
  });
}
//k线图
export function get_game_trade(url, data) {
  return request({
    url,
    method: "post",
    data,
  });
}
// 预测机器人列表
export function robotList() {
  return request({
    url: "/api/robot/robotList",
    method: "post",
  });
}
// 预测信息
export function robotInfo(data) {
  return request({
    url: "/api/robot/robotInfo",
    method: "post",
    data,
  });
}
// 预测下注
export function betpre(data) {
  return request({
    url: "/api/btc/betpre",
    method: "post",
    data,
  });
}
// 活动列表 api/task/list
export function hdlist(data) {
  return request({
    url: "/api/task/list",
    method: "post",
    data,
  });
}
// 活动奖励领取
export function hdreceive(data) {
  return request({
    url: "/api/task/receive",
    method: "post",
    data,
  });
}
// 活动奖励领取
export function upshare(data) {
  return request({
    url: "/api/task/share",
    method: "post",
    data,
  });
}
// 用户在线时长
export function onlinetime(data) {
  return request({
    url: "/api/task/onlinetime",
    method: "post",
    data,
  });
}

// 充值推送
export function recharge_ishow_tips(data) {
  return request({
    url: "/api/index/recharge_ishow_tips",
    method: "post",
    data,
  });
}
export function recharge_tips() {
  return request({
    url: "/api/index/recharge_tips",
    method: "post",
  });
}

// 用户信息
export function yhxx() {
  return request({
    url: "/api/msgboard/index",
    method: "post",
  });
}

// 评论
export function add_msg(data) {
  return request({
    url: "/api/msgboard/add_msg",
    method: "post",
    data,
  });
}

// 红包发送
export function send_red(data) {
  return request({
    url: "/api/active/send_red ",
    method: "post",
    data,
  });
}

// 红包记录
export function send_red_record(data) {
  return request({
    url: "/api/active/send_red_record",
    method: "post",
    data,
  });
}

// 机器日志记录
export function runningLog(data) {
  return request({
    url: "/api/mining/runningLog",
    method: "post",
    data,
  });
}

// 助力列表
export function shiveindex(data) {
  return request({
    url: "/api/shive/index",
    method: "post",
    data,
  });
}

// 助力接口
export function do_support(data) {
  return request({
    url: "/api/shive/do_support",
    method: "post",
    data,
  });
}

// 机器列表
export function robot_list(data) {
  return request({
    url: "/api/shive/robot_list",
    method: "post",
    data,
  });
}

// 我的助力机器列表
export function my_shive_list(data) {
  return request({
    url: "/api/shive/my_shive_list",
    method: "post",
    data,
  });
}

// 请求助力接口
export function qqshare(data) {
  return request({
    url: "/api/shive/share",
    method: "post",
    data,
  });
}
// 累计
export function leijibet(data) {
  return request({
    url: "/api/Btc/bet_records",
    method: "post",
    data,
  });
}

// 累计
export function getquhao(data) {
  return request({
    url: "/api/common/code",
    method: "post",
    data,
  });
}

// 语言
export function getlanguage(data) {
  return request({
    url: "/api/common/get_language",
    method: "post",
    data,
  });
}

// 基金列表
export function getproducts(data) {
  return request({
    url: "/api/yuebao/products",
    method: "post",
    data,
  });
}

// 基金购买
export function getyuebaobuy(data) {
  return request({
    url: "/api/yuebao/buy",
    method: "post",
    data,
  });
}

// 基金购买历史
export function getmyproducts(data) {
  return request({
    url: "/api/yuebao/myproducts",
    method: "post",
    data,
  });
}

// 基金购买历史
export function getback_money(data) {
  return request({
    url: "/api/yuebao/back_money",
    method: "post",
    data,
  });
}

export function getPeriod(category) {
  return request({
    method: 'post',
    url: "/api/btc/get_period",
    data: {
      category
    }
  });
}

export function postBtcBet(data) {
  return request({
    url: "/api/btc/bet",
    method: "post",
    data,
  });
}

export function getPeriodHistory(data) {
  return request({
    method: "post",
    url: "/api/btc/bet_records",
    data,
  });
}

export function getNotice(){
  return request({
    url: '/api/index/notice'
  })
}

export function getActiveList() {
  return request({
    url: '/api/Active/get_lists'
  })
}

export function getPeriodChart(data) {
  return request({
    url: '/api/btc/period_histroy',
    method: 'post',
    data
  })
}

export function getRunningLog() {
  return request({
    url: '/api/Mining/runningLog',
    method: 'post'
  })
}

export function getIndexService() {
  return request({
    url: '/api/index/service',
    method: 'post'
  })
}

export function getTeamIndex(){
  return request({
    url: '/api/team/index',
    method: 'post'
  })
}

export function getBankList() {
  return request({
    url: '/api/Withdraw/bankList',
    method: 'post'
  })
}
