<template>
  <div class="chart">
    <div class="header">
      <div class="title">
        <van-icon
          name="arrow-left"
          size="2rem"
          color="#000"
          @click="$router.go(-1)"
        />
        <van-tabs @change="changeTab" v-model="typeActive" style="flex: 1">
          <van-tab name="1" :title="$t('racecar.txt3')"></van-tab>
          <van-tab name="2" :title="$t('racecar.txt4')"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="main">
      <div class="chart">
        <div class="th-container">
          <div class="th-title">Número de emisión</div>
          <div class="th" v-for="(item, index) in rankList">{{ item }}<br />{{ index + 1 }}</div>
        </div>
        <div class="tr-container">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('racecar.txt13')"
            :loading-text="$t('racecar.txt14')"
            @load="getData"
          >
            <div class="tr-p" v-for="item in chartList">
              <div class="tr-title">{{ item.period }}</div>
              <div class="tr" v-for="i in item.lottery">
                <div :style="{ background: getNumColor(i) }" class="bgtr">
                  {{ i }}
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPeriodChart } from "../api/index";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      rankList: [
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
        "No.",
      ],
      typeActive: 1,
      chartList: [],
      colorArr: [
        {
          text: "1",
          color: "#FDFF54",
        },
        {
          text: "2",
          color: "#4189F7",
        },
        {
          text: "3",
          color: "#4D4D4D",
        },
        {
          text: "4",
          color: "#EB7B31",
        },
        {
          text: "5",
          color: "#A7FAFE",
        },
        {
          text: "6",
          color: "#4B1DF9",
        },
        {
          text: "7",
          color: "#B8B8B8",
        },
        {
          text: "8",
          color: "#EC321B",
        },
        {
          text: "9",
          color: "#6B0F12",
        },
        {
          text: "10",
          color: "#61BF33",
        },
      ],
      page: 1,
    };
  },

  created() {},

  methods: {
    async getData() {
      this.loading = true;

      const res = await getPeriodChart({
        limit: 50,
        page: this.page,
        category: this.typeActive,
      });
      this.loading = false;
      this.chartList = [...this.chartList, ...res.data.data];

      if (this.page < res.data.last_page) {
        this.page++;
        this.loading = false;
      } else {
        this.finished = true;
      }
    },

    getNumColor(item) {
      if (item == 0) return;
      return this.colorArr[item - 1].color;
    },

    changeTab(name) {
      this.page = 1
      this.chartList = []
      this.getData()
    }
  },
};
</script>
<style lang="less" scoped>
.chart {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-bottom: 1.5rem;

    .title {
      display: flex;
      align-items: center;
      margin-top: 2.5rem;
      margin: 2.5rem 1rem 0;
    }
  }

  .main {
    .chart {
      display: flex;

      .tr-container,
      .th-container {
        display: flex;
        .th {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5.5rem;
          padding: 0 0.5rem;
          text-align: center;
          white-space: wrap;
          border-right: 1px solid #CFCFCF;
          border-top: 1px solid #CFCFCF;
        }

        .th-title {
          height: 5.5rem;
          width: 8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #CFCFCF;
          border-top: 1px solid #CFCFCF;
          text-align: center;
        }
      }

      .tr-container {
        display: flex;
        flex-direction: column;

        .tr-p {
          border-top: 1px solid #CFCFCF;


          display: flex;
          height: 3rem;
          .tr-title {
            width: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #CFCFCF;
          }

          .tr {
            // border: 0.3px solid #000;
            padding: 0.3rem;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .bgtr {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              color: #000;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
