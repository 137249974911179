<template>
  <!-- 新增地址 -->
  <div class="Addressdetails">
    <div class="Addressdetails-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{ title }}</div>
      <div></div>
    </div>
    <div v-if="parseFloat(id) === 1">
      <div class="tips" style="margin-bottom: 3rem;">
        <img class="tips-icon" src="../assets/tips_icon.png" />
        <div class="tips-desc">{{$t('address.usdter')}}</div>
      </div>
      <div class="title">{{ $t("Addressdetails.Address") }}</div>
      <div class="money">
        <input
          type="text"
          v-model="addres"
          :placeholder="$t('address.usdtyi')"
        />
      </div>
      <!-- <div style="color: #fa7d1f; padding-top: 0.5rem; font-size: 1.2rem">
        *{{$t('address.usdter')}}
      </div> -->
      <div class="title" style="margin-top: 1rem">{{$t('address.usdtsan')}}</div>
      <div class="money">
        <input
          type="text"
          v-model="bankMail"
          @blur="bluemail"
          :placeholder="$t('address.usdtsan')"
          :disabled="true"
        />
      </div>
      <div class="title" style="margin-top: 1rem">{{$t('address.usdtsi')}}</div>
      <div class="money">
        <van-field v-model="MailCode" >
            <template #button>
                <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
            </template>
        </van-field>
      </div>
      <!-- <div class="money">
        <van-field v-model="MailCode" >
            <template #button>
                <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
            </template>
        </van-field>
      </div> -->
        <!-- <div class="title" style="margin-top: 1rem">Email verification code</div>
        <div class="money">
          <van-field v-model="MailCode" >
              <template #button>
                  <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
              </template>
          </van-field>
        </div> -->
      <div class="bottom" @click="address(1)">
        {{ $t("Addressdetails.Determine") }}
      </div>
    </div>
    <div v-if="parseFloat(id) === 0">
      <!-- <div>
        <div class="title" style="margin-top: 1rem">Tipo de conta PIX</div>
        <div class="pixtype">
          <div>{{typepix}}</div>
            <div>
              <van-icon name="arrow-down"  @click="changeshow" />
              <van-popover
              v-model="showPopover"
              trigger="click"
              @select="onSelect"
              :actions="actions"
              placement="bottom-end"
              :offset="[20, 10]">
                <div class="items" style="width: 13rem; height: 4rem; line-height: 4rem; text-align: center;font-size: 1.4rem; border-bottom:1px solid rgba(0, 0, 0, 0.1) ; margin: 0rem 1rem;"
                  v-for="(i,v) in actions" :key="v" @click="popoveritem(i.text)">{{ i.text }}</div>
            </van-popover>
            </div>
         </div>
        </div> -->
     <div v-if="typepix==='CPF'">
      <div class="tips">
        <img class="tips-icon" src="../assets/tips_icon.png" />
        <div class="tips-desc">{{$t('address.addbankp')}}</div>
      </div>
      <div class="title" style="margin-top: 1rem">{{$t('address.addbankyi')}}</div> <!--  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"-->
        <div class="money">
          <input
            type="number"
            v-model="bankNumber"
            :placeholder="$t('address.addbanker')"
            oninput="if(value.length>18)value=value.slice(0,18)"
            onKeypress="return (/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
          />
        </div>
        <div class="title" style="margin-top: 1rem">{{$t('address.addbanksan')}}</div> <!-- oninput="value=value.replace(/[^0-9.-]/g,'')"-->
        <div class="money" @click="showPopup()">
		  {{bankname}}
        </div>

		<!-- <div class="money" @click="showPopup()">  原来的
		 <input

		    type="text"
		    v-model="bankname"
		    :placeholder="$t('address.addbanksi')"
			disabled
		    maxlength="32"
		    oninput="this.value=this.value.replace(/[^a-zA-Z]/g,' ')"
		    style="white-space: pre;"
		  />
		</div> -->
        <div class="title" style="margin-top: 1rem">{{$t('address.addbankwu')}}</div>
        <div class="money">
          <input
            type="text"
            v-model="username"
            :placeholder="$t('address.addbankliu')"
            oninput="this.value=this.value.replace(/[^a-zA-Z]/g,' ')"
            style="white-space: pre;color: #000000;"
          />
        </div>
        <!-- <div class="title" style="margin-top: 1rem">
          Numero De Celular De Contato
        </div>
        <div class="money" style="position: relative">
          <input
            style="padding-left: 3.5rem"
            type="text"
            v-model="bankNumber"
            placeholder="Digite o número do celular"
            maxlength="11"
          />
          <div
            style="
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding: 0rem 1rem;
              border-right: 1px solid rgba(255, 255, 255, 0.5);
            "
          >
            +55
          </div>
        </div> -->
        <div class="title" style="margin-top: 1rem">{{$t('address.addbankqi')}}</div>
        <div class="money">
          <input
            type="text"
            v-model="bankMail"
            @blur="bluemail"
            :placeholder="$t('address.addbankba')"
          />
        </div>
        <div class="title" style="margin-top: 1rem">{{$t('address.addbankjiu')}}</div>
        <div class="money">
          <van-field v-model="MailCode" >
              <template #button>
                  <button class="codebutton" @click="sendcode" :disabled="codestatus" type="button">{{codecontent}}</button>
              </template>
          </van-field>
        </div>
     </div>
     <div v-if="typepix==='PHONE'">
      <div class="title" style="margin-top: 1rem">Número da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bank"
            placeholder="Insira o número da conta PIX"
            oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
            maxlength="11"
          />
        </div>
        <div class="title" style="margin-top: 1rem">Nome da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bankname"
            placeholder="Digite o nome da conta PIX"
            oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'')"
          />
        </div>
        <div class="title" style="margin-top: 1rem">
          Numero De Celular De Contato
        </div>
        <div class="money" style="position: relative">
          <input
            style="padding-left: 3.5rem"
            type="text"
            v-model="bankNumber"
            placeholder="Digite o número do celular"
            maxlength="11"
          />
          <div
            style="
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding: 0rem 1rem;
              border-right: 1px solid rgba(255, 255, 255, 0.5);">
            +55
          </div>
        </div>
        <div class="title" style="margin-top: 1rem">Email de contato</div>
        <div class="money">
          <input
            type="text"
            v-model="bankMail"
            @blur="bluemail"
            placeholder="email de contato"
          />
        </div>
        <div class="title" style="margin-top: 1rem">CPF</div>
        <div class="money">
          <input
            type="text"
            v-model="cpf"
            placeholder="Insira o código tributário do beneficiário"
            oninput="value=value.replace(/[^0-9.-]/g,'')"
            maxlength="14"
          />
        </div>
     </div>
     <div v-if="typepix==='EMAIL'">
      <div class="title" style="margin-top: 1rem">Número da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bank"
            placeholder="Insira o número da conta PIX"
          />
        </div>
        <div class="title" style="margin-top: 1rem">Nome da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bankname"
            placeholder="Digite o nome da conta PIX"
            oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'')"
          />
        </div>
        <div class="title" style="margin-top: 1rem">
          Numero De Celular De Contato
        </div>
        <div class="money" style="position: relative">
          <input
            style="padding-left: 3.5rem"
            type="text"
            v-model="bankNumber"
            placeholder="Digite o número do celular"
            maxlength="11"
          />
          <div
            style="
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding: 0rem 1rem;
              border-right: 1px solid rgba(255, 255, 255, 0.5);
            "
          >
            +55
          </div>
        </div>
        <div class="title" style="margin-top: 1rem">Email de contato</div>
        <div class="money">
          <input
            type="text"
            v-model="bankMail"
            @blur="bluemail"
            placeholder="email de contato"
            maxlength="11"
          />
        </div>
        <div class="title" style="margin-top: 1rem">CPF</div>
        <div class="money">
          <input
            type="text"
            v-model="cpf"
            placeholder="Insira o código tributário do beneficiário"
            oninput="value=value.replace(/[^0-9.-]/g,'')"
            maxlength="14"
          />
        </div>
     </div>
     <div v-if="typepix==='CHAVE'">


      <div class="title" style="margin-top: 1rem">Número da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bank"
            placeholder="Insira o número da conta PIX"
          />
        </div>
        <div class="title" style="margin-top: 1rem">Nome da conta PIX</div>
        <div class="money">
          <input
            type="text"
            v-model="bankname"
            placeholder="Digite o nome da conta PIX"
            oninput="this.value=this.value.replace(/[^a-zA-Z]/g,'')"
          />
        </div>
        <div class="title" style="margin-top: 1rem">
          Numero De Celular De Contato
        </div>
        <div class="money" style="position: relative">
          <input
            style="padding-left: 3.5rem"
            type="text"
            v-model="bankNumber"
            placeholder="Digite o número do celular"
               maxlength="11"
          />
          <div
            style="
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding: 0rem 1rem;
              border-right: 1px solid rgba(255, 255, 255, 0.5);
            "
          >
            +55
          </div>
        </div>
        <div class="title" style="margin-top: 1rem">Email de contato</div>
        <div class="money">
          <input
            type="text"
            v-model="bankMail"
            @blur="bluemail"
            placeholder="email de contato"
          />
        </div>
        <div class="title" style="margin-top: 1rem">CPF</div>
        <div class="money">
          <input
            type="text"
            v-model="cpf"
            placeholder="Insira o código tributário do beneficiário"
            oninput="value=value.replace(/[^0-9.-]/g,'')"
            maxlength="14"
          />
        </div>
     </div>
        <!-- <p style="color: #fa7d1f; padding: 3rem 0.5rem 0rem; font-size: 1.3rem">
          *{{$t('address.addbankp')}}
        </p> -->
        <div class="bottom" style="margin-top: 3rem" @click="address(2)">
          {{ $t("Addressdetails.Determine") }}
        </div>
      </div>

	  <van-popup v-model="bankshow" round position="bottom" style="padding: 2rem;text-align: center;font-size: 1.3rem;">
		  <div style="text-align: right;" @click="changevalue()">Confirmar</div>
		  <div style="height: 30rem;overflow-y: scroll;margin: 2rem;">
			  <div v-for="(i,v) in banknamelist" :key="v" style="padding: 1rem;" :class="{reds:bnindex==v}" @click="changebn(v,i.bankcode,i.bankname)">
			  		{{i.bankname}}
			  </div>
		  </div>
	  </van-popup>

    </div>
  <!-- </div> -->
</template>

<script>
import { addressapi, postbankapi, withdrawalapi, scode, getBankList } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddressDetails',
  data () {
    return {
      addres: '', // 钱包地址
      bankname: '', // 银行姓名
      bank: '', // 银行账号
      bankNumber: '', // 电话号码
      username: '',
      bankMail: '', // 邮箱
      MailCode: '', // 邮箱验证码
      codecontent: this.$t('mail.sendcode'),
      cpf: '', // CPF账号
      id: 0,
      title: 'Portfolio address',
      value: '',
      type: '', // 判断是新增还是修改
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: 'CPF' }, { text: 'PHONE' }, { text: 'EMAIL' }, { text: 'CHAVE' }],
      typepix: 'CPF',
      timer: null,
      codestatus: false,
	   bankshow: false,
	   banknamelist:[],
	   bnindex:0,
	   bcode:'',
	   bname:''
    }
  },
  created () {

    this.id = this.$route.query.id
    console.log(this.$route.query.emali)
    // this.bankMail = this.$route.query.emali
    this.type = this.$route.query.type
    // console.log('234', this.type, this.id)
    if (parseFloat(this.id) === 1) {
      this.title = 'Portfolio address'
    } else {
      this.title = 'Bank account'
    }
     // this.withdrawal()
  },
  methods: {
	  changevalue() {

		  this.bankname=this.bname
		  this.bankshow = false;
	  },
	  changebn(index,code,name) {
		  this.bnindex = index
		  this.bcode=code
		  this.bname = name
	  },
	  async showPopup() {
      const res = await getBankList()
      console.log(res);
      this.banknamelist = res.data
	        this.bankshow = true;
	    },
    sendcode () { // 发送验证码
      const that = this
      if (that.bankMail == '') { // eslint-disable-line
        Toast({
          message: that.$t('mail.emptyemail'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      that.codecontent = 300
      that.codestatus = true
      that.SendCode() // 发送请求
      that.timer = setInterval(() => {
        if (that.codecontent > 0) {
          that.codecontent--
        }
        if (that.codecontent == 0) { // eslint-disable-line
          that.codecontent = that.$t('mail.sendcode')
          that.codestatus = false
        }
      }, 1000)
    },
    async SendCode () { // 发送验证码请求
      const that = this
      const data = {
        email: that.bankMail,
        event: 'userWallet'
      }
      if (parseFloat(that.id) === 1) {
        data.event = 'userWallet'
      } else {
        data.event = 'userBank'
      }
      const res = await scode(data)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      console.log(res)
    },
    popoveritem (item) {
      this.typepix = item
      this.showPopover = false
      this.bankname = ''
      this.bank = ''
      this.bankNumber = ''
      this.bankMail = ''
      this.cpf = ''
    },
    async withdrawal () {
      // if (this.type) {
        const res = await withdrawalapi()
        console.log(res, 'xinxi')
        if(res.data.account_name) {
			this.bankname = res.data.account_name
		}
        if(res.data.bank_account) {
			this.bankNumber = res.data.bank_account
		}
        // this.bankNumber = res.data.mobile
       if(res.data.email) {
		    this.bankMail = res.data.email
	   }
        if(res.data.username) {
			this.username = res.data.username
		}
        if(res.data.wallet_address) {
			this.addres = res.data.wallet_address
		}
		this.banknamelist = res.data.bankArr
		console.log(this.banknamelist);
		if(res.data.cpf) {
			this.bcode=res.data.cpf
			this.banknamelist.forEach((i,v)=>{
				if(i.bankcode==res.data.cpf) {
					this.bnindex = v
				}
			})
		}
     // }

    },
    onSelect (action) {
      // Toast(action.text)
      this.typepix = action.text
    },
    changeshow () {
      this.showPopover = true
    },
    // validateCPF (cpf) {
    //   // console.log(cpf)
    //   // 去除非数字字符
    //   cpf = cpf.replace(/\D/g, '')

    //   // 校验长度是否为11位
    //   if (cpf.length !== 11) {
    //     return false
    //   }

    //   // 校验重复数字
    //   if (/^(\d)\1{10}$/.test(cpf)) {
    //     return false
    //   }

    //   return true
    // },
    IsEmail (str) {
      const reg =
        /^([a-z0-9]+[.]?)*[a-z0-9]+@([a-z0-9]+[.]?)*[a-z0-9]+\.[a-z]{2,3}$/
      return reg.test(str)
    },
    // onblue (value) {
    //   if (this.validateCPF(value.target._value.trim())) {
    //     this.value = true
    //     // console.log(111)
    //   } else {
    //     this.value = false
    //   }
    // },
    bluemail () {
      if (this.IsEmail(this.bankMail.trim())) {
        this.value = true
        // console.log('成功')
      } else {
        this.value = false
        // console.log('失败')
      }
    },
    async address (index) {
      // this.bluemail()
      console.log(index)
      if (index === 1) {
        if (this.addres.trim() === '') {
          Toast({
            message: this.$t('Addressdetails.empty'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        } else {
          const data = {
            address: this.addres,
            email: this.bankMail.trim(),
            code: this.MailCode
          }
          console.log(data)
          const res = await addressapi(data)
          // console.log(res)
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          if (res.code === 1) {
            setTimeout(() => {
              this.$router.push('/my')
            }, 1000)
          }
        }
      } else {
        if (this.bankNumber == '') { // eslint-disable-line
          Toast({
            message: this.$t('address.tsyi'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          return
        }
        if (this.bankname == '') { // eslint-disable-line
          Toast({
            message: this.$t('address.tser'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          return
        }

		if (this.bcode == '') { // eslint-disable-line
		  Toast({
		    message: 'Por favor cambie el nombre del banco antes de enviar',
		    duration: 1200,
		    className: 'noticeWidth' // 所加样式名
		  })
		   return
		  }

        // if (this.bankNumber.trim() === '') {
        //   Toast({
        //     message: 'The phone number cannot be blank',
        //     duration: 1200,
        //     className: 'noticeWidth' // 所加样式名
        //   })
        //   return
        // }
        if (this.bankMail == '') { // eslint-disable-line
          Toast({
            message: this.$t('address.tssan'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          return
        }
        if (this.MailCode == '') { // eslint-disable-line
          Toast({
            message: this.$t('address.tssi'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          return false
        }


        if (this.username == '') { // eslint-disable-line
          Toast({
            message: this.$t('address.tswu'),
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
        const data = {
          account_number: this.bankNumber,
          account_name: this.bankname,
          username: this.username,
          // cpf: this.cpf.trim(),
          email: this.bankMail.trim(),
          // mobile: this.bankNumber.trim(),
          account_type: 2,
          code: this.MailCode,
          bankcode:	this.bcode
        }
        console.log(data, 'qqtj')
        const res = await postbankapi(data)
        console.log(res)
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        if (res.code === 1) {
          setTimeout(() => {
            this.$router.push('/my')
          }, 1000)
        }
        // console.log('3123', this.value)
        // if (this.value) {
        //   // if (this.typepix === 'PHONE') {

        // } else {
        //   Toast({
        //     message: this.$t('address.tsliu'),
        //     className: 'noticeWidth' // 所加样式名
        //   })
        // }
      }
    }
  },
  beforeDestroy (){
    if(this.timer){
      clearInterval(this.timer); //关闭
    }
  },

}
</script>

<style lang="less" scoped>

	.reds {
		background-color: #f6f6f6;
	}

.Addressdetails {
  background-color: #fff;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 2.5rem;
  .Addressdetails-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
      // color: red;
    }
  }
  .title {
    color: #333;
    font-size: 1.7rem;
    font-weight: 600;
  }
  .pixtype {
    height: 5rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 1rem;
    margin-top: 1rem;
    color: #fff;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding: 0rem 2rem;
    // padding-right: 4rem;
    display: flex;
    justify-content: space-between;

  }

  .money {
    height: 5rem;
    border-radius: 1rem;
    margin-top: 1rem;
    color: #333;
    font-size: 1.4rem;
    border: 3px solid #f6f6f6;
    display: flex;
    align-items: center;
    padding: 0rem 2rem;
    input {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border: none;
      // padding: 0rem 2.5rem;
    }
    .all {
      color: #15b4fb;
      margin-left: 0.8rem;
    }
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #FE6719;
    text-align: center;
    line-height: 4.4rem;
    border-radius:1rem;
    font-size: 1.6rem;
    color: #fff;
  }
  input::-webkit-input-placeholder {
    color: #333;
  }
}

.tips {
  background-color: #FF6B1E;
  width: 100%;
  display: flex;
  padding: 1.35rem 1.5rem;
  border-radius: 1.35rem;

  .tips-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .tips-desc {
    margin-left: .5rem;
    color: #FFF16E;
    font-size: 1.3rem;
  }
}
/* 修改*/
::v-deep .van-cell {
  background: #fff;
  padding: 0;
  color: #333;
}
::v-deep .van-field__control{
  color: #333;
}
.codebutton{
  padding: 0 0.4rem;
  // background-color: #15b4fb;
  border: none;
  border-radius: 0.2rem;
  color:#FE6719;
}
</style>
