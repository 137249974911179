<template>
  <!-- 提现页面 -->
  <div>
    <div class="withdrawBg" alt="">
      <img class="bgimg" src="../assets/withdrawBg.png" alt="" />
      <div class="Withdraw-title">
        <van-icon
          style="margin-left: 1.5rem"
          name="arrow-left"
          size="2rem"
          color="#fff"
          @click="$router.back()"
        />
        <div class="title">{{ $t("Withdraw.title") }}</div>
        <!--  <div class="img" @click="toWithdrawalsrecord">
			   <img src="../assets/record.png" alt="" />
			 </div> -->
        <div class="img" @click="toWithdrawalsrecord">
          <img src="../assets/czjl.png" alt="" />
        </div>
      </div>
      <div class="tx-money">
        <div class="Available">{{ $t("Withdraw.Available") }} (USDT)</div>
        <div class="money-num">{{ available }}</div>
      </div>
    </div>
    <div class="Withdraw">
      <div class="swtich">
        <van-radio-group
          icon-size="2rem"
          checked-color="#FE6719"
          v-model="type"
          direction="horizontal"
        >
          <van-radio
            :class="type == 1 ? 'radio-active' : ''"
            class="xz"
            :name=1
            >USDT</van-radio
          >
          <van-radio
            :class="type == 2 ? 'radio-active' : ''"
            class="xz"
            :name=2
            >BANK</van-radio
          >
        </van-radio-group>
      </div>
      <div>
        <div class="Amount">
          <div class="left">{{ $t("Withdraw.Amount") }}</div>
        </div>
        <div class="money">
          <!-- <input type="text" v-model="money" oninput="this.value = this.value.replace(/[^0-9]/g, '');" @input="numValid(money)"/> -->
          <input type="number" v-model="money" @input="numValid(money)"/>
          <div class="all" @click="allmoney">{{ $t("Withdraw.All") }}</div>
        </div>
        <div class="address" v-if="type == 1">
          {{ $t("addwith.address") }}
        </div>
        <div class="address" v-if="type == 2">
          {{ $t("addwith.addresser") }}
        </div>
        <div v-if="type == 2" @click="bankaddress" class="addres">
          {{ Withdrawobj.bank_account }}
        </div>
        <div v-else-if="type == 1" @click="toaddress" class="addres">
          {{ Withdrawobj.wallet_address }}
        </div>
        <!-- <div>
						 <div style="color: #333; margin: 1.5rem 0rem; font-size: 1.6rem">{{$t('addwith.addresssan')}}</div>
						 <div class="type">
							 <div class="box" v-for="(item,index) in Withdrawobj.channel" :key="index"
								 :class="{boxs:type===item.type}" @click="boxtype(item.type)">{{item.name}}</div> -->
        <!-- {{$t('addwith.addresssi')}}-->
        <!-- <div class="box" :class="{boxs:type===1}" @click="boxtype(1)" style="margin-left: 2rem;">USDT</div> -->
        <!-- </div>
					 </div> -->
        <div v-if="type == 2" class="info">
          <div class="info-desc">
            {{ $t("Withdraw.min") }}:
            <span>{{ Withdrawobj.min }} USDT</span>
          </div>
          <div class="info-desc">
            {{ $t("Withdraw.max") }}:
            <span>{{ Withdrawobj.max }} USDT</span>
          </div>
          <!-- <div class="info-desc">
            {{ $t("Withdraw.fee") }}:
            <span>{{ rechargeData[this.type - 1].bank_fee }}</span>
          </div> -->
        </div>
        <div v-if="type == 1" class="info">
          <div class="info-desc">
            {{ $t("Withdraw.min") }}:
            <span>{{ Withdrawobj.min }} USDT</span>
          </div>
          <div class="info-desc">
            {{ $t("Withdraw.max") }}:
            <span>{{ Withdrawobj.max }} USDT</span>
          </div>
          <!-- <div class="info-desc">
            {{ $t("Withdraw.fee") }}:
            <span>{{ rechargeData[this.type - 1].fee }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="btn" @click="withdrawalinfo">
      {{ $t("Withdraw.Submit") }}
    </div>
    <div class="hint">
      <p class="ss">1. {{ $t("Withdraw.Currently") }}</p>
      <p class="ss">2. {{ $t("Withdraw.Bank") }}</p>
      <p class="ss">3. {{ $t("Withdraw.Arrival") }}</p>
      <p class="ss">4. {{ $t("Withdraw.within") }}</p>
    </div>
  </div>
</template>

<script>
import {
  withdrawalapi,
  withdrawalinfoapi,
} from "@/api/index";
import { Toast } from "vant";
import { Dialog, RadioGroup, Radio } from "vant";
export default {
  name: "WithDraw",
  data() {
    return {
      Withdrawobj: {},
      money: 0,
      available: 0,
      type: 1,
      bank: "",
      codes: 1,
      isLoading: false,
      loding: 1, // 防爆
      bankmax: 0,
      rechargeData: "",
      rawValue: '0'
    };
  },
  created() {
    this.withdrawal()
    // this.getData();
  },
  // computed: {
  //     filteredValue: {
  //       get() {
  //         // 获取原始值并返回处理后的值（正整数部分）
  //         let reg = /^\d*$/; // 同上，允许数字但不包括前导零。如果想允许前导零，可以改为 /^[1-9]\d*$/ 或 /^\d*$/g。
  //         return this.rawValue.match(reg) ? this.rawValue : '0'; // 如果不符合正整数，则返回空字符串，否则返回原始值。
  //       },
  //       set(value) {
  //         // 设置原始值，这里不做额外处理，因为已经在get中过滤了。
  //         this.rawValue = value;
  //       }
  //     }
  // },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    numValid(money) {
        console.log(money)
        let value = money.replace(/[^\d]/g, '').replace(/^0+/, ''); // 删除非数字字符和开头的0（除非是单独的0）
        if (value === '') {
          value = ''; // 允许清空输入框
        } else if (value[0] === '0') {
          value = value.slice(1) || '0'; // 防止变成'00'这样的情况
        }
        this.money = value;
        if (this.type === 1) {
            if (money > parseFloat(this.Withdrawobj.max)) {
              this.money = parseFloat(this.Withdrawobj.bank_max)

            }
        } else if (this.type === 2) {
            if (money > parseFloat(this.Withdrawobj.bank_max)) {
              this.money = parseFloat(this.Withdrawobj.bank_max)
            }
        }
       // }
    },
        // handleInput(event) {
        //   // 获取输入值
        //   let value = event.target.value;
        //   // 使用正则表达式过滤非正整数
        //   let reg = /^\d*$/; // 允许输入数字，但不包括前导零（如果想允许前导零，可以改为 /^[1-9]\d*$/）
        //   if (!reg.test(value)) {
        //     // 如果不符合正整数，则不更新值
        //     return;
        //   } else {
        //     // 如果符合，可以进一步处理（例如，更新数据）
        //     this.updateValue(value); // 假设你有一个方法用于更新值
        //   }
        // },
        // updateValue(value) {
        //   // 这里可以更新你的数据模型或进行其他操作
        //   console.log('更新后的值：', value);
        // }
    // 提现类型
    boxtype(index) {
      console.log(index);
      if (index === 1) {
        this.type = 1;
        this.money = 0;
      } else {
        this.type = 2;
        this.money = 0;
      }
    },
    // 提现信息
    async withdrawal() {
      const res = await withdrawalapi();
      console.log("666", res);
      this.Withdrawobj = res.data;
      console.log(res.data.channel[0].type);
      this.type = res.data.channel[0].type;
      // this.bankmax = parseFloat(res.data.max)
      this.available = parseFloat(res.data.available).toFixed(2);
      const div = document.querySelector(".hint");
      if (div) {
        div.innerHTML = res.data.tips;
      }
      this.bank = res.data.bank_account;

    },
    // 提现接口
    async withdrawalinfo() {
      // if (this.bank) {
        // 银行提现最小最大金额
        if (this.type === 2) {
          if (parseFloat(this.money) >= parseFloat(this.Withdrawobj.bank_min)) {
            if (this.loding === 1) {
              this.loding = 0;
              const data = {
                price: parseFloat(this.money).toFixed(2),
                type: this.type,
              };
              const res = await withdrawalinfoapi(data);
              if (res.code === 0 && res.data.block == 1) {
                // Dialog.confirm({
                //   title: "",
                //   message: res.msg,
                //   confirmButtonText: "Confirm",
                //   cancelButtonText: "Cancel",
                // })
                //   .then(() => {
                //     this.$router.push("/Fund");
                //   })
                //   .catch(() => {});
              } else {
                Toast({
                  message: res.msg,
                  duration: 1200,
                  className: "noticeWidth", // 所加样式名
                });
              }
              this.codes = res.code;
              console.log(this.bank,4444)
              this.withdrawal();
              if (!this.bank) {
                setTimeout(() => {
                  this.$router.push("/address");
                }, 1200);
              } else {
                if (res.code === 1) {
                  setTimeout(() => {
                    this.$router.push("/withdrawalsrecord");
                  }, 1500);
                } else if (res.code === 204) {
                  setTimeout(() => {
                    this.$router.push("/recharges");
                  }, 1500);
                } else if (res.code === 203) {
                  setTimeout(() => {
                    this.$router.push("/address");
                  }, 1500);
                } else if (res.code === 205) {
                  setTimeout(() => {
                    this.$router.push("/mining");
                  }, 1500);
                } else if (res.code === 206) {
                  setTimeout(() => {
                    this.$router.push("/install");
                  }, 1500);
                }
              }
              setTimeout(() => {
                this.loding = 1;
              }, 1500);
            }
          } else {
            Toast({
              message: this.$t("addwith.tsyi") + this.Withdrawobj.bank_min,
              duration: 1200,
              className: "noticeWidth", // 所加样式名
            });
          }
        } else {
          // USD提现最小最大金额
          // if (this.Withdrawobj.wallet_address) {
            if (parseFloat(this.money) > parseFloat(this.Withdrawobj.max)) {
              Toast({
                message: this.$t("addwith.tsyi") + this.Withdrawobj.max,
                duration: 1200,
                className: "noticeWidth", // 所加样式名
              });
              return false;
            }
            if (parseFloat(this.money) >= parseFloat(this.Withdrawobj.min)) {
              if (this.loding === 1) {
                this.loding = 0;
                const data = {
                  price: parseFloat(this.money).toFixed(2),
                  type: this.type,
                };
                // console.log(parseFloat(this.money))
                const res = await withdrawalinfoapi(data);
                // console.log(res.msg)

                // if (res.code === 0) {
                //   Dialog.confirm({
                //     title: "",
                //     message: res.msg,
                //     confirmButtonText: "Confirm",
                //     cancelButtonText: "Cancel",
                //   })
                //     .then(() => {
                //       this.$router.push("/Fund");
                //     })
                //     .catch(() => {});
                // } else {
                //   Toast({
                //     message: res.msg,
                //     duration: 1200,
                //     className: "noticeWidth", // 所加样式名
                //   });
                // }
                this.codes = res.code;
                this.withdrawal();
                // if (!this.Withdrawobj.wallet_address) {
                //   setTimeout(() => {
                //     this.$router.push("/address");
                //   }, 1200);
                // }
                // else {
                  if (res.code === 0) {
                    Toast({
                      message: res.msg,
                      duration: 1200,
                      className: "noticeWidth", // 所加样式名
                    });
                    // this.type = 2;
                  }
                  else {
                    if (res.code === 1) {
                      setTimeout(() => {
                        this.$router.push("/withdrawalsrecord");
                      }, 1500);
                    } else if (res.code === 201) {
                      Toast({
                        message: res.msg,
                        duration: 1200,
                        className: "noticeWidth", // 所加样式名
                      });
                      setTimeout(() => {
                         this.$router.push("/address");
                      }, 1500);
                    }else if (res.code === 204) {
                      Toast({
                        message: res.msg,
                        duration: 1200,
                        className: "noticeWidth", // 所加样式名
                      });
                      setTimeout(() => {
                        this.$router.push("/recharges");
                      }, 1500);
                    } else if (res.code === 203) {
                      Toast({
                        message: res.msg,
                        duration: 1200,
                        className: "noticeWidth", // 所加样式名
                      });
                      setTimeout(() => {
                        this.$router.push("/address");
                      }, 1500);
                    } else if (res.code === 205) {
                      setTimeout(() => {
                        this.$router.push("/mining");
                      }, 1500);
                    } else if (res.code === 206) {
                      Toast({
                        message: res.msg,
                        duration: 1200,
                        className: "noticeWidth", // 所加样式名
                      });
                      setTimeout(() => {
                        this.$router.push("/install");
                      }, 1500);
                    }
                  }
                // }
                this.loding = 1;
              }
            } else {
              Toast({
                message: this.$t("addwith.tssan") + this.Withdrawobj.min,
                duration: 1200,
                className: "noticeWidth", // 所加样式名
              });
            }
          // } else {
          //   Toast({
          //     message: this.$t("Withdraw.receiving"),
          //     duration: 1200,
          //     className: "noticeWidth", // 所加样式名
          //   });
          //   setTimeout(() => {
          //     this.$router.push("/address");
          //   }, 1200);
          // }
        }
      // } else {
      //   Toast({
      //     message: this.$t("Withdraw.receiving"),
      //     duration: 1200,
      //     className: "noticeWidth", // 所加样式名
      //   });
      //   setTimeout(() => {
      //     this.$router.push("/address");
      //   }, 1200);
      // }
    },
    // 全部
    allmoney() {
      // if (this.Withdrawobj.available < 10000) {
      //   if (this.type === 1) {
      //     this.money = parseFloat(this.Withdrawobj.available).toFixed(2) | 0
      //   } else if (this.type === 2) {
      //     this.money = parseFloat(this.Withdrawobj.bank_max).toFixed(2) | 0
      //   }
      // } else {
      //   this.money = 10000
      // }
      console.log(this.type);
      if (this.type === 1) {
        if (
          parseFloat(this.Withdrawobj.available) >=
          parseFloat(this.Withdrawobj.max)
        ) {
          this.money = parseFloat(this.Withdrawobj.max).toFixed(2);
        } else {
          this.money = parseFloat(this.Withdrawobj.available).toFixed(2);
        }
      }
      if (this.type === 2) {
        if (
          parseFloat(this.Withdrawobj.available) >=
          parseFloat(this.Withdrawobj.bank_max)
        ) {
          this.money = parseFloat(this.Withdrawobj.max).toFixed(2);
        } else {
          this.money = parseFloat(this.Withdrawobj.available).toFixed(2);
        }
      }
    },
    toWithdrawalsrecord() {
      this.$router.push("/withdrawalsrecord");
    },
    // 钱包
    toaddress() {
      if (!this.Withdrawobj.wallet_address) {
        setTimeout(() => {
          this.$router.push("/address");
        }, 1200);
      }
    },
    // 银行
    bankaddress() {
      if (!this.Withdrawobj.bank_account) {
        setTimeout(() => {
          this.$router.push("/address");
        }, 1200);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.withdrawBg {
  position: relative;
  width: 100%;
  height: 20rem;
  .bgimg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .Withdraw-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    background-repeat: no-repeat;

    .title {
      color: #fff;
      font-size: 1.8rem;
      font-weight: 700;
    }

    .img {
      width: 1.55rem;
      height: 1.75rem;
      margin-right: 1.5rem;
    }

    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }

  .tx-money {
    margin-top: 4rem;
    width: max-content;
    margin-left: 1.6rem;
    display: flex;
    flex-direction: column;

    .Available {
      font-size: 1.35rem;
      color: #fff;
    }

    .money-num {
      font-weight: 800;
      font-size: 2.8rem;
      color: #fff;
    }
  }
}
.Withdraw {
  margin: 0 1.6rem;
  margin-top: -2rem;
  padding: 0rem 1.6rem;
  padding-top: 1.8rem;
  padding-bottom: 2.2rem;
  border-radius: 1.8rem;
  border: 1px solid #ccc;
  background-color: #fff;

  .swtich {
    margin-bottom: 2rem;
  }

  .Withdraw-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .title {
      color: #000;
      font-size: 1.8rem;
      font-weight: 700;
    }

    .img {
      width: 1.55rem;
      height: 1.75rem;
    }

    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }

  .Amount {
    display: flex;
    color: #333;
    justify-content: space-between;
    font-size: 1.6rem;

    .right {
      display: flex;

      .Available {
        font-size: 1.4rem;
        margin-right: 1.3rem;
      }
    }
  }

  .money,
  .addres {
    height: 5.5rem;
    border: 3px solid #f6f6f6;
    border-radius: 1rem;
    margin-top: 1.5rem;
    color: #333;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    padding-right: 1rem;

    input {
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 1rem;
      border: none;
      padding: 0rem 2.5rem;
    }

    .all {
      color: #15b4fb;
      margin-left: 0.8rem;
    }
  }

  .addres {
    padding-left: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    background-color: #ffffff;
  }

  .type {
    display: flex;
    color: #fff;
    margin-top: 2rem;

    .box,
    .boxs {
      width: 6rem;
      height: 3rem;
      border: 1px solid #f6f6f6;
      border-radius: 0.5rem;
      margin-right: 2rem;
      text-align: center;
      line-height: 3rem;
      color: #333;
    }

    .boxs {
      border: none;
      background-color: #15b4fb;
      color: #fff;
    }
  }

  .address {
    color: #333;
    margin: 1.5rem 0rem;
    font-size: 1.6rem;
  }

  .info {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #333;
    margin-top: 2rem;
    font-size: 1.3rem;
    padding: 1.35rem;

    .info-desc {
      display: flex;
      margin-bottom: 1.2rem;
      font-size: 1.3rem;

      span {
        width: 100%;
        text-align: right;
        font-size: 1.3rem;
      }
    }

    .info-desc:last-child {
      margin-bottom: unset;
    }
  }

  .fee {
    color: #333;
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }
}

.btn {
  margin: 0 1.6rem;
  height: 4.4rem;
  background-color: #fe6719;
  border-radius: 1rem;
  text-align: center;
  line-height: 4.4rem;
  font-size: 1.4rem;
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.hint {
  margin: 0 1.6rem;

  ::v-deep p {
    color: #ff7575;
    font-size: 1.3rem;
    margin-top: 2rem;
    line-height: 2rem;
  }
}

::v-deep .van-radio__label {
  font-size: 2rem;
  margin-left: 1.5rem;
}

::v-deep .xz {
  border: 1px solid #707070;
  padding: 0.75rem;
  border-radius: 1rem;
}

.radio-active {
  border-color: #fe6719;
}

::v-deep .van-radio {
  margin-right: 1.5rem;
}

::v-deep .xz .van-icon-success:before {
  display: none;
}
</style>
