<template>
  <!-- 登录 -->
  <div class="login">
    <!-- <div class="logo">
      <img src="../assets/logo.png" alt="" />
    </div> -->
    <!-- <div class="title">XAI GPT</div> -->
    <div class="toL">
      <div class="hello">{{ $t("login.txt1") }}</div>
      <div class="wei">{{ $t("login.txt2") }}</div>
    </div>
    <div class="box">
      <!-- <div class="loginname">{{ $t("login.login") }}</div> -->
      <div class="loginname">
        <van-tabs
          v-model="activefour"
          :border="true"
          background="#ffffff"
          title-inactive-color="#000000"
          title-active-color="#000000"
          color="#FE6719"
          class="tabarmagin"
          @change="chanminute"
        >
          <!-- <van-tab :title="$t('login.txt3')"></van-tab> -->
          <van-tab :title="$t('login.txt4')"></van-tab>
        </van-tabs>
      </div>
      <div></div>
      <!-- <div @click="switchlanguage">{{ $t("login.language") }}</div> -->
    </div>
    <van-form @submit="onSubmit" ref="from">
      <div style="position: relative" v-if="activefour == 1">
        <van-field
          v-model="username"
          name="username"
          type="text"
          :placeholder="$t('login.txt5')"
          :rules="rules.username"
          style="padding-left: 6rem"
        />
        <div
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1.5rem;
            font-size: 1.3rem;
          "
          @click="changequhao()"
        >
          + {{ areacode }}
        </div>
      </div>
      <div style="position: relative" v-if="activefour == 0">
        <van-field
          v-model="username"
          name="username"
          type="text"
          :placeholder="$t('login.txt6')"
          :rules="rules.username"
          style="padding-left: 2rem"
        />
      </div>
      <div class="position">
        <van-field
          v-model="password"
          name="password"
          type="password"
          :placeholder="$t('login.txt7')"
          :rules="rules.password"
        />
      </div>
      <div class="Forgot">
        <div
          v-if="hostName != 'mxxai.com'"
          @click="toregister"
          style="color: #fe6719"
        >
          {{ $t("login.txt8") }}
        </div>
        <div></div>
        <div @click="toForgot" style="color: #19b8fe">
          {{ $t("login.txt9") }}?
        </div>
      </div>
      <div>
        <van-button round block type="info" native-type="submit">{{
          $t("login.txt10")
        }}</van-button>
      </div>
    </van-form>
    <div class="bottombj"></div>
    <!-- 文字-->
    <div class="xiatext">
      <div class="text">{{ $t("login.txt11") }}</div>
      <div class="texter">
        <!-- <span>{{ $t("register.txt12") }} &nbsp;</span> -->
        <a href="https://ampgpt.site/">{{ $t("register.txt13") }}</a>
        <span>{{ $t("register.txt14") }}</span>
      </div>
    </div>
    <div class="botImg">
      <img src="../assets/homeBg.png" alt="" class="img" />
    </div>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <!-- <div v-for="(i,v) in codelist" :key="v">{{i.code}}</div> -->
      <van-picker
        title=""
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        confirm-button-text="Confirmar"
        cancel-button-text="Cancelar"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getlogin, getservice, getquhao } from "@/api/index";

export default {
  name: "LoGin",
  data() {
    return {
      Language: localStorage.getItem("lagn") || "pt-br",
      username: "",
      password: "",
      rules: {},
      loading: 1,
      hrefurl: "",
      shivetype: false,
      shiveid: "",
      sn: "",
      activefour: 0,
      areacode: "52",
      show: false,
      columns: [],
      hostName: "",
    };
  },
  created() {
    this.geturl();
    this.getHostName();
    console.log(localStorage.getItem("type"));
    if (localStorage.getItem("type") != null) {
      this.shiveid = localStorage.getItem("shiveid");
      this.sn = localStorage.getItem("sn");
      this.shivetype = true;
      console.log(this.shiveid, this.sn, "logins");
    }
  },
  mounted() {
    this.setfromrules();
  },
  watch: {
    // 监听语言切换
    "$i18n.locale"() {
      this.setfromrules();
    },
  },
  methods: {
    chanminute(e) {
      this.activefour = e;
      console.log(this.activefour);
    },
    onConfirm(e) {
      // console.log(e);
      this.areacode = e;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    onChange() {},
    async changequhao() {
      await this.getquhao();

      this.show = true;
    },
    async getquhao() {
      this.columns = [];
      const res = await getquhao();
      console.log(res);
      res.data.forEach((i) => {
        this.columns.push(i.code);
      });
    },
    // 判断是否是手机端，如果是，返回true
    // tochange () {
    //   this.username = this.username.replace(/[^\d]/g, '')
    // },
    // 校验规则
    setfromrules() {
      this.rules = {
        username: [
          {
            required: true,
            message: this.$t("login.txt15"),
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.txt16"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    async onSubmit(value) {
      if (this.loading === 1) {
        this.loading = 0;
        const params = {
          email: this.username,
          password: this.password,
          area: this.areacode,
        };
        const res = await getlogin(params);
        this.loading = 1;
        if (parseFloat(res.code) === 1) {
          console.log(res.data);

          localStorage.setItem("token", res.data.userinfo.token);
          localStorage.setItem("userInfo", JSON.stringify(res.data.userinfo));
          if (localStorage.getItem("tink_var")) {
            // console.log(111)
          } else {
            localStorage.setItem("tink_var", "en-us");
          }
          Toast({
            message: res.msg,
            duration: 1200,
            className: "noticeWidth", // 所加样式名
          });
          if (this.shivetype) {
            this.$router.push({
              path: "/AssiSter",
              query: {
                shive_id: this.shiveid,
                sn: this.sn,
              },
            });
          } else {
            setTimeout(() => {
              this.$router.push("/Welcome");
            }, 500);
          }
        } else {
          Toast({
            message: res.msg,
            duration: 1200,
            className: "noticeWidth", // 所加样式名
          });
        }
      }
    },
    // 去忘记密码
    toForgot() {
      this.$router.push("/forgot");
      // window.location.href = this.hrefurl
    },
    // 去注册页面
    toregister() {
      this.$router.push("/register");
    },
    // 切换语言
    switchlanguage() {
      console.log(this.Language);
      if (this.Language === "zh-cn") {
        this.Language = "en-us";
        this.$i18n.locale = "en-us";
        localStorage.setItem("lang", "en-us");
        localStorage.setItem("tink_var", "en-us");
      } else if (this.Language === "en-us") {
        this.$i18n.locale = "zh-cn";
        this.Language = "zh-cn";
        localStorage.setItem("lang", "zh-cn");
        localStorage.setItem("tink_var", "zh-cn");
      }
    },
    // 获取绑定电报url
    async geturl() {
      const url = "/api/index/get_service";
      const res = await getservice(url);
      this.hrefurl = res.data.telegram;
      console.log(res.data.telegram, "url");
    },

    getHostName() {
      this.hostName = window.location.hostname;
      console.log(this.hostName);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #e5e5e5 !important;
}
::v-deep .van-tabs__nav {
  background-color: transparent !important;
}
.login {
  position: relative;
  /* background: url('../assets/ampbk.png') no-repeat; */
  /* // background-size: 100% 25rem; */
  background-size: cover;
  background-position: bottom;
  /* background-color: #fff; */
  padding: 5rem 1.6rem;
  padding-bottom: 0rem;
  width: 100%;
  min-height: 100vh;
  // ::v-deep --van-field-placeholder-text-color{
  //   font-weight: 700;
  //   color: red;
  // }
  ::v-deep .van-field__control::-webkit-input-placeholder {
    // color: #9b9da9;
    font-weight: 700;
  }

  .botImg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15rem;
    z-index: -2;
    .img {
      width: 100%;
      height: 15rem;
    }
  }

  .toL {
    padding: 0;
  }
  .hello {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }
  .wei {
    font-size: 1.2rem;
    color: #adb1b7;
  }

  .logo {
    width: 13rem;
    height: 12rem;
    margin: 0 auto;
  }
  .box {
    display: flex;
    color: #fff;
    justify-content: space-between;
    font-size: 1.4rem;
    margin-top: 2rem;
  }
  .title {
    color: #000;
    text-align: center;
    font-weight: 800;
    font-size: 2rem;
    // margin-top: 2.1rem;
  }
  .loginname {
    padding: 0 2.5rem;
    color: #000;
    font-size: 1.4rem;
    font-weight: 500;
    border: 1px solid #cbcbcb;
    background-color: #fff;
    margin-bottom: -0.5rem;
    border-radius: 3rem;
  }
  ::v-deep .van-cell {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    color: #000000;
    border-radius: 2.2rem;
    margin-top: 2rem;
    padding: 1.2rem 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.3rem;
    &:nth-child(1) {
      padding-right: 7rem;
    }
    &::after {
      border-bottom: 0px solid #000;
    }
  }
  ::v-deep .van-field__control {
    color: #000;
  }
  ::v-deep .position {
    position: relative;
  }
  // .code {
  //   color: #15b4fb;
  //   font-size: 1.4rem;
  //   position: absolute;
  //   top: 1.2rem;
  //   right: 2.2rem;
  // }
  ::v-deep .van-button {
    border-radius: 1rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #fe6719;
    border: none;
    font-size: 1.4rem;
    margin-top: 5rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Forgot {
    color: #3997e6;
    text-align: right;
    font-weight: 700;
    margin: 1.5rem 0rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  ::v-deep input[type="password"]::-ms-reveal {
    display: none;
  }
  // .bottombj {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 25rem;
  //   background: url('../assets/dibubj.png') no-repeat;
  //   background-size: 100% 100%;
  // }
  // bottom文字
  .xiatext {
    margin: 0 1rem;
    margin-top: 3rem;
    .text {
      color: #000;
      // margin: 0.5rem 0rem;
      // margin-top: 1.5rem;
      font-size: 1.4rem;
    }
    .texter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1rem;
    }
  }
}
</style>
