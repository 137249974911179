<template>
  <div class="Activity">
    <!-- 自定义nav-->
    <div class="nav">
      <div class="navpos">
        <div @click="$router.go(-1)">
          <van-icon color="#f0d49b" size="2rem" name="arrow-left" />
        </div>
        <!-- <div style="margin-left: 6rem">{{ $t("addtask.title") }}</div> -->
      </div>
      <!-- <div @click="$router.push('/TaskRecord')">Task Record</div> -->
    </div>
    <!-- 内容-->
    <div class="body">
      <div
        class="content"
        style="position: relative"
        v-for="(item, index) in hdarr"
        :key="index"
      >
        <div class="conimg" v-if="false">
          <img :src="item.image" />
        </div>
        <div class="concenter" style="width: 100%">
          <img class="contentimg" src="../assets/flobt.png" alt="">
          <div style="width: 100%;">
            <div class="one">{{ item.title }}（{{ item.plan }}）</div>
            <div class="two">{{ item.content }}</div>
            <div class="san">
              <div>
                +{{ item.award_money }}
                <span v-if="item.award_type == 1">USDT</span>
                <span v-if="item.award_type == 2">Points</span>
                <span v-if="item.award_type == 3">XAI</span>
              </div>
              <div
                class="conright"
                @click="joinnow(item.status, item.id, item.type)"
              >
                {{ $t("addtask.conright") }} {{ $t("addtask.conrighter") }}
              </div>
            </div>
          </div>
          <div
            class="conright"
            @click="joinnow(item.status, item.id, item.type)"
            v-if="false"
          >
            {{ $t("addtask.conright") }}<br />{{ $t("addtask.conrighter") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 内容-->
  </div>
</template>
<script>
import { hdlist, hdreceive } from "@/api/index";
import { Toast } from "vant";
export default {
  name: "ActiVity",
  data() {
    return {
      hdarr: [],
    };
  },
  created() {
    this.gethdlist();
  },
  methods: {
    async gethdlist() {
      const res = await hdlist();
      console.log(res);
      if (res.code == 1) {
        this.hdarr = res.data;
      }
    },
    async joinnow(status, id, type) {
      if (type == 3 && status == 0) {
        // 跳转分享
        this.$router.push({
          path: "/Share",
          query: {
            id,
          },
        });
        return false;
      }
      if (status == 1) {
        Toast({
          message: this.$t("addtask.tsyi"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      } else if (status == 0) {
        Toast({
          message: this.$t("addtask.tser"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return false;
      }
      const data = {
        id: id,
      };
      const res = await hdreceive(data);
      if (res.code == 1) {
        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        this.gethdlist();
      } else {
        Toast({
          message: res.msg,
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.Activity {
  width: 100%;
  background-image: url(../assets/renwuBG.png);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
  .nav {
    color: #fff;
    font-weight: 700;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    padding: 0 3rem;
    height: 35rem;
    width: 100%;
    // background-image: url('../assets/hdbk2.png');
    position: relative;
    // position: absolute
    .navpos {
      position: absolute;
      top: 1.3rem;
      left: 2rem;
      display: flex;
      align-items: center;
    }
    img {
      width: 1rem;
      height: 1.5rem;
    }
  }
  .body {
    margin: 0 2rem;
    margin-bottom: 0;
    .content {
      width: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      color: #fff;
      // border: 2px solid #5a50e8;
      padding: 1rem 1.5rem;
      border-radius: 1rem;
      margin-top: 2rem;
      background-image: url("../assets/renwu-item.png");
      background-size: 100% 100%;
      .conimg {
        // margin-left: 4rem;
        width: 5rem;
        margin-right: 1rem;
        img {
          width: 100%;
        }
      }
      .concenter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .contentimg {
          position: absolute;
          right: -2.3rem;
          top: -2.3rem;
          width: 7.5rem;
          height: 7.5rem;
        }

        .conright {
          background: #ddc58f;
          opacity: 1;
          border-radius: 1rem;
          padding: 0.5rem 1rem;
          font-weight: 700;
          color: #000;
        }
        .one {
          margin-left: -2.3rem;
          margin-top: -0.9rem;
          margin-bottom: 0.9rem;
          margin-right: 3.5rem;
          padding: 1rem;
          background-image: url("../assets/renwuleft.png");
          background-size: 100% 100%;
          font-size: 1.5rem;
          font-weight: 700;
        }
        .two {
          color: #9e9e9e;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          font-size: 1rem;
          margin: 0.5rem 0;
        }
        .san {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #ddc58f;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          img {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
