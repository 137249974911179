import Vue from 'vue'
// 在这里引入你所需的组件
import {
  Button,
  Skeleton,
  Field,
  Form,
  Icon,
  NoticeBar,
  Popup,
  ActionSheet,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  CountDown,
  Checkbox,
  CheckboxGroup,
  Uploader,
  List,
  PullRefresh,
  Popover,
  DropdownMenu, DropdownItem, Overlay, Calendar, DatetimePicker,
  Picker,
  Radio,
  RadioGroup,
  NavBar,
  Divider,
  Tag,
  Dialog,
  Grid, GridItem
} from 'vant'

// 按需引入UI组件
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Skeleton)
Vue.use(Form)
Vue.use(Field)
Vue.use(Icon)
Vue.use(NoticeBar)
Vue.use(Popup)
Vue.use(ActionSheet)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(CountDown)
Vue.use(Divider);
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(List)
Vue.use(Radio);
Vue.use(RadioGroup);

Vue.use(PullRefresh)

Vue.use(Popover)

Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Overlay)
Vue.use(Calendar)
Vue.use(DatetimePicker)


// Vue.use(Dialog);
Vue.use(Picker);
Vue.use(NavBar)