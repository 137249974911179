<template>
  <div class="runninglog">
    <van-nav-bar
      title="Registro de práctica"
      left-arrow
      @click-left="$router.go(-1)"
    />

    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('Mypurse.Theres')"
      :loading-text="$t('Mypurse.Loading')"
      @load="getData"
    >
    <div style="padding: 0 1rem;">

        <div class="log-item" v-for="item in logList">
          <span class="title">Tiempo de práctica:</span>
          <span class="date">{{ item.createtime }}</span>
        </div>
    </div>
    </van-list>
  </div>
</template>

<script>
import { getRunningLog } from "../api/index";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      logList: [],
    };
  },

  methods: {
    async getData() {
      this.loading = true;
      const res = await getRunningLog();
      this.logList = res.data;
      this.loading = false;
      this.finished = true
    },
  },
};
</script>

<style scoped lang="less">
.runninglog {
  width: 100%;
  min-height: 100vh;

  .log-item {
    padding:  0 1rem;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #CFCFCF;

    .title {
    }
    
    .date {
        font-weight: bold;
        color: #fe6719;
    }

    span {
        font-size: 1.4rem;
    }
  }
}
</style>
