// 1、创建西班牙语言包对象
export default {
  // 登录
  login: {
    txt1: "Hola, por favor inicia sesión en tu cuenta",
    txt2: "¿No tienes cuenta? Por favor regístrate primero",
    txt3: "Correo",
    txt4: "Teléfono",
    txt5: "Por favor ingresa tu correo electrónico",
    txt6: "Por favor ingresa tu número de teléfono",
    txt7: "Por favor ingresa tu contraseña de inicio de sesión",
    txt8: "Registrarse",
    txt9: "¿Olvidaste tu contraseña?",
    txt10: "Iniciar sesión",
    txt11: "Haz clic para visitar. Aceptas los Términos de Uso y la Política de Privacidad de XAI.",
    txt12: "2021-2024,",
    txt13: "XAIGPT_sitio",
    txt14: "Inc o sus afiliados",
    txt15: "Por favor ingresa una dirección de correo electrónico válida",
    txt16: "Por favor ingresa la contraseña correcta",
  },

  // 忘记密码
  Forgot: {
    txt1: "Olvidé la contraseña",
    txt2: "Correo electrónico",
    txt3: "Por favor ingresa el correo electrónico con el que te registraste",
    txt4: "Nueva contraseña",
    txt5: "Por favor ingresa tu nueva contraseña",
    txt6: "Confirmar nueva contraseña",
    txt7: "Por favor ingresa nuevamente tu nueva contraseña",
    txt8: "Código de verificación",
    txt9: "Por favor ingresa el código de verificación",
    txt10: "Obtener",
    txt11: "Confirmar",
  },

  // 注册
  register: {
    txt1: "Registrar cuenta",
    txt2: "Correo electrónico",
    txt3: "Por favor ingresa tu correo electrónico",
    txt4: "Contraseña",
    txt5: "Por favor ingresa tu contraseña",
    txt6: "Confirmar contraseña",
    txt7: "Por favor ingresa nuevamente tu contraseña",
    txt8: "Código de verificación de correo electrónico",
    txt9: "Por favor ingresa el código de verificación del correo electrónico",
    txt10: "Obtener",
    txt11: "Confirmar",
    txt12: "Por favor ingresa una dirección de correo electrónico válida",
    txt13: "",
    txt14: "",
    txt15: "Por favor ingresa una contraseña válida",
    txt16: "Por favor ingresa una contraseña válida",
    txt17: "Por favor ingresa un código de verificación de correo electrónico válido",
    txt18: "Por favor ingrese su nombre de usuario",
    txt19: "nombre de usuario",
    txt20: 'Número de teléfono',
    txt21: 'Por favor, ingrese su número de teléfono',
    txt22: 'Por favor, ingrese un número de teléfono válido',
    txt23: 'Código de verificación del teléfono',
    txt24: 'Por favor, ingrese el código de verificación del teléfono',
    txt25: 'código de verificación del teléfono móvil',
  },

  // 首页
  home: {
    txt1: "Activos Totales",
    txt2: "Ganancias de Hoy",
    txt3: "Acumuladas",
    txt4: "Bono",
    txt5: "Retirable",
    txt6: "Depositar",
    txt7: "Retirar",
    txt8: "Compartir",
    txt9: "Equipo",
    txt10: "Introducción",
    txt11: "Anuncios",
    txt12: "Registros",
    txt13: "Comenzar Práctica",
    txt14: "Principiante",
    txt15: "Avanzado",
    txt16: "Promoción",
    txt17: "Nombre del Producto",
    txt18: "Diarias",
    txt19: "Plazo",
    txt20: "Total",
    txt21: "No hay productos disponibles",
    txt22: "Comprar",
    txt23: "Percio",
    txt24: "Sí",
    txt25: "No",
    txt26: "Determinar",
    txt27: "Enviar",
    txt28: "XAI Tokens",
  },

  // 公告
  gonggao: {
    title: "Anuncio",
  },

  // 公告详情
  gonggaodetails: {
    title: "Detalles del Anuncio",
  },

  // 签到
  SignIn: {
    title: "Centro de Inicio de Sesión",
    profit: "Ganar recompensas",
    SignIn1: "Día 1",
    SignIn2: "Día 2",
    SignIn3: "Día 3",
    SignIn4: "Día 4",
    SignIn5: "Día 5",
    SignIn6: "Día 6",
    SignIn7: "Día 7",
    gift: "Caja de regalo misteriosa",
    Sign: "Registrarse",
    chick: "Iniciado sesión hoy",
    txt1: "Check-in continuo",
    txt2: "día",
  },

  // 签到记录
  CheckinRecord: {
    title: "Registro de Check-in Diario",
  },

  // k线图
  diagram: {
    height: "Altura",
    low: "Bajo",
    quantity: "Cantidad 24H",
    Recharge: "Recargar",
    purchase: "Comprar",
    min1: "1 minuto",
    min5: "5 minutos",
    min30: "30 minutos",
    hour1: "1 hora",
    dayk: "Día k",
    Weekk: "Semana k",
  },

  // 矿机运行
  Receive: {
    Remaining: "Tiempo restante de inicio",
    Start: "Comenzar",
    Click: "Haz clic para ver",
    Help: "Centro de ayuda",
    Team: "Grupos",
    Totalnumber: "Número total de personas",
    Todays: "Ganancias de hoy",
    available: "Beneficios del siguiente nivel",
    Recharge: "Recargar",
    Withdraw: "Retirar",
    friends: "Invita a tus amigos a unirse para obtener recompensas.",
    qd: "Registro de máquina", // Nuevo -- Inicio
    yq: "Invitar", // Invitación
    lrtj: "Estadísticas de Ganancias", // Estadísticas de ganancias
    tjsj: "Datos Estadísticos", // Datos estadísticos
    xj: "Informe detallado", // Detalles
  },

  // 帮助中心
  Helpcenter: {
    title: "Centro de Ayuda",
  },

  Helpcenterdetails: {
    title: "Detalles del Centro de Ayuda",
  },

  // 团队
  party: {
    title: "Mi Equipo",
    Totalnumber: "Número total de personas",
    Todays: "Recompensas de registro",
    available: "Recompensas de recarga",
    team1: "Equipo de nivel 1",
    team2: "Equipo de nivel 2",
    team3: "Equipo de nivel 3",
    Referral: "Código de referencia",
    Share: "Compartir enlace",
    Success: "Replicar éxito",
    Job: "Título profesional",
    Income: "Ingresos",
    Receive: "Recibir",
    zczrs: "Número total de registros", // Total de usuarios registrados
    czzrs: "Número total de recargas", // Total de usuarios recargados
    czyj: "Comisión de recarga", // Comisión de recarga
    zczje: "Monto total recargado", // Monto total recargado
  },

  // 团队等级
  Grade: {
    title1: "Nivel 1",
    title2: "Nivel 2",
    title3: "Nivel 3",
    Username: "Nombre de usuario",
    Phone: "Teléfono",
    Recharge: "Recargar",
    qxz: "Por favor selecciona", // Please select
    wcz: "No recargado", // Not recharged
    ycz: "Recargado", // Recharged
    cpgq: "Producto expirado", // Product expiration
    zzyx: "En funcionamiento", // Running
    title6: "Número de serie",
  },

  // 充值选择
  Recharge: {
    title: "Canal de Recarga",
    recharge: "Recargar",
    amount: "Monto de recarga",
    Please: "Por favor ingresa el monto de la recarga",
    confirm: "Confirmar",
    success: "Selección exitosa",
    less: "El monto de la recarga no puede ser menor a uno",
    txt1: 'Seleccionar canal de recarga',
  },

  // 充值详情
  Charge: {
    Address: "Copiar dirección",
    reminder: "Recordatorio de recarga",
    assets:"No agregues activos a cuentas que no sean la cuenta oficial; de lo contrario, los activos no serán recuperables",
    amount: "El monto de la transferencia debe coincidir con el monto real del pedido.",
    Upload: "Subir orden de recarga",
    Please: "Por favor asegúrate de que se haya seleccionado la imagen.",
    Success: "Subida exitosa",
    Remark: "Nota: Recuerda subir la captura de pantalla después de recargar",
  },

  // 充值记录
  Rechargerecord: {
    title: "Registro de Recarga",
    Date: "Fecha",
    Amount: "Monto",
    Channel: "Canal",
    State: "Estado",
    recharged: "Por recargar",
    successful: "Recarga exitosa",
    Notrecharged: "No recargado",
    shzt: "Estado de auditoría", // Audit status
    shz: "En revisión", // Under review
  },

  // 提现
  Withdraw: {
    title: "Retirar",
    Amount: "monto de retiro",
    Available: "Disponible",
    All: "Todo",
    address: "DIRECCIÓN",
    Submit: "Enviar",
    Currently: "Actualmente se admiten dos métodos de retiro",
    Bank: "Horas de retiro bancario: Los retiros se pueden hacer en cualquier momento de lunes a viernes.",
    Arrival:"Tiempo de llegada: Prepara según el tiempo de llegada del banco, generalmente entre 30 y 48 horas.",
    within:"El tiempo de llegada es dentro de 72 horas durante el periodo de subsidio sin impuestos.",
    withdrawal: "El monto de retiro no puede ser menor a 15.",
    receiving: "Primero vincula la cuenta de recepción antes de continuar.",
    min: "Mín",
    max: "Máx",
    fee: "Comisión",
  },

  // 采矿页面
  Mining: {
    title: "Estrategia de Negocios",
    Power: "Potencia",
    progress: "En progreso",
    Computer: "Potencia de computación",
    Purchasing: "Precio de compra",
    Daily: "Ingreso diario",
    Valid: "Periodo de validez",
    Days: "Días",
    Recharge: "Recargar",
    purchase: "Comprar ahora", // purchase
    Miner: "Minero",
    After:"Después de la expiración, el minero puede entregarte en cualquier momento.",
    Experience: "Estrategia de Negocios",
    Price: "Precio",
    profit: "Beneficio diario",
    Start: "Fecha de inicio",
    End: "Fecha de finalización",
    Agree: "Aceptar",
    Escrow: "Acuerdo de depósito en garantía",
    Artificial: "Solución de Minería con Inteligencia Artificial",
    Users: "Los usuarios pueden minar comprando máquinas de minería o alquilando poder de computación en la nube",
    Hash: "Tasa de hash: 1TH/S-100TH/S",
    Benefit:"Beneficio: Obtén el 80% de cada moneda producida, que se liquidará automáticamente en la cuenta del usuario.",
    Withdrawal: "Retiro: Retiro en cualquier momento, retiro las 24 horas",
    experience:"(Los mineros de experiencia son solo para experiencia del usuario)",
    Special: "Nota especial",
    calculation:"El cálculo de estos datos se basa en el precio actual de la moneda y se estima según los datos de la minería del grupo de monedas, y el sistema lo convierte automáticamente a USD. Para los datos específicos de producción de monedas reales, consulta la situación actual para los datos verificados de monedas.",
    machine:"Los ingresos de energía de las máquinas de minería y la computación en la nube pueden disminuir con el tiempo, y los ingresos anuales específicos pueden estar sujetos a los ingresos anuales reales en ese momento.",
    mining:"Compra una máquina de minería o alquila un plan de computación en la nube, y la tarifa de mantenimiento para el depósito de producción de monedas representa el 20% del costo de soporte de ajuste dinámico temprano del equipo. La cantidad esperada de monedas no será menor al 70% por día. El cálculo actual se basa en el valor de producción más bajo para calcular los ingresos. La expectativa anual real es superior al 300%. La cantidad real de monedas producidas prevalecerá.",
    Currency: "Tiempo de producción de moneda T+1 (es decir, dentro de un día después de la entrega nuclear y el depósito de la moneda en custodia), consulta la fecha oficial real de producción de moneda para más detalles",
    Confirm: "Confirmar",
    first: "Confirmar primero para aceptar el acuerdo de depósito en garantía",
    purchases: "Número de compras",
    gmsl: "Monto de compra",
    yjsy: "Ingreso proyectado",
  },

  // 我的页面
  my: {
    txt1: "Depositar",
    txt2: "Retirar",
    txt3: "Billetera",
    txt4: "Productos",
    txt5: "Correo",
    txt6: "Dirección de retiro",
    txt7: "Invitar Amigos",
    txt8: "Equipo",
    txt9: "Reclamar Recompensas",
    txt10: "Video de la Empresa",
    txt11: "Verificación de Seguridad",
    txt12: "Configuración de Idioma",
    txt13: "Descargar APP",
    txt14: "Acerca de la Empresa",
    txt15: "Soporte Oficial",
    txt16: "Cerrar sesión",
    txt17: "Enlace de invitación",
  },

  // 设置页面
  install: {
    title: "Instalar",
    switch: "Cambiar idioma",
    name: "Modificar nombre",
    Password: "Cambiar contraseña",
    Phone: "Cambiar número de teléfono",
    binding: "Vinculación de la app",
  },

  // 上传头像
  Headimage: {
    Avatar: "Subir avatar",
    Upload: "Subir",
    image: "La imagen subida no puede estar vacía.",
  },

  // 修改名字
  ModifyName: {
    name: "Modificar nombre",
    Determine: "Confirmar",
    cannot: "El nombre no puede estar vacío",
  },

  // 修改密码
  ChangePassword: {
    title: "Cambiar Contraseña",
    Old: "Contraseña de inicio de sesión antigua",
    New: "Nueva contraseña de inicio de sesión",
    Confirm: "Confirmar contraseña",
    Determine: "Confirmar",
    oldpassword: "Por favor ingresa la contraseña antigua",
    newpassword: "Por favor ingresa la nueva contraseña",
    Confirmationpassword: "Por favor ingresa la contraseña",
    twopasswords: "Ambas contraseñas deben coincidir.",
  },

  // 修改手机号
  Phonenumber: {
    title: "Número de Teléfono",
    phone: "Número de Teléfono",
    code: "Código de Verificación Móvil",
    codes: "Código",
    Determine: "Confirmar",
    Phonerules: "El número de teléfono no puede estar vacío",
    coderules: "El código de verificación no puede estar vacío.",
    Please: "Por favor, ingrese su número de teléfono primero.",
  },

  // 所有记录
  Mypurse: {
    title: "Mi Billetera",
    All: "Todo",
    type: "Tipo",
    Recharge: "Recargar",
    withdraw: "Retirar",
    product: "Compra de Producto",
    daily: "Ingreso Diario",
    Direct: "Recompensa por Referencia Directa",
    envelope: "Recolección de Sobre Rojo",
    fixed: "Ingreso Fijo",
    check: "Iniciar Sesión",
    benefits: "Beneficios del Equipo",
    Cancel: "Cancelar",
    Theres: "No hay más",
    Loading: "Cargando",
    System: "Ajuste del Sistema",
    yuebao: "Recompensa por la compra en el nivel inferior de Yu'ebao",
    shouyi: "Error en la suscripción de Yu'ebao",
    amount: "",
    qiandao: "",
    tuandui: "",
    zhuce: "",
    keyong: "",
    jiangli: "",
  },

  // 矿机状态
  product: {
    title: "Comprar Producto",
    progress: "En Progreso",
    purchases: "Número de Compras",
    Computer: "Poder de Cómputo",
    price: "Precio de Compra",
    Daily: "Ingreso Diario de Producción",
    running: "En Funcionamiento",
    expired: "Expirado",
    Expired: "Expirado",
    start: "Hora de Inicio",
    end: "Hora de Finalización",
  },

  // 购买记录
  Purchaserecord: {
    title: "Historial de Compras",
    types: "Tipos de",
    product: "Nombre del Producto",
    price: "Precio del Producto",
    Daily: "Retorno Diario",
    start: "Hora de Inicio",
    end: "Hora de Finalización",
  },

  // 地址
  Address: {
    title: "Dirección de Billetera",
    already:
      "Ya has agregado una dirección. Si necesitas modificarla, por favor contacta al administrador para realizar los cambios.",
  },

  // 新增地址
  Addressdetails: {
    title: "Agregar Dirección",
    Address: "DIRECCIÓN",
    Determine: "Confirmar",
    empty: "La dirección no puede estar vacía.",
    Successfully: "Añadido con éxito",
  },

  // 领取红包
  Redemption: {
    title: "Código de Redención",
    Please: "Por favor ingresa el código de redención",
    hbyi: "Número de entradas",
    hber: "Ingresa el monto",
    Determine: "Confirmar",
    Determineer: "Confirmar Recolección",
    Determinesan: "Quiero enviar USDT",
    Exchange: "El código de redención no puede estar vacío",
  },

  // 视频介绍
  Video: {
    title: "Introducción en Video",
  },

  // 公司介绍
  company: {
    title: "Acerca de la Empresa",
  },

  // 活动列表
  activity: {
    title: "Actividad",
    hot: "Productos Populares",
    discounted: "Productos con Descuento",
  },

  mail: {
    mailbox: "Buzón",
    yzm: "Código",
    srmail: "Por favor ingresa tu dirección de correo electrónico", // Please enter your email address
    sryzm: "Ingresa el código", // Please enter the verification code
    sendcode: "Enviar", // Send verification code
    emptyemail: "El correo electrónico no puede estar vacío.", // Email cannot be empty,
    txt1: "Cambiar correo electrónico",
  },

  profit: {
    jrsy: "Ganancias de Hoy", // 今日收益
    zcyqjl: "Recompensas por Invitación de Registro", // 注册邀请奖励
    jqsy: "Ganancias de la Máquina", // 机器收益
    tdyjjl: "Recompensas por Comisión de Equipo", // 团队佣金奖励
    yxsr: "Ingreso de Salario Mensual", // 月薪收入
    lrze: "Beneficio Total", // 利润总额
    zzcyqjl: "Total de Recompensas por Invitación de Registro", // 总注册邀请奖励
    zjqsy: "Total de Ganancias de la Máquina", // 总机器收益
    ztdyjjl: "Total de Recompensas por Comisión de Equipo", // 总团队佣金奖励
    zyxsr: "Total de Ingreso de Salario Mensual", // 总月薪收入
    jrzlr: "Beneficio Total Hoy", // 今日总利润
    zlr: "Beneficio Total", // 总利润
  },

  foot: {
    home: "Inicio", // 首页
    yx: "Activo", // 运行
    jy: "Juego", // 交易
    tm: "Equipo", // 活动
    my: "Mi", // 我的
  },

  rechargettwo: {
    name: "Nombre",
    bank: "Banco",
    account: "Cuenta",
    qtjczjt: "Por favor añade una captura de pantalla de la recarga",
    czje: "Monto de recarga",
  },

  add: {
    addemail: "Correo electrónico",
    addforbottom:
      "Si la verificación de correo electrónico falla, haz clic en Contacto",
    addforbottomer: "Servicio al Cliente Oficial",
    addregtitle: "Registro",
    addregts: "Registro exitoso, redirigiendo",
  },

  addhome: {
    inbodyyi: "Depositar",
    inbodyer: "Recargar",
    inbodysan: "Retirar",
    inbodysi: "Compartir con Amigos",
    inbodywu: "Grupos",
    inbodyliu: "Centro de Recompensas",
    rw: "Recompensa",
    wallet: "Mi Billetera",
    walletyi: "Activos Totales",
    walleter: "Saldo Disponible",
    walletsan: "Ganancias",
    walletsi: "Ganancias de Hoy",
    walletwu: "Tokens XAI Totales",
    walletliu: "Valor Total de XAI",
    help: "Centro de Ayuda",
    bottomamp: "XAIGPT",
    bottom:
      " te ayuda a dominar los secretos de ganar dinero en la era de la inteligencia artificial!",
    bottomer:
      " recoge automáticamente señales de comercio y realiza compras y ventas automatizadas. ¡Te permite ganar dinero fácilmente las 24 horas del día, 7 días a la semana, y obtener ganancias de comercio automáticas! ¡Análisis de datos independiente, comercio inteligente y garantías de ganancias estables! Únete a nosotros y comienza tu viaje hacia la libertad financiera!",
    bottomtwo: "Operación Segura",
    bottomtwoday: "Día",
    bottomtwoer: "Ganancias Acumuladas",
    hezuotitle: "Plataforma de Cooperación",
    hezuoyi: "Binance",
    hezuoer: "Kraken",
    hezuosan: "KuCoin",
    hezuosi: "Bitmark",
    hezuowu: "Gate.io",
    hezuoliu: "Coinbase",
    hezuoqi: "Bybit",
    hezuoba: "OKX",
    hezuojiu: "bitget",
    hezuoshi: "Bitfinex",
    tcyi: "Regalo Misterioso",
    tcer: "Caja",
    tcsan: "Última Publicación",
    gb: "Cerrar",
  },

  addrech: {
    maxusdt: "El monto de recarga no puede exceder",
    tsyi: "El valor de recarga no puede exceder 5,000",
    tser: "Valor mínimo de recarga",
    // tsmessyi:
    //   "Utilice USDT para recargar y disfrute de reembolsos adicionales. Por ejemplo, si la tasa de bonificación es del 3%, si el usuario recarga 100USDT utilizando USDT, recibirá 103USDT.",
    // tsmesser:
    //   "Después de completar la recarga de la cuenta utilizando cualquier método de pago, los usuarios pueden recibir recompensas en tokens XAI, puntos de lotería, recompensas en efectivo, etc. Preste atención al anuncio de la compañía para recompensas específicas.",
    tsmessyi1:"Instrucciones para recargar cuenta:",
    tsmessyi2:"Admite recarga de criptomonedas y recarga de cuenta bancaria.",
    tsmessyi3:"Puedes obtener recompensas en efectivo de la empresa utilizando la criptomoneda USDT para recargar. Consulte con el administrador para obtener recompensas específicas.",
    tsmessyi4:"Para recargar utilizando una cuenta bancaria, obtenga un nuevo número de cuenta cada vez. No recargue la cuenta antigua para evitar pérdidas financieras.",
    tsmessyi5:"Si encuentra algún problema de recarga o no ha recibido la recarga, comuníquese con el administrador de inmediato.",
  },
  addbank: {
    banktitle: "Recargar",
    banktitlevalue: "Ingrese el monto de recarga",
    tsyi: "El monto de recarga no puede ser cero",
    tser: "El valor de recarga no puede ser menor que",
    btn: "Enviar recarga",
    tssan: "Por favor, cargue la imagen primero",
    bankbtn: "Confirmar recarga",
    address: "",
    copy: "Copiar",
    reminder: "* Recordatorio de recarga",
    txt1: "1. No agregue activos a cuentas que no sean oficiales, de lo contrario los activos no serán recuperados.",
    txt2: "2. El monto de la transferencia debe coincidir exactamente con el monto real del pedido",
    txt3: "Nota: Recuerde cargar la imagen de carga después de que se cargue.",
    tips: "",
    tips2: "Escanea el código QR para transferir USDT",
    address_t: "Dirección de billetera",
  },
  addwith: {
    address: "Dirección de billetera",
    addresser: "Dirección de cuenta bancaria",
    addresssan: "Tipo de retiro",
    addresssi: "Banco",
    tsyi: "El monto de retiro no puede ser menor que",
    tser: "El monto de retiro no puede exceder",
    tssan: "El monto de retiro no puede ser menor que",
    jiluyi: "Registro de retiro",
    jiluer: "Fecha",
    jilusan: "Monto",
    jilusi: "Canal",
    jiluwu: "Estado",
  },
  address: {
    Bitcoin: "Revertir",
    message:
      "Por la seguridad de su cuenta, por favor vuelva a emitir el retiro 24 horas después de modificar la información de la cuenta.",
    messageer: "Por favor únase primero.",
    usdtyi: "Por favor ingrese su dirección de billetera",
    usdter: "Solo se pueden agregar direcciones TRC20",
    usdtsan: "Correo electrónico de contacto",
    usdtsi: "Código de verificación de correo electrónico",
    addbankyi: "Número de cuenta",
    addbanker: "Ingrese la cuenta",
    addbanksan: "Nombre del banco",
    addbanksi: "Ingrese el nombre del banco",
    addbankwu: "Por favor ingrese su nombre",
    addbankliu: "Por favor ingrese su nombre",
    addbankqi: "Correo electrónico de contacto",
    addbankba: "Correo electrónico de contacto",
    addbankjiu: "Código de verificación de correo electrónico",
    addbankp:
      "Para asegurar que sus fondos de retiro lleguen a su cuenta sin problemas, por favor vincule su nombre real y cuenta bancaria. De lo contrario, es posible que no reciba el retiro.",
    tsyi: "El banco no puede estar vacío.",
    tser: "El número no puede estar vacío",
    tssan: "El correo electrónico no puede estar vacío.",
    tssi: "El código de verificación no puede estar vacío.",
    tswu: "El nombre no puede estar vacío",
    tsliu: "El formato del correo electrónico es incorrecto.",
  },
  addtask: {
    title: "Centro de recompensas",
    conright: "Unirse",
    conrighter: "Ahora",
    tsyi: "Recibido",
    tser: "Descartado",
    titleer: "Compartir",
    shareyi: "Sube y envía una foto",
    shareer: "Por favor sube el certificado de compartición",
    sharesan: "Enviar",
    sharets: "La imagen aún no ha sido subida.",
  },
  addturn: {
    start: "Tiempo restante para terminar",
    problenyi: "Este sorteo aún no está abierto, así que mantente atento.",
    problener:
      "El sorteo giratorio primero consumirá puntos y se cambiará automáticamente a saldo cuando los puntos sean insuficientes.",
    problensan: "Consumir ",
    newproblensan: " puntos para participar en el sorteo.",
    problensi: "Consumir ",
    newproblensi: " saldo para participar en el sorteo.",
    topboxyi: "Puntos restantes",
    topboxer: "Saldo disponible",
    topboxsan: "Puntos acumulados",
    btn: "Registros de sorteos",
    tsyi: "Si los puntos son menores que ",
    newtsyi: " , te has cambiado al saldo para participar en el sorteo",
    tser: "El saldo es menor que ",
    newtser: " no puede participar",
    tssan: "Los que tienen menos de ",
    newtssan: " puntos no pueden participar",
    tssi: "El saldo es menor que ",
    newtssi: " no puede participar",
    tswu: "El sorteo aún no ha comenzado, mantente atento.",
    tsliu: "No has comprado un robot y no puedes asistir al evento",
    tsqi: "El sorteo aún no ha comenzado, mantente atento.",
    title: "Rueda de la suerte",
    Bitcoin: "Consumo de puntos",
    Bitcoiner: "Pegar",
  },
  addrece: {
    title: "Grupos",
    tcyi: "Copiar enlace",
    tcer: "Compartir en",
    tsyi: "Por favor recarga y activa tu cuenta primero",
  },
  addparty: {
    incomeyi: "Reembolso no recibido",
    incomeer: "Ingreso diario",
    incomesan: "Recompensas promocionales",
    lbodyyi: "Total / Válido",
    lbodyer: "Recarga/Comisión",
    level: "Nivel",
    sqyj: "Recoger comisión",
    levelteam: "Equipo de nivel",

    // jieshaotitle: "Reglas específicas:",
    // jieshaoyi: "Reglas de invitación",
    // jieshaoer:
    //   "Invita a más amigos a unirse a tu equipo XAI. Puedes obtener más recompensas por invitación y bonos de desarrollo del equipo.",
    // jieshaosan:
    //   "Invita a cada amigo para obtener el robot XAIGPT y recibirás una recompensa de invitación de 3USDT. ¡Cuantos más amigos invites, más recompensas recibirás, sin límite superior!",
    // jieshaosi:
    //   "Puedes obtener comisión permanente del equipo. Obtén el monto de recarga de los miembros del nivel 1 (4%), el monto de recarga de los miembros del nivel 2 (2%), y el monto de recarga de los miembros del nivel 3 (0.5%).",
    // jieshaowu:
    //   "Cuando los miembros de tu equipo de nivel 123 alcancen diferentes números, podrás recibir bonificaciones y recompensas de la empresa en diferentes etapas cada semana y cada mes.",


    jie1: "Recompensas por desarrollo de equipo:",
    jie2: "¡La cantidad de equipos activos determina tu bonificación e ingresos! ¡Cuanto más grande sea tu equipo, más ganarás!",
    jie3:
      "¡Activa tu cuenta para disfrutar de recompensas de recarga permanentes de nivel de equipo 123! La tasa de recompensa básica es del 4%, 2% y 0.5%. (Cuanto más alto sea tu nivel de cuenta, más podrás disfrutar del bono de recarga del 4%-5% del equipo de Nivel 1)",
    jie4:
      "C.La empresa otorgará periódicamente recompensas y subsidios a los líderes de equipo destacados.",
    jieshaoliu:
      "Cuando los usuarios activos de tu equipo de nivel 123 alcancen diferentes números, podrás recibir bonificaciones y recompensas en diferentes etapas, como semanales y mensuales.",




    thiszhou: "Esta semana",
    thismonth: "Este mes",
    lastmonth: "El mes pasado",
    all: "Todo",
    zdytime: "Personalizado",
    tsyi: "Actualmente no hay comisiones disponibles para ingresos",
    gradeyi: "Nivel 1 activos",
    gradeer: "Nivel 2 activos",
    gradesan: "Nivel 3 activos",
    gradetitle: "Nivel del equipo",
    jintian: "Hoy",
    kstime: "Hora de inicio",
    jstime: "Hora de finalización",
    btnqd: "Confirmar",
    qd: "Confirmar",
    qx: "Cancelar",
    qxz: "Por favor seleccione",
  },
  addpull: {
    pullyi: "Desliza hacia abajo para actualizar",
    puller: "Suelta para actualizar",
    pullsan: "Cargando...",
  },
  addprofit: {
    title: "Ganancia",
    datayi: "Tasa de recompensa por registro",
    dataer: "Tasa de ganancia de máquina",
    datasan: "Tasa de comisión de equipo",
    datasi: "Tasa de ingresos por salario mensual",
  },
  addmin: {
    content: "Tasa de retorno",
    tcyi: "Calcular",
    tsyi: "Actualmente no tienes la cantidad de máquinas compradas",
    tser: "No hay más cantidad disponible",
    tssan: "Actualmente no tienes la cantidad de máquinas compradas",
    tssi: "La cantidad de compra no puede ser cero.",
  },
  addgift: {
    nav: "BTC/USDT",
    normal: "Predicción",
    navrightyi: "Historial",
    navrighter: "Regla",
    qhyi: "Modo normal",
    qher: "Modo estable",
    msgyi: "Alto",
    msger: "Bajo",
    msgsan: "Volumen 24H",
    fxk: "Política de privacidad financiera",
    bonex: "Boleto",
    btwoyi: "Monto de apuesta:",
    datanull: "Actualmente no hay datos disponibles",
    tcyi: "Monto",
    tcer: "Se espera que esté disponible después de la rentabilidad.",
    tcsan: "Comisión de procesamiento:",
    tcsi: "Monto en USDT",
    tcsitwo: "Saldo disponible:",
    tcwu: "Enviar",
    tarbaryi: "1 minuto",
    tarbarer: "5 minutos",
    tarbarsan: "Comercio",
    tabarsi: "Transacciones históricas",
    tabarwu: "Transacción pendiente",
    tabarliu: "Registro de transacciones",
    tsyi: "Por favor revise la política de privacidad primero.",
    tser: "El monto no puede estar vacío o ser cero",
    tssan: "No puede ser menor que el mínimo de",
    htitle: "Historial de números ganadores",
    htsyi: "¿Qué está pasando?",
    rtitle: "Regla",
    Ttitle: "Análisis de tendencias",
    tbodyyi: "Predicción 28",
    tbodyer: "[Señal]",
    ycboyi: "Planificador",
    ycboer: "Boleto",
    ycbosan: "Predecir",
    ycbosi: "Resultado",
    ycbowu: "Ganar",
    ycboliu: "Perder",
    ycboki: "Número de compradores:",
    ycboba: "Selección rápida",
    yctcyi: "Confirmación de pedido",
    yctcer: "Boleto",
    yctcsan: "Opciones de apuesta",
    yctcsi: "Monto de apuesta individual",
    yctcwu: "Pedido total",
    yctcliu: "Cancelar",
    yctcqi: "Confirmar",
    ycts: "El monto ingresado no puede estar vacío",
  },
  addmy: {
    mywyi: "Activos totales:",
    mywer: "Ganancias de hoy: ",
    mywsan: "Ingreso total:",
    mywsi: "Recompensas:",
    mywwu: "Saldo disponible:",
    mywliu: "Recargar",
    mywqi: "Retirar",
    myba: "Duración",
    sboyi: "Regalo misterioso",
    sboer: "Caja",
    iboyi: "WhatsApp",
    iboer: "Telegram",
    ibosan: "Correo electrónico",
    xgpassts:
      "Después de cambiar la contraseña, se tarda 24 horas en iniciar el retiro",
    appyi: "Vincular cuenta",
    apper: "WhatsApp vinculado con éxito",
    tele: "Telegram vinculado con éxito",
    yx: "Correo electrónico vinculado con éxito",
    appsan: "Cuenta:",
    appsi: "Revertir",
    appts:
      "Ya has agregado una cuenta; si necesitas modificarla, contacta con el administrador para su operación.",
    yxts: "Después de hacer clic en enviar, no olvides revisar tu carpeta de spam. Si aún no recibes el código de verificación, intenta de nuevo más tarde.",
    mywts: "Detalles del registro de facturas de esta semana",
    // codeyi: "Recompensas por intercambio de USDT",
    // codeer: "Recompensas de canje de XAI",
    codeyi: "USDT",
    codeer: "XAI",
    duryi: "Duración",
    durer: "Duración en línea",
    dursan: "La duración en línea es de 30 minutos.",
    mailts: "Solo se pueden ingresar letras minúsculas",
  },
  evaluate: {
    one: "Opiniones de usuarios",
    tabone: "Última versión",
    tabtwo: "Quiero publicar",
    input: "¡Por favor asegúrese de revisar el contenido de la opinión!",
    imgone: "Contenido de la opinión",
    imgtwo: "Subir imágenes",
    kong: "El contenido subido no puede estar vacío",
  },
  hongbao: {
    listtitle: "Historial de envío",
    listyi: "Todos",
    lister: "Recibido",
    listsan: "Enviado",
    tsyi: "El sobre rojo y el monto no pueden estar vacíos",
    daodil: "Es el fin",
  },
  mlog: {
    title: "Registro de máquina",
    listtime: "Hora de inicio",
  },
  newkx: {
    title: "Monto acumulado del pedido",
    title1: "Obtener monto de comisión",
  },
  jjbuy: {
    title: "Apreciación de riqueza",
    title1: "Historial",
    title2: "Límite de compra",
    title3: "Comprar",
    title4: "Ingreso diario",
    title5: "Día",
    title6: "El término",
    title7: "Monto de suscripción",
    title8: "Cantidad restante",
    title9: "Distancia hasta el fin",
    title10: "Saldo disponible",
    title11: "Recargar",
    title12: "Reembolso de saldo",
    title13: "¿Estás seguro de que deseas comprar el producto modificado?",
    title14: "Confirmar",
    title15: "Cancelar",
    title16: "Historial de compra",
    title17: "Pendiente de revisión",
    title18: "No pasó",
    title19: "Ingreso",
    title20: "Completado",
    title21: "Periodo de solicitud",
    newtitle21: "Tiempo de aprobación",
    title22: "Si el saldo es insuficiente, por favor recarga primero.",
    text1: 'Saldo de la cuenta',
    text2: 'Recarga',
    text3: 'Reembolso',

  },
  newhome: {
    title: "La actividad ha terminado",
    title1: "El evento no ha comenzado.",
    title2: "Por favor espera a que termine la cuenta regresiva",
  },
  Receive: {
    txt1: "Tablero de la comunidad",
    txt2: "Eventos corporativos",
    txt3: "Despacho de misiones",
    txt4: "Rueda de la suerte",
    txt5: "Fondos",
  },
  Gift: {
    txt1: "Compartir enlace",
    txt2: "Copiar",
    txt3: "Invitar amigos para ganar recompensas",
    txt4: "Mi equipo",
    txt5: "Total de miembros del equipo",
    txt6: "Recompensas totales del equipo",
    txt7: "Clasificación de ganancias del equipo",
    txt8: "Esta semana",
    txt9: "Este mes",
  },
  Product: {
    title: "Historial de compras",
    txt1: "Nombre",
    txt2: "Precio",
    txt3: "Período de validez",
    txt4: "Estado",
    txt5: "Ingreso diario",
    txt6: 'En ejecución',
    txt7: 'Expirado'
  },
  Share: {
    txt1: "Mi nivel",
    txt2: "Enlace para compartir",
    txt3: "Copiar",
    txt4: "Entrada a la comunidad",
  },
  RedList: {
    txt1: "Enviar usuario",
    txt2: "Recibir",
    txt3: "Quiero enviar",
  },

  racecar: {
    txt1: "Panel estándar",
    txt2: "Instrucciones del juego",
    boleto: ["Grande", "Pequeño", "Doble", "Simple"],
    rank: [
      "Campeón",         // Champion
      "Subcampeón",      // Runner-up
      "Tercer",    // Third place
      "Cuarto",    // Fourth place
      "Quinto",    // Fifth place
      "Sexto",     // Sixth place
      "Séptimo",   // Seventh place
      "Octavo",    // Eighth place
      "Noveno",    // Ninth place
      "Décimo"     // Tenth place
    ],
    txt3: "1 minuto",
    txt4: "5 minuto",
    txt5: 'Apostando',
    txt6: 'Carreras',
    txt7: 'Sorteo',
    txt8: 'Ingresar monto',
    txt9: 'Apuestas recientes',
    txt10: 'Apostar ahora',
    txt11: 'Historial de apuestas',
    txt12:'historial de fondos',
    txt13: 'no hay más',
    txt14: 'cargando...',
    txt15: 'Apuesta hecha',
    txt16: 'Desea apostar ',
    txt17: ' monto?',
    txt18: 'Tendencia completa',
    txt55: 'El hombre que se levantó de la cama',
    txt77: 'Confirmación',
    txt88: 'Cancelación'
  },
  vip: {
    txt1: 'nivel de membresía',
    txt2: 'Nivel',
    txt3: 'Porcentaje de reembolso',
    txt4: 'Alcanzado'
  }
};
