<template>
  <!-- 提现记录 -->
  <div class="record">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="Pull down to refresh"
      loosing-text="Let it go and update"
      loading-text="Loading..."
      pull-distance="100"
    >
      <div style="min-height: 100vh">
        <div class="record-title">
          <van-icon
            name="arrow-left"
            size="2rem"
            color="#000"
            @click="$router.back()"
          />
          <div class="title">{{ $t("addwith.jiluyi") }}</div>
          <div></div>
        </div>
        <div class="top" v-if="false">
          <div>{{ $t("addwith.jiluer") }}</div>
          <div>{{ $t("addwith.jilusan") }}</div>
          <div>{{ $t("addwith.jilusi") }}</div>
          <div>{{ $t("addwith.jiluwu") }}</div>
        </div>
        <!-- 分页 -->
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('Mypurse.Theres')"
          :loading-text="$t('Mypurse.Loading')"
          @load="onLoad"
        >
          <div class="bottom" v-for="(i, v) in list" :key="v">
            <div style="display: flex; flex-direction: column; width: 100%">
              <div style="font-weight: bold;margin-bottom: 0.5rem;">{{ i.type === 1 ? "USDT" : "BANK" }}</div>
              <div>{{ i.status }}</div>
              <div class="date">{{ i.createtime }}</div>
            </div>
            <div style="color: #ff0000">{{ i.price }}</div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Withdrawalsrecordapi } from "@/api/index";
export default {
  name: "reCord",
  data() {
    return {
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      page: "1", // 页码
      limit: "12", // 一页请求多少条
    };
  },
  mounted() {
    // this.onLoad()
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    async RechargeRecord() {
      const data = {
        limit: this.limit,
        page: this.page,
      };
      const res = await Withdrawalsrecordapi(data);
      //   console.log(res)
      if (res.data.last_page <= this.page) {
        this.finished = true;
      }
      this.list.push(...res.data.data);
      this.list.forEach((i) => {
        i.price = parseFloat(i.price).toFixed(2);

        if (parseFloat(i.status) === 0) {
          i.status = "Pending review";
        } else if (parseFloat(i.status) === 1) {
          i.status = "Successful withdrawal";
        } else if (parseFloat(i.status) === 2) {
          i.status = "Withdrawal failed";
        } else if (parseFloat(i.status) === 3) {
          i.status = "Withdrawal rejected";
        }
      });
    },
    async onLoad() {
      this.loading = true;
      await this.RechargeRecord();
      this.page++;
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  // background-color: #fff;
  background-image: url("../assets/mypu.png");
  // background-size: 100%  25rem;
  background-repeat: no-repeat;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .record-title {
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .top,
  .bottom {
    color: #333;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0rem;
    border-bottom: 2px solid #f6f6f6;
    font-size: 1.2rem;
    div {
      // width: 25%;
      // text-align: center;
    }
  }
  .bottom {
    font-size: 1.3rem;
    border-bottom: 0px solid #f6f6f6;
    color: #333;
  }

  .date {
    margin-top: 0.5rem;
    font-size: 1rem;
  }
}
</style>
