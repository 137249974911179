import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Forgot from '../views/Forgot.vue'
import Home from '../views/Home.vue'
import Announcement from '../views/Announcement.vue'
import SignIn from '../views/SignIn.vue'
import CheckinRecord from '../views/CheckinRecord.vue'
import Allrecords from '../views/Allrecords.vue'
import Receive from '../views/Receive.vue'
import Helpcenter from '../views/Helpcenter.vue'
import Recharges from '../views/Recharges.vue'
import withdraw from '../views/Withdraw.vue'
import party from '../views/party.vue'
import Grade from '../views/Grade.vue'
import Record from '../views/Record.vue'
import Charge from '../views/Charge.vue'
import gift from '../views/gift.vue'
import Mining from '../views/Mining.vue'
import my from '../views/my.vue'
import product from '../views/product.vue'
import install from '../views/install.vue'
import ModifyName from '../views/ModifyName.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Phonenumber from '../views/Phonenumber.vue'
import Modifyemail from '../views/Modifyemail.vue'
import diagram from '../views/diagram.vue'
import gonggaodetails from '../views/gonggaodetails.vue'
import bangzhudetails from '../views/bangzhudetails.vue'
import Address from '../views/Address.vue'
import Addressdetails from '../views/Addressdetails.vue'
import Purchaserecord from '../views/Purchaserecord.vue'
import register from '../views/register.vue'
import Headimage from '../views/Headimage.vue'
import Redemption from '../views/Redemption.vue'
import Video from '../views/Video.vue'
import Mypurse from '../views/Mypurse.vue'
import CompanyDetails from '../views/CompanyDetails.vue'
import EventDetails from '../views/EventDetails.vue'
import turntable from '../views/turntable.vue'
import BankCard from '../views/BankCard.vue'
import Withdrawalsrecord from '../views/Withdrawalsrecord.vue'
import YuEbao from '../views/YuEbao.vue'
import YuEbaodetails from '../views/YuEbaodetails.vue'
import airplane from '../views/airplane.vue'
import telegraph from '../views/telegraph.vue'
import Error403 from '../views/Error403.vue'
import lotteryrecord from '../views/lotteryrecord.vue'
import MailBox from '../views/MailBox.vue'
import ProfitStatistics from '../views/ProfitStatistics.vue'
import RechargeTwo from '../views/RechargeTwo.vue'
import HistoricalLottery from '../views/HistoricalLottery.vue'
import Rules from '../views/Rules.vue'
import Trendanalysis from '../views/Trendanalysis.vue'
import TrenDanalyxq from '../views/TrenDanalyxq.vue'
import gifter from '../views/gifter.vue'
import Activity from '../views/Activity.vue'
import TaskRecord from '../views/TaskRecord.vue'
import Duration from '../views/Duration.vue'
import Share from '../views/Share.vue'
import EvaLuate from '../views/EvaLuate.vue'
import EnvElope from '../views/EnvElope.vue'
import RedList from '../views/RedList.vue'
import machineLog from '../views/machineLog.vue'
import AssiSt from '../views/AssiSt.vue'
import AssiSter from '../views/AssiSter.vue'
import AssiStminning from '../views/AssiStminning.vue'
import AssiStminningxq from '../views/AssiStminningxq.vue'
import jbdh from '../views/jbdh.vue'
import Fund from '../views/Fund.vue'
import funds from '../views/funds.vue'
import racecar from '../views/racecar.vue'
import periodReord from '@/views/periodReord.vue'
import CompanyEvent from '../views/CompanyEvent.vue'
import periodChart from '../views/periodChart.vue'
import RunningLog from '../views/runninglog.vue'
import Welcome from '../views/welcome.vue'
import VipLevel from '../views/vipLevel.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/vipLevel',
    name: 'vipLevel',
    component: VipLevel
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: Announcement
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/checkinrecord',
    name: 'CheckinRecord',
    component: CheckinRecord
  },
  {
    path: '/allrecords',
    name: 'Allrecords',
    component: Allrecords
  },
  {
    path: '/receive',
    name: 'Receive',
    component: Receive
  },
  {
    path: '/helpcenter',
    name: 'Helpcenter',
    component: Helpcenter
  },
  {
    path: '/recharges',
    name: 'Recharges',
    component: Recharges
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: withdraw
  },
  {
    path: '/party',
    name: 'party',
    component: party
  },
  {
    path: '/grade',
    name: 'Grade',
    component: Grade
  },
  {
    path: '/record',
    name: 'Record',
    component: Record
  },
  {
    path: '/charge',
    name: 'Charge',
    component: Charge
  },
  {
    path: '/gift',
    name: 'gift',
    component: gift
  },
  {
    path: '/mining',
    name: 'Mining',
    component: Mining
  },
  {
    path: '/my',
    name: 'my',
    component: my
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/install',
    name: 'install',
    component: install
  },
  {
    path: '/modifyname',
    name: 'ModifyName',
    component: ModifyName
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/phonenumber',
    name: 'Phonenumber',
    component: Phonenumber
  },
  {
    path: '/modifyemail',
    name: 'Modifyemail',
    component: Modifyemail
  },
  {
    path: '/diagram',
    name: 'diagram',
    component: diagram
  }, {
    path: '/gonggaodetails',
    name: 'gonggaodetails',
    component: gonggaodetails
  }, {
    path: '/bangzhudetails',
    name: 'bangzhudetails',
    component: bangzhudetails
  }, {
    path: '/address',
    name: 'Address',
    component: Address
  }, {
    path: '/addressdetails',
    name: 'Addressdetails',
    component: Addressdetails
  }, {
    path: '/purchaserecord',
    name: 'Purchaserecord',
    component: Purchaserecord
  }, {
    path: '/register',
    name: 'register',
    component: register
  }, {
    path: '/Headimage',
    name: 'Headimage',
    component: Headimage
  }, {
    path: '/redemption',
    name: 'Redemption',
    component: Redemption
  }, {
    path: '/video',
    name: 'Video',
    component: Video
  }, {
    path: '/mypurse',
    name: 'Mypurse',
    component: Mypurse
  }, {
    path: '/companycetails',
    name: 'CompanyDetails',
    component: CompanyDetails
  }, {
    path: '/eventdetails',
    name: 'EventDetails',
    component: EventDetails
  }, {
    path: '/turntable',
    name: 'turntable',
    component: turntable
  }, {
    path: '/bankcard',
    name: 'BankCard',
    component: BankCard
  }, {
    path: '/withdrawalsrecord',
    name: 'Withdrawalsrecord',
    component: Withdrawalsrecord
  }, {
    path: '/yuebao',
    name: 'YuEbao',
    component: YuEbao
  }, {
    path: '/yuebaodetails',
    name: 'YuEbaodetails',
    component: YuEbaodetails
  }, {
    path: '/airplane',
    name: 'airplane',
    component: airplane
  }, {
    path: '/telegraph',
    name: 'telegraph',
    component: telegraph
  }, {
    path: '/error',
    name: 'Error403',
    component: Error403
  }, {
    path: '/lotteryrecord',
    name: 'lotteryrecord',
    component: lotteryrecord
  }, {
    path: '/MailBox',
    name: 'MailBox',
    component: MailBox
  }, {
    path: '/ProfitStatistics',
    name: 'ProfitStatistics',
    component: ProfitStatistics
  }, {
    path: '/RechargeTwo',
    name: 'RechargeTwo',
    component: RechargeTwo
  }, {
    path: '/HistoricalLottery',
    name: 'HistoricalLottery',
    component: HistoricalLottery
  }, {
    path: '/Rules',
    name: 'Rules',
    component: Rules
  }, {
    path: '/Trendanalysis',
    name: 'Trendanalysis',
    component: Trendanalysis
  }, {
    path: '/TrenDanalyxq',
    name: 'TrenDanalyxq',
    component: TrenDanalyxq
  }, {
    path: '/gifter',
    name: 'gifter',
    component: gifter
  }, {
    path: '/Activity',
    name: 'Activity',
    component: Activity
  }, {
    path: '/TaskRecord',
    name: 'TaskRecord',
    component: TaskRecord
  }, {
    path: '/Duration',
    name: 'Duration',
    component: Duration
  }, {
    path: '/Share',
    name: 'Share',
    component: Share
  }, {
    path: '/EvaLuate',
    name: 'EvaLuate',
    component: EvaLuate
  }, {
    path: '/EnvElope',
    name: 'EnvElope',
    component: EnvElope
  }, {
    path: '/RedList',
    name: 'RedList',
    component: RedList
  }, {
    path: '/machineLog',
    name: 'machineLog',
    component: machineLog
  }, {
    path: '/AssiSt',
    name: 'AssiSt',
    component: AssiSt
  }, {
    path: '/AssiSter',
    name: 'AssiSter',
    component: AssiSter
  }, {
    path: '/AssiStminning',
    name: 'AssiStminning',
    component: AssiStminning
  }
  , {
    path: '/AssiStminningxq',
    name: 'AssiStminningxq',
    component: AssiStminningxq
  },
   {
    path: '/Fund',
    name: 'Fund',
    component: Fund
  },
{
    path: '/funds',
    name: 'funds',
    component: funds
  },
  {
    path: '/racecar',
    name: 'racecar',
    component: racecar
  },
  {
    path: '/periodReord',
    name: 'periodReord',
    component: periodReord
  },
  {
    path: '/CompanyEvent',
    name: 'CompanyEvent',
    component: CompanyEvent
  },
  {
    path: '/periodChart',
    name: 'periodChart',
    component: periodChart
  },
  {
    path: '/RunningLog',
    name: 'RunningLog',
    component: RunningLog
  },
  {
    path: '/Welcome',
    name: 'Welcome',
    component: Welcome
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }  //动态路由匹配
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

// 添加以下代码即可  解决重复跳转路由的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
