<template>
  <!-- 大转盘抽奖 -->
  <div class="turntable">
    <div class="turntable-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#fff"
      />
    </div>
    <div class="remainingbox">
      <div class="remaining">
        <div class="start-time">
          <div style="font-size: 1.3rem">{{ $t("addturn.start") }}</div>
          <!-- <div style="color: #fa9803; font-size: 1.5rem">{{starttime ? starttime :'00:00:00'}}</div> -->
          <van-count-down
            :time="starttime"
            style="color: #fa9803"
          ></van-count-down>
        </div>
      </div>
      <div class="problen" v-if="parseFloat(Atividade) === 0">
        {{ $t("addturn.problenyi") }}
      </div>
      <!-- <div class="problen" v-if="parseFloat(Atividade)===1">A atividade da plataforma giratória está ativada</div> -->
      <div class="problens" v-if="parseFloat(Atividade) === 1">
        {{ $t("addturn.problener") }}
      </div>
      <div class="problen" v-if="parseFloat(Atividade) === 3">
        {{ $t("addturn.problensan") }} {{ newjf }}
        {{ $t("addturn.newproblensan") }}
      </div>
      <div class="problen" v-if="parseFloat(Atividade) === 4">
        {{ $t("addturn.problensi") }} {{ newye }}
        {{ $t("addturn.newproblensi") }}
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div>
          <van-swipe
            class="my-swipe"
            :autoplay="2500"
            :touchable="false"
            indicator-color="white"
            :show-indicators="false"
          >
            <van-swipe-item
              style="
                text-align: center !important;
                background-color: #b94a1a;
                color: #fff;
                height: 3rem;
                line-height: 3rem;
              "
              v-for="(i, v) in swipelist"
              :key="v"
            >
              {{ i }}
            </van-swipe-item>
          </van-swipe>
        </div>

        <!-- <div class="zhuanpan">
          <div class="neirong">
            <img ref="neirong" src="../assets/neirong.png" alt="">
          </div>
          <div class="kaishi" @click="kaishizhuanpan">
            <img src="../assets/kais.png" alt="">
          </div>
          <div class="zhizhen">
            <img src="../assets/zhizhen.png" alt="">
          </div>
        </div> -->
        <!-- 转盘抽奖 -->
        <div class="box">
          <LuckyGrid
            ref="myLucky"
            width="33rem"
            height="33rem"
            :prizes="prizes"
            :blocks="blocks"
            :buttons="buttons"
            @start="startCallback"
            @end="endCallback"
          ></LuckyGrid>
        </div>
        <div class="top">
          <div class="topbox">
            <div class="points">
              {{ $t("addturn.topboxyi") }}: {{ integrante }}
            </div>
            <div class="Saldo">{{ $t("addturn.topboxer") }}: {{ member }}</div>
            <div class="Saldo">{{ $t("addturn.topboxsan") }}: {{ count }}</div>
          </div>
          <div class="giftimg" ref="giftimg">
            <img src="../assets/gift.6cfa3773.png" alt="" />
          </div>
        </div>
        <!-- <div style="padding: 1.3rem;">O sorteio da loteria giratória consumirá os pontos primeiro e mudará automaticamente para o saldo quando os pontos forem insuficientes</div> -->
      </div>
      <div class="record-box">
        <div class="record-title">{{$t('addturn.btn')}}</div>
        <div class="record">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('Mypurse.Theres')"
            :loading-text="$t('Mypurse.Loading')"
            @load="onLoad"
          >

            <div class="Bitcoin" v-for="(i, v) in yeblist" :key="v">
              <div class="b-c">
                <div class="Bitcoin-left">
                  <div class="Bitcoin-name">
                    <div style="color: #e8a622">
                      {{ $t("addturn.Bitcoin") }}{{ i.cost }}
                    </div>
                    <div class="btc">{{ i.createtime }}</div>
                  </div>
                </div>
                <div class="Bitcoin-right">
                  <div> {{ i.name }}</div>
                </div>
              </div>

            </div>
          </van-list>
        </div>
      </div>
    </div>
    <!-- <div class="button">
      <div class="btn" @click="$router.push('/lotteryrecord')">{{$t('addturn.btn')}}</div>
    </div> -->
  </div>
</template>

<script>
import {
  turntablelistapi,
  Turntablestartapi,
  turntablerecordapi,
} from "@/api/index";
import { Toast } from "vant";
export default {
  name: "turnTable",
  data() {
    return {
      time: null,
      time1: null,
      time2: null,
      start: true,
      blocks: [
        {
          padding: "2.8rem",
          imgs: [
            {
              src: require("../assets/cjbg.png"),
              width: "100%",
              height: "100%",
            },
          ],
        },
        // { padding: '10px', background: '#5b2da1' },
      ],
      prizes: [
        {
          x: 0,
          y: 0,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          background: "Gold",
          fonts: [
            {
              fontSize: "1rem",
              text: "",
              top: "66%",
            },
          ],
        },
        {
          x: 1,
          y: 0,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              text: "",
              top: "66%",
            },
          ],
        },
        {
          x: 2,
          y: 0,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              top: "66%",
              text: "",
            },
          ],
        },
        {
          x: 2,
          y: 1,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              text: "",
              top: "66%",
              fontSize: "1rem",
            },
          ],
        },
        {
          x: 2,
          y: 2,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              top: "66%",
              text: "",
            },
          ],
        },
        {
          x: 1,
          y: 2,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              top: "66%",
              text: "",
            },
          ],
        },
        {
          x: 0,
          y: 2,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              top: "66%",
              text: "",
            },
          ],
        },
        {
          x: 0,
          y: 1,
          imgs: [
            {
              src: require("../assets/cjitem.png"),
              width: "100%",
              height: "100%",
              activeSrc: require("../assets/cjbtn.png"),
            },
          ],
          fonts: [
            {
              fontSize: "1rem",
              top: "66%",
              text: "",
            },
          ],
        },
      ],
      buttons: [
        {
          x: 1,
          y: 1,
          imgs: [
            {
              src: require("../assets/cjbtns.png"),
               // src: require("../assets/cjbtn.png"),
              width: "100%",
              height: "100%",

            },
          ],
          fonts:[
            {
              text:'',
              top:'28px',
              fontSize:'30px'
            },
          ]

        },
      ],
      defaultStyle: {
        background: "#b8c5f2",
      },
      integrante: 0, // 抽奖积分
      list: [],
      starttime: 0,
      id: "", // 奖品id
      play_score: "",
      startgame: 1,
      Atividade: "", // 活动开始或关闭
      member: "", // 账户可用余额
      lotterytype: 1, // 抽奖类型判断是积分抽奖还是余额抽奖
      robot: "", // 判断有没有买机器人
      jifen: "", // 积分抽奖
      balance: "", // 余额抽奖
      count: "", // 抽奖次数
      swipelist: [],
      newjf: "",
      newye: "",
      historyParams: {
        page: 1,
        limit: 30,
      },
      yeblist: [],
      isLoading: false,
      loading: false,
      finished: false,
    };
  },
  async created() {
    await this.turntablelist();
  },
  mounted() {
    // this.giftimg();
  },
  destroyed() {
    clearInterval(this.time);
    clearInterval(this.time1);
    clearInterval(this.time2);
  },
  methods: {
    async onLoad () {
      this.loading = true
      await this.turntablelist1()
      // this.historyParams.page++
      this.loading = false
      // this.list.push(...res.data.data)
      this.finished = true

    },

    async turntablelist1() {
      const res = await turntablerecordapi(this.historyParams);
      if(res.data.last_page <= this.historyParams.page) {
        this.finished = true
      }

      this.yeblist.push(...res.data.data)
    },
    // 获取是否开启
    async startCallback() {
      const res = await turntablelistapi();

      this.jifen = res.data.score_on;
      this.balance = res.data.balance_on;
      this.addchoujiang();
    },
    async addchoujiang() {
      console.log(this.newjf, "jf");
      console.log(this.newye, "ye");
      console.log(this.member);
      // await this.$refs.myLucky.play();
      // this.$refs.myLucky.stop(Math.floor(Math.random() * 8));
      if(this.start == false){
        return;
      }
      // return;
      if (this.Atividade && this.starttime > 0) {
        // 活动开启状态
        if (parseFloat(this.robot) === 1) {
          // 判断有没有购买机器人
          if (parseFloat(this.balance) === 1 && parseFloat(this.jifen) === 1) {
            // 积分抽奖和余额抽奖都开启状态
            if (this.lotterytype === 1) {
              // 优先消耗积分
              if (this.integrante >= this.newjf) {
                if (this.startgame === 1) {
                  this.startgame = 2;
                  // if (this.integrante === 0 || this.integrante < 10) {
                  //   // console.log(1)
                  //   Toast('积分不足')
                  // } else {
                  // this.integrante -= 10
                  // 调用抽奖组件的play方法开始游戏
                  await this.$refs.myLucky.play();
                  this.start = false
                  // 模拟调用接口异步抽奖
                  await this.Turntablestart(1);

                  // 假设后端返回的中奖索引是0
                  // console.log(index)
                  // 调用stop停止旋转并传递中奖索引
                  // console.log('2133', this.id)
                  await this.$refs.myLucky.stop(this.id);
                  await setTimeout(() => {
                    this.turntablelist();
                    this.startgame = 1;
                  }, 6000);
                }
              } else {
                Toast({
                  message:
                    this.$t("addturn.tsyi") +
                    this.newjf +
                    this.$t("addturn.newtsyi"),
                  duration: 1200,
                  // className: 'noticeWidth' // 所加样式名
                });
                this.lotterytype = 2;
              }
            } else {
              if (parseFloat(this.member) >= parseFloat(this.newye)) {
                if (this.integrante >= this.newjf) {
                  this.lotterytype = 1;
                  this.addchoujiang();
                  return;
                }
                if (this.startgame === 1) {
                  this.startgame = 2;
                  // if (this.integrante === 0 || this.integrante < 10) {
                  //   // console.log(1)
                  //   Toast('积分不足')
                  // } else {
                  // this.integrante -= 10

                  // 调用抽奖组件的play方法开始游戏
                  await this.$refs.myLucky.play();
                  this.start = false
                  // 模拟调用接口异步抽奖
                  await this.Turntablestart(2);

                  // 假设后端返回的中奖索引是0
                  // console.log(index)
                  // 调用stop停止旋转并传递中奖索引
                  // console.log('45676', this.id)
                  await this.$refs.myLucky.stop(this.id);
                  // this.lotterytype = 1
                  await setTimeout(() => {
                    this.turntablelist();
                    this.startgame = 1;
                  }, 6000);
                }
              } else {
                Toast({
                  message:
                    this.$t("addturn.tser") +
                    this.newye +
                    this.$t("addturn.newtser"),
                  duration: 1200,
                  // className: 'noticeWidth' // 所加样式名
                });
              }
            }
          } else if (parseFloat(this.jifen) === 1) {
            // 积分抽奖开启状态
            if (this.integrante >= this.newjf) {
              if (this.startgame === 1) {
                this.startgame = 2;
                // if (this.integrante === 0 || this.integrante < 10) {
                //   // console.log(1)
                //   Toast('积分不足')
                // } else {
                // this.integrante -= 10
                // 调用抽奖组件的play方法开始游戏
                await this.$refs.myLucky.play();
                this.start = false
                // 模拟调用接口异步抽奖
                await this.Turntablestart(1);

                // 假设后端返回的中奖索引是0
                // console.log(index)
                // 调用stop停止旋转并传递中奖索引
                // console.log('2133', this.id)
                await this.$refs.myLucky.stop(this.id);
                await setTimeout(() => {
                  this.turntablelist();
                  this.startgame = 1;
                }, 6000);
              }
            } else {
              Toast({
                message:
                  this.$t("addturn.tssan") +
                  this.newjf +
                  this.$t("addturn.newtssan"),
                duration: 1200,
                // className: 'noticeWidth' // 所加样式名
              });
            }
          } else if (parseFloat(this.balance) === 1) {
            // 余额抽奖开启状态
            if (this.member >= this.newye) {
              if (this.startgame === 1) {
                this.startgame = 2;
                // if (this.integrante === 0 || this.integrante < 10) {
                //   // console.log(1)
                //   Toast('积分不足')
                // } else {
                // this.integrante -= 10

                // 调用抽奖组件的play方法开始游戏
                await this.$refs.myLucky.play();
                this.start = false
                // 模拟调用接口异步抽奖
                await this.Turntablestart(2);

                // 假设后端返回的中奖索引是0
                // console.log(index)
                // 调用stop停止旋转并传递中奖索引
                // console.log('45676', this.id)
                await this.$refs.myLucky.stop(this.id);
                // this.lotterytype = 1
                await setTimeout(() => {
                  this.turntablelist();
                  this.startgame = 1;
                }, 6000);
              }
            } else {
              Toast({
                message:
                  this.$t("addturn.tssi") +
                  this.newye +
                  this.$t("addturn.newtssi"),
                duration: 1200,
                // className: 'noticeWidth' // 所加样式名
              });
            }
          } else {
            Toast({
              message: this.$t("addturn.tswu"),
              duration: 1200,
              // className: 'noticeWidth' // 所加样式名
            });
          }
        } else {
          Toast({
            message: this.$t("addturn.tsliu"),
            duration: 1200,
            // className: 'noticeWidth' // 所加样式名
          });
        }
      } else {
        // console.log(111)
        Toast({
          message: this.$t("addturn.tsqi"),
          duration: 1200,
          // className: 'noticeWidth' // 所加样式名
        });
      }
    },
    async turntablelist() {
      const res = await turntablelistapi();

      // if (res.data.member_score >= 20) {
      //   this.lotterytype = 1
      // } else {
      //   this.lotterytype = 2
      // }

      let list = this.prizes;
      let newlist = list.map((item, index) => {
        item.imgs[0].src = res.data.prize_list[index].image;
        // item.imgs[0].src = require("../assets/Venus.png");
        item.fonts[0].text = res.data.prize_list[index].name;
        return item;
      });

      this.prizes = newlist;

      this.newjf = res.data.play_score;
      this.newye = res.data.play_score_yue;

      this.list = res.data.lucky_history;
      this.integrante = res.data.member_score;
      this.starttime = res.data.countdown * 1000;
      // console.log(this.starttime);
      this.play_score = res.data.play_score;
      this.Atividade = res.data.is_on;
      this.robot = res.data.miner_on;
      this.count = res.data.count;
      this.jifen = res.data.score_on;
      this.balance = res.data.balance_on;
      this.swipelist = res.data.notice;
      if (parseFloat(this.jifen) === 1 && parseFloat(this.balance) === 1) {
        // console.log('111', this.jifen)
        // console.log('222', this.balance)
        this.Atividade = 1;
      } else if (
        parseFloat(this.jifen) === 1 ||
        parseFloat(this.balance) === 1
      ) {
        // 判断是积分或是余额开启
        if (parseFloat(this.jifen) === 1) {
          // 积分抽奖开启
          // console.log('333', this.jifen)
          this.Atividade = 3;
        } else if (parseFloat(this.balance) === 1) {
          // 余额抽奖开启
          this.Atividade = 4;
          // console.log('444', this.balance)
        }
      }
      // console.log(this.Atividade)
      this.member = parseFloat(res.data.member_balance).toFixed(2);
      // res.data.prize_list.forEach((i, index) => {
      //   // console.log(i.name)
      //   this.prizes[index].fonts[0].text = i.name;
      //   if (i.image) {
      //     this.prizes[index].imgs[0].src = i.image;
      //   } else {
      //     this.prizes[index].imgs[0].src = require("../assets/Venus.png");
      //   }
      // });
    },
    // giftimg() {
    //   this.time = setInterval(() => {
    //     this.time1 = setTimeout(() => {
    //       this.$refs.giftimg.style.transform = "scale(1.3)";
    //     }, 600);
    //     this.time2 = setTimeout(() => {
    //       this.$refs.giftimg.style.transform = "scale(1)";
    //     }, 800);
    //   }, 800);
    // },
    // 点击抽奖按钮会触发star回调
    // async startCallback () {
    //   // this.jifen = ''
    //   // this.balance = ''
    //   // await this.turntablelist()
    //   this.getinfo()

    //   // }
    // },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(1111)
      this.yeblist = []
      this.turntablelist1()
      // console.log(prize)
      setTimeout(() => {
        this.$refs.myLucky.init();
        this.start = true
      }, 6000);

    },
    async Turntablestart(type) {
      const data = {
        cost: type,
      };
      const res = await Turntablestartapi(data);
      console.log(res, "123");

      if (res.code === 222) {
        setTimeout(() => {
          Toast({
            message: res.msg,
            duration: 1200,
            // className: 'noticeWidth' // 所加样式名
          });
        }, 5000);
        return;
      }
      // console.log(this.id)
      if (res.code === 1) {
        this.id = parseFloat(res.data.won_id) - 1;
        setTimeout(() => {
          Toast({
            message: res.msg,
            duration: 1200,
            // className: 'noticeWidth' // 所加样式名
          });
        }, 5000);
      } else {
        this.$refs.myLucky.init();
        Toast({
          message: res.msg,
          duration: 1200,
          // className: 'noticeWidth' // 所加样式名
        });
        // this.$router.go(0)
      }
    },
  },
};
</script>

<style lang="less" scoped>
.turntable {
  position: relative;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  background: url("../assets/beijings.png") no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2.5rem;
  .turntable-title {
    padding-top: 2.5rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .van-icon-arrow-left:before {
    margin-left: 1.6rem;
  }
  .remainingbox {
    width: 90%;
    height: 12rem;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .remaining {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      padding-left: 1rem;
      .remaining-img {
        width: 4rem;
        height: 4rem;
        margin-left: 1rem;
      }
      .start-time {
        width: 100%;
        // margin-left: 1rem;
        font-size: 1.7rem;
        color: #67beaf;
        font-weight: 800;
        text-align: center;
      }
    }
    .problen,
    .problens {
      width: 70%;
      // height: 4rem;
      text-align: center;
      margin-left: 50%;
      transform: translateX(-50%);
      // line-height: 4rem;
      color: rgb(250, 152, 3);
      border-radius: 1rem;
      font-size: 1.2rem;
      // font-weight: 700;
    }
    .problens {
      width: 100%;
      padding: 0rem 1.5rem;
      margin-top: -0.8rem;
    }
  }
  .content {
    width: 100%;
    // height: 46.2rem;
    // background: linear-gradient(rgb(155, 233, 211), rgb(66, 93, 88));
    margin-top: 20rem;
    padding: 0 1.5rem;
    .content-box {
      width: 100%;
      height: 100%;
      border: 0.6rem solid transparent;
      border-radius: 1.5rem;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      .top {
        margin-top: 3rem;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .topbox {
          // display: flex;
          .points {
            padding: 0.4rem;
            // height: 2.2rem;
            font-size: 1.3rem;
            // background-color: #b94a1a;
            color: #fff;
            border-radius: 0.5rem;
            border: 1px solid #6719ff;
          }
          .Saldo {
            padding: 0.5rem;
            // height: 2.2rem;
            font-size: 1.3rem;
            // background-color: #b94a1a;
            color: #fff;
            border-radius: 0.5rem;
            margin-top: 0.6rem;
            margin-bottom: 0.5rem;
            border: 1px solid #6719ff;
          }
        }
        .giftimg {
          width: 3rem;
          height: 3rem;
          transition: 0.6s all ease-out;
        }
      }
      .zhuanpan {
        width: 72%;
        height: 29rem;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: -0.5rem;
        background-image: url("../assets/zhuanpan.png");
        background-size: 100% 100%;
        position: relative;
        .neirong {
          width: 79%;
          margin-left: 50%;
          transform: translateX(-50%);
          // margin-top: 2.5rem;
          img {
            margin-top: 2.6rem;
            transition: 3s all;
          }
        }
        .kaishi {
          width: 6rem;
          height: 6rem;
          position: absolute;
          top: 9.5rem;
          left: 50%;
          transform: translate(-50%);
        }
        .zhizhen {
          width: 4rem;
          position: absolute;
          top: 0rem;
          left: 50%;
          transform: translate(-50%);
        }
      }

      .Lucky {
        width: 30rem;
        height: 30rem;
        margin: 0 auto;
        margin-top: -3rem;
      }
      .box {
        position: relative;
        display: flex;
        justify-content: center;
      }
      .zhizhen {
        position: absolute;
        top: 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 4rem;
        height: 6rem;
        z-index: 999;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }

    .record-box {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      background-image: url('../assets/jl.png');
      background-size: 100% 100%;
      padding: 3rem 1.2rem;
      margin-top: 2.5rem;
      min-height: 43rem;
      width: 100%;
      // background: linear-gradient(180deg, #2b036a 0%, #06010d 100%);
      // border-radius: 2rem;
    }

    .record-title {
      color: #000;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
    }

    .record {
      flex: 1;
    }
  }
  .button {
    width: 100%;
    // height: 5rem;
    margin-top: -1rem;
    background: linear-gradient(rgb(66, 93, 88), rgb(16, 51, 44));
    padding: 1rem;
    .btn {
      width: 100%;
      height: 4.4rem;
      background: #00967d;
      border: 3px solid #51c4b2;
      margin: 0.26667rem 0.26667rem 0.4rem;
      border-radius: 0.8rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
    }
    ::v-deep .my-swipe {
      // width: 30rem;
      margin: 0 auto;
    }
    ::v-deep .van-swipe-item {
      color: #fff !important;
      font-size: 1.3rem;
      margin: 1rem 0rem;
      width: 30rem;
      height: 4rem;
      // line-height: 2rem;
      text-align: center !important;
      // background-color: #39a9ed;
    }
  }
}

.Bitcoin {
    width: 100%;
    // background-color: #2b2b2b;
    border-radius: 1rem;
    margin-top: .5rem;
    color: #000;
    padding: .7rem 1.5rem .5rem 1.5rem;
    // align-items: center;
    .b-c{
      padding-top: 1.2rem;
      border-top: 1px solid #D9D9D9;
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
       .Bitcoin-left {
         display: flex;
         .Bitcoin-img {
           width: 2.84rem;
           height: 2.84rem;
           margin-right: 1rem;
           margin-top: 0.5rem;
         }
         .Bitcoin-name {
           .btc {
             margin-top: 1rem;
             font-size: 1rem;
           }
         }
       }
       .Bitcoin-right {
       //   height: 1rem;
       //   margin-bottom: 1rem;
       width: 40%;
       text-align: center;
       // text-align: right;
         color: #fc5531;
       }
    }
  }
</style>
