<template>
  <!-- 绑定飞机 -->
  <div class="Phonenumber">
    <div class="Phonenumber-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
         @click="$router.push('/install')"/>
      <div class="title">{{$t('addmy.iboyi')}}</div>
      <div></div>
    </div>
   <div v-if="show">
    <div v-if="!conta">
      <div>
        <div class="Email">{{$t('addmy.appyi')}}</div>
        <input type="number" v-model="value" class="input">
      </div>
      <div class="submit">
        <van-button round block type="info" @click="bangding">{{$t('Phonenumber.Determine')}}</van-button>
      </div>
   </div>
    <div v-else>
    <div class="chneggong">
        <img src="../assets/chengong.png" alt="">
    </div>
    <div class="successfully">{{$t('addmy.apper')}}</div>
    <div class="info">
        <div>{{$t('addmy.appsan')}} </div>
        <div>{{conta}}</div>
    </div>
      <div class="btn" @click="tishi">{{$t('addmy.appsi')}}</div>
    </div>
   </div>
   <div class="botImg">
     <img src="../assets/homeBg.png" alt="" class="img"/>
   </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { myuserapi, bangdingapi } from '@/api/index'
export default {
  name: 'PhoneNumber',
  data () {
    return {
      value: '',
      conta: '',
      show: false
    }
  },
  created () {
    this.myuser()
  },
  methods: {
    async myuser () {
      const res = await myuserapi()
      // console.log('111', res)
      this.conta = res.data.user.whatsapp
      this.show = true
    },
    async bangding () {
      const data = {
        field: 'whatsapp',
        value: this.value
      }
      const res = await bangdingapi(data)
      // console.log(res)
      Toast({
        message: res.msg,
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
      if (parseFloat(res.code) === 1) {
        this.value = this.conta
        setTimeout(() => {
          this.$router.push('/install')
        }, 2000)
      }
    },
    tishi () {
      Toast({
        message: this.$t('addmy.appts'),
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Phonenumber {
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  // background-size: cover;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .botImg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16rem;
    .img{
      width: 100%;
      height: 16rem;
    }
  }
  .Phonenumber-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;
    .title {
      color: #000;
      font-size: 1.8rem;
      // margin-left: 9rem;
      font-weight: 700;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;
    }
  }
  .input {
    margin-top: 1rem;
    width: 100%;
    height: 5rem;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 3px 6px 1px rgba(89,121,251,0.2);
    border-radius: 1rem;
    // margin-top: 2rem;
    color: #333;
    padding-left: 2rem;
     padding-right: 2rem;
    font-size: 1.3rem;
  }
  ::v-deep .van-button {
    border-radius: 1rem;
    height: 4.4rem;
  }
  ::v-deep .van-button--info {
    background-color: #FE6719;
    border: none;
    font-size: 1.4rem;
  }
  ::v-deep .van-field__error-message {
    font-size: 1.4rem;
  }
  .Email {
    color: #fff;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  .submit {
    margin-top: 15rem;
  }
  .bottom {
    width: 100%;
    height: 4.4rem;
    background-color: #15b4fb;
    text-align: center;
    line-height: 4.4rem;
    // margin-top: 11rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;
  }
  .chneggong {
    width: 8.4rem;
    height: 8.8rem;
    margin: 0 auto;
    margin-top: 5rem;
  }
  .successfully {
    color: #858585;
    text-align: center;
    margin-top: 2rem;
    font-size: 1.3rem;
  }
  .info {
    display: flex;
    color: #333;
    margin-left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    margin-top: 2rem;
    font-size: 1.6rem;
  }
  .btn {
    width: 80%;
    height: 4.4rem;
    background-color: #5979FB;
    text-align: center;
    line-height: 4.4rem;
    margin: 0 auto;
    margin-top: 15rem;
    border-radius: 2.75rem;
    font-size: 1.6rem;
    color: #fff;

  }
}
</style>
