<template>
  <div class="EvaLuate">
    <div class="Mypurse-title">
      <van-icon
        @click="$router.push('/receive')"
        name="arrow-left"
        size="2rem"/>
      <div class="title">{{$t('evaluate.one')}}</div>
      <div class="img">
        <img src="" alt="" />
      </div>
    </div>
    <!-- 标签页内容-->
    <van-tabs v-model="active">
      <van-tab :title="$t('evaluate.tabone')">
        <div class="onebody">
          <div class="body" v-for="(item,index) in msglist" :key="index">
            <div class="one">
              <div class="oneleft">
                <img src="../assets/etwo.png" />
                <span>{{item.username}}</span>
              </div>

            </div>
            <div class="three">
              <div v-html="item.content"></div>
            </div>
            <div class="two" v-if="item.imgs != ''">
              <div v-for="(value,i) in item.imgs" :key="i">
                <img :src="value" @click="bigimg(value)" />
              </div>
            </div>
            <div class="oneright">{{item.createtime}}</div>
          </div>
        </div>
      </van-tab>
      <van-tab :title="$t('evaluate.tabtwo')">
        <div class="erbody">
          <div class="er">
            <div class="top">
              <div class="topyi">
                <img src="../assets/etwoyi.png" />
                <span>{{$t('evaluate.imgone')}}</span>
              </div>
              <div class="toper">
                <van-field
                  v-model="data.content"
                  rows="2"
                  autosize
                  type="textarea"
                  maxlength="250"
                  :placeholder="$t('evaluate.input')"
                  show-word-limit
                />
              </div>
            </div>
            <div class="bottom">
              <div class="topyi">
                <img src="../assets/etwoer.png" />
                <span>{{$t('evaluate.imgtwo')}}</span>
              </div>
              <div class="bottomimg">
                <van-uploader v-model="fileList"
                multiple :max-count="6"
                :before-read="afterRead"
                :deletable="false" />
              </div>
            </div>
          </div>
          <div class="erbtn" @click="addmsg">Confirmar</div>
        </div>
      </van-tab>
      <!-- <van-tab title="标签 3">内容 3</van-tab>
      <van-tab title="标签 4">内容 4</van-tab> -->
    </van-tabs>
    <!-- 图片放大-->
    <van-overlay :show="imgshow" z-index="999">
      <div class="wrapper" @click="imgshow = false">
        <img :src="imgvalue" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { yhxx, Uploadpicturesapi, add_msg } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'EvaLuate',
  data () {
    return {
      active: 0,
      msglist: [], // 信息列表
      fileList: [],
      filesarr: [],
      data: {
        content: '',
        url: ''
      },
      imgshow: false,
      imgvalue: ''
    }
  },
  created () {
    this.getyhxx()
  },
  methods: {
    getyhxx () {
      yhxx().then(res=>{
        // console.log(res, '用户信息')
        this.msglist = res.data
        // this.msglist[0].imgs = [] // 测试为空的图片数组
      })
    },
    async afterRead (file) { // 上传图片
      // console.log(file)
      const formData = new FormData()
      formData.append('file', file)
      // // 自定义加载图标
      Toast.loading({
        forbidClick: true,
        loadingType: 'spinner',
        duration: 1500
      })
      const res = await Uploadpicturesapi(formData)
      // console.log(res)
      this.filesarr.push(res.data.url)
      // console.log(this.fileList, '111')
    },
    addmsg () { // 上传
      this.data.url = this.filesarr.join(',')
      if (this.data.content == '') {
        Toast({
          message: this.$t('evaluate.kong'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      // console.log(this.data, '111')
      add_msg(this.data).then(res=>{
        console.log(res, 'res')
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        if(res.code == 1){
          this.active = 0
          this.getyhxx()
        }
      })
    },
    bigimg (value) { // 放大图片
      this.imgvalue = value
      this.imgshow = true
    }
  }
}
</script>

<style scoped lang="less">
.EvaLuate{
  background-color: #F4F6F8;
  min-height: 100vh;
  padding-bottom: 5rem;
  .Mypurse-title {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FE6719;
    color: #fff;
    .title {
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  ::v-deep .van-tabs__line{
    background-color: #FE6719;
  }
  .onebody{
    margin: 0 2rem;
    .body{
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      padding: 1.5rem;
      margin-top: 2rem;
      .one{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.17rem solid #F6F6F6;
        padding-bottom: 1rem;
        font-size: 1.3rem;
        .oneleft{
          display: flex;
          align-items: center;
          img{
            width: 2rem;
            height: 2rem;
            margin-right: .5rem;
          }
          span{
            font-weight: bold;
          }
        }

      }
      .oneright{
        padding-top: 1rem;
        border-top: 1px solid #F6F6F6;
        text-align: right;
        color: #A5A5A5;
      }
      .two{
        display: flex;
        flex-wrap: wrap;
        margin-top: 0rem;
        margin-bottom: 2rem;
        div{
          width: 6.33rem;
          height: 6.33rem;
          // background: #333333;
          border-radius: 0.83rem 0.83rem 0.83rem 0.83rem;
          margin-right: 1rem;
          margin-top: 1rem;
          img{
            width: 100%;
            height: 100%;
            border-radius: 0.83rem 0.83rem 0.83rem 0.83rem;
          }
        }
      }
      .three{
        margin: 2rem 0 1.5rem  0;
        font-weight: 400;
        font-size: 1.3rem;
        color: #A5A5A5;
      }
    }
  }
  /* er*/
  .erbody{
    margin: 0 2rem;
    margin-top: 2rem;
    .er{
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      padding: 1.5rem;
      .top{
        border-bottom: 2px solid #F6F6F6;
        padding-bottom: 1rem;
        .topyi{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          img{
            width: 2rem;
            height: 2rem;
            margin-right: .5rem;
          }
        }
      }
      .bottom{
        margin-top: 1.5rem;
        .topyi{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          img{
            width: 2rem;
            height: 2rem;
            margin-right: .5rem;
          }
        }
        .bottomimg{
          margin-top: 1.5rem;
        }
      }
    }
    .erbtn{
      background: #FE6719;
      box-shadow: 0px 3px 6px 1px rgba(21,180,251,0.2);
      border-radius: 55px 55px 55px 55px;
      color: #fff;
      text-align: center;
      padding: 1.2rem 0;
      font-size: 18px;
      margin-top: 4rem;
    }
  }
  .wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img{
      width: 100%;
    }
  }
}
</style>
