<template>
  <!-- 银行卡充值 -->
  <div class="Video">
    <div class="Video-title">
      <van-icon
        @click="$router.back()"
        name="arrow-left"
        size="2rem"
        color="#000"
      />
      <div class="title">{{$t('addbank.banktitle')}}</div>
      <div></div>
    </div>
    <div class="amount">{{$t('addbank.banktitlevalue')}}</div>
    <div class="input">
      <input
        class="inputbox"
        type="text"
        v-model="price"
        oninput="if(this.value.length > 6 ) this.value = this.value.slice(0,6); value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
        @blur="blurinput"/>
      <div class="yuedeng">≈ {{ price }} USDT</div>
    </div>
    <div class="money">
      <div :class="[number === 15 ? 'number' : 'numbers']" @click="numbermoney(15)">15</div>
      <div :class="[number === 30 ? 'number' : 'numbers']" @click="numbermoney(30)">30</div>
      <div :class="[number === 50 ? 'number' : 'numbers']" @click="numbermoney(50)">50</div>
      <div :class="[number === 100 ? 'number' : 'numbers']" @click="numbermoney(100)">100</div>
      <div :class="[number === 500 ? 'number' : 'numbers']" @click="numbermoney(500)">500</div>
      <div :class="[number === 1000 ? 'number' : 'numbers']" @click="numbermoney(1000)">1000</div>
      <div :class="[number === 2000 ? 'number' : 'numbers']" @click="numbermoney(2000)">2000</div>
      <div :class="[number === 5000 ? 'number' : 'numbers']" @click="numbermoney(5000)">5000</div>
      <div :class="[number === 10000 ? 'number' : 'numbers']" @click="numbermoney(10000)">10000</div>
    </div>
    <div class="Recharge" @click="Recharge">{{$t('addbank.bankbtn')}}</div>
    <div class="bottom-title">* {{ $t("Charge.reminder") }}</div>
    <p>1.{{ $t("Charge.assets") }}.</p>
    <p>2.{{ $t("Charge.amount") }}.</p>
  </div>
</template>

<script>
import { geturl,postoffline } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'AddRess',
  data () {
    return {
      price: 0,
      number: 12,
      entrance: '',
      url: '',
      id: '',
      isloading: 1, // 防爆
      bankurl: '', // 请求充值通道的URL
      min: '', // 充值最小金额
      type: ''
    }
  },
  created () {
    // this.Videointroduction()
    this.entrance = this.$route.query.entrance
    this.id = this.$route.query.id
    this.bankurl = this.$route.query.url
    this.min = this.$route.query.min
    this.type = this.$route.query.type
    console.log(this.type)
    // console.log(this.min)
    console.log(this.entrance)
    // console.log(this.id)
  },
  methods: {
    blurinput () {
      if (this.price === '') {
        this.price = 0
      }
    },
    numbermoney (number) {
      // console.log(number)
      if (number === 15) {
        this.number = number
        this.price = number
      } else if (number === 30) {
        this.number = number
        this.price = number
      } else if (number === 50) {
        this.number = number
        this.price = number
      } else if (number === 100) {
        this.number = number
        this.price = number
      } else if (number === 500) {
        this.number = number
        this.price = number
      } else if (number === 1000) {
        this.number = number
        this.price = number
      } else if (number === 2000) {
        this.number = number
        this.price = number
      } else if (number === 5000) {
        this.number = number
        this.price = number
      } else if (number === 10000) {
        this.number = number
        this.price = number
      }
    },
    async Recharge () {
      // this.$router.push({
      //   path: '/RechargeTwo'})
      if(this.type == 2){ // eslint-disable-line
        const data = {
          pay_id: this.id,
          money: this.price,
          entrance: this.entrance || 'mining'
        }
        const res = await geturl(this.bankurl, data)
        window.location.href = res.data.payURL
        return false
      }
      // console.log(this.price, this.min)
      if (parseFloat(this.price) === 0) {
        Toast({
          message: this.$t('addbank.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      if (parseFloat(this.price) < parseFloat(this.min)) {
        Toast({
          message: this.$t('addbank.tser') + this.min,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      } else {
        if (this.id) { // 银行卡充值
          // if (parseFloat(this.price) >= this.min) {
          if (this.isloading === 1) {
            this.isloading = 0
            const data = {
              money: this.price,
              entrance: this.entrance || 'mining',
              pay_id: parseFloat(this.id),
              type: 1
            }
            console.log(data)
            const res = await postoffline(data)
            if( res.code == 1){
              this.$router.push({
                path: '/RechargeTwo',
                query: {
                  money: this.price,
                  entrance:this.entrance || 'mining',
                  pay_id: parseFloat(this.id),
                  order_no: res.data.order_no
                }
              })
            } else {
              Toast({
                message: res.msg,
                duration: 1200,
                className: 'noticeWidth' // 所加样式名
              })
              return false
            }
            // // console.log(this.bankurl)
            // const res = await bankapi(data, this.bankurl)
            // // console.log(111)
            // // console.log(res)
            // Toast({
            //   message: res.msg,
            //   duration: 1200,
            //   className: 'noticeWidth' // 所加样式名
            // })
            // if (parseFloat(res.code) === 1) {
            //   this.url = res.data.payURL
            //   setTimeout(() => {
            //     window.location.href = res.data.payURL
            //   }, 1000)
            // }
            // this.isloading = 1
          }
          // } else {
          //   Toast({
          //     message: `Valor mínimo de recarga ${this.min} `
          //     // className: 'noticeWidth' // 所加样式名
          //   })
          // }
        }
        // if (parseFloat(this.id) === 3) { // 投注猫支付
        //   if (this.isloading === 1) {
        //     this.isloading = 0
        //     const data = {
        //       money: this.price,
        //       entrance: this.entrance,
        //       pay_id: parseFloat(this.id)
        //     }
        //     const res = await bankapi(data, this.bankurl)
        //     // console.log(res)
        //     Toast({
        //       message: res.msg,
        //       duration: 1200,
        //       className: 'noticeWidth' // 所加样式名
        //     })
        //     if (parseFloat(res.code) === 1) {
        //       this.url = res.data.payURL
        //       setTimeout(() => {
        //         window.location.href = res.data.payURL
        //       }, 1000)
        //     }
        //     this.isloading = 1
        //   }
        // }
        // if (parseFloat(this.id) === 4) { // 新通道支付
        //   // console.log(111)
        //   if (this.isloading === 1) {
        //     this.isloading = 0
        //     const data = {
        //       money: this.price,
        //       entrance: this.entrance,
        //       pay_id: parseFloat(this.id)
        //     }
        //     // console.log(this.bankurl)
        //     const res = await bankapi(data, this.bankurl)
        //     // console.log(res)
        //     Toast({
        //       message: res.msg,
        //       duration: 1200,
        //       className: 'noticeWidth' // 所加样式名
        //     })
        //     if (parseFloat(res.code) === 1) {
        //       this.url = res.data.payURL
        //       setTimeout(() => {
        //         window.location.href = res.data.payURL
        //       }, 1000)
        //     }
        //     this.isloading = 1
        //   }
        // }
        // if (parseFloat(this.id) === 5) { // 新通道支付
        //   // console.log(111)
        //   if (this.isloading === 1) {
        //     this.isloading = 0
        //     const data = {
        //       money: this.price,
        //       entrance: this.entrance,
        //       pay_id: parseFloat(this.id)
        //     }
        //     // console.log(this.bankurl)
        //     const res = await bankapi(data, this.bankurl)
        //     // console.log(res)
        //     Toast({
        //       message: res.msg,
        //       duration: 1200,
        //       className: 'noticeWidth' // 所加样式名
        //     })
        //     if (parseFloat(res.code) === 1) {
        //       this.url = res.data.payURL
        //       setTimeout(() => {
        //         window.location.href = res.data.payURL
        //       }, 1000)
        //     }
        //     this.isloading = 1
        //   }
        // }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Video {
  background-color: #fff;
  padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .Video-title {
    margin-bottom: 3rem;
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .amount {
    color: #333;
    font-size: 1.6rem;
    // margin-top: -1rem;
  }
  .input {
    position: relative;
    .inputbox {
      width: 100%;
      height: 5rem;
      border-radius: 1rem;
      border: 1px solid #F6F6F6;
      background-color: #fff;
      margin-top: 2rem;
      color: #333;
      padding: 1rem 2rem;
      font-size: 1.4rem;
    }
    .yuedeng {
      position: absolute;
      top: 3.5rem;
      right: 2rem;
      color: #8D8D8D;
    }
  }
  .money {
    width: 100%;
    height: 3.9rem;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .number {
      width: 20%;
      height: 3.9rem;
      background-color:#FE6719;
      color: #fff;
      border-radius: 1rem;
      text-align: center;
      line-height: 3.9rem;
      font-weight: 600;
      margin-left: 1rem;
      margin-top: 1rem;
    }
    .numbers {
      width: 20%;
      height: 3.9rem;
      border: 2px solid #F6F6F6;
      color: #333;
      border-radius: 1.3rem;
      text-align: center;
      line-height: 3.9rem;
      font-weight: 600;
      margin-left: 1rem;
      margin-top: 1rem;
    }
  }
  .Recharge {
    width: 100%;
    height: 4.4rem;
    background-color: #FE6719;
    color: #fff;
    text-align: center;
    line-height: 4.4rem;
    border-radius: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 13rem;
  }
    .bottom-title {
    font-size: 1.4rem;
    color: #333;
    margin-top: 2rem;
  }
  p {
    color: #8D8D8D;
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
}
</style>
