<template>
  <div class="team">
    <div class="t-title">
      <div class="cont">
        <div class="fx">{{ $t('Gift.txt1') }}</div>
        <div class="ljz">
          <div class="lj">{{shareUrl}}</div>
          <div class="copy"
          v-clipboard:copy="shareUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError" >{{ $t('Gift.txt2') }}</div>
        </div>
        <div class="yq"
        >{{ $t('Gift.txt3') }}</div>
      </div>
    </div>
    <div class="my">
      <div class="my-t">
        {{ $t('Gift.txt4') }}
      </div>
      <div class="my-c">
        <div class="c-item">
          <div class="c-u" style="margin-bottom: 1rem;">
            <div class="c-i">
              <img src="../assets/tou.png" alt="" class="img"/>
            </div>
            <div class="c-t">{{ $t('Gift.txt5') }}：{{tema.team_count}}</div>
          </div>
          <div class="c-u">
            <div class="c-i">
              <img src="../assets/liwu.png" alt="" class="img"/>
            </div>
            <div class="c-t">{{ $t('Gift.txt6') }}：{{tema.team_prize}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="team">
      <div class="team-t">
        {{ $t('Gift.txt7') }}
      </div>
      <div class="team-i">
        <div class="bg"></div>
        <div class="t-item">
          <div class="com">
            <div class="t-t">
              <div class="k"></div>
              <div class="z">{{ $t('Gift.txt8') }}</div>
              <div class="y">{{ $t('Gift.txt9') }}</div>
              <!-- <div class="s">收益最高</div> -->
            </div>
            <div class="t-s" v-for="(item,index) in rankList" :key="index">
              <div class="s1" v-if="index==0"><img src="../assets/1s.png" alt="" class="img"/></div>
              <div class="s1" v-if="index==1"><img src="../assets/2s.png" alt="" class="img"/></div>
              <div class="s1" v-if="index==2"><img src="../assets/3s.png" alt="" class="img"/></div>
              <div class="se">
                <div class="s2">
                  <div class="yuan"> <img :src="item[0].head" alt="" class="img"/> </div>
                  <div class="num">
                    <div id="">ID:{{item[0].user_id}}</div>
                    <div id="">{{item[0].username}}</div>
                  </div>
                </div>
                <div class="s3">
                  <div class="yuan"> <img :src="item[1].head" alt="" class="img"/> </div>
                  <div class="num">
                      <div id="">ID:{{item[1].user_id}}</div>
                      <div id="">{{item[1].username}}</div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="t-s">
              <div class="s1"><img src="../assets/2s.png" alt="" class="img"/></div>
              <div class="se">
                <div class="s2"><div class="yuan"></div><div class="num">ID:123</div></div>
                <div class="s3"><div class="yuan"></div><div class="num">ID:123</div></div>
              </div>
            </div>
            <div class="t-s">
              <div class="s1"><img src="../assets/3s.png" alt="" class="img"/></div>
              <div class="se">
                <div class="s2"><div class="yuan"></div><div class="num">ID:123</div></div>
                <div class="s3"><div class="yuan"></div><div class="num">ID:123</div></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- /弹出层-->
    <TabBar></TabBar>
  </div>
</template>

<script>

import * as echarts from 'echarts'
import { Toast } from 'vant'
import {  getTeamIndex,get_period,getpartyapi, prop, bet, bet_records, period_histroy, get_huobi, get_game_trade,leijibet } from '@/api/index'
 // import clockAnimation from "./jbdh.vue";
export default { // k线图
  name: 'AllreCords',
   // components: {
   //    clockAnimation
   //  },
  data() {
    return {
	   bhvalue:'',
	   bhindex:1,
     shareUrl:'',
		// clockIsShow: true,
		// tcshow:true,
	  codeobj:{},
    tema:'',
    rankList:[],
      kxindex: 0,
      qhindex: 0,
      kxtitle: {}, // k线顶部
      tradedata: { // k线图请求体
        symbol: 'btcusdt',
        period: '1M',
        limit: 15
      },
      klist: [], // k线图内容
      // showPopover: flase,
      actions: [
        { text: '选项一' },
        { text: '选项二' },
        { text: '选项三' }
      ],
      showPopover: false,
      checked: true, // 复选框
      active: 0, // 选项卡1
      qqperiod: { // 获取当前期数
        category : 1
      },
      NumArr: [],
      result: [],
      proplist: [], // 下注分类
      prop_data: {
        type: 1
      },
      periodarr: [], // 当前期数
      prposhow: false, // 下注弹出层
      prpodata: {
        type: '',
        amount: '',
        category: '1'
      },
      prpovalue: 0,
      prpomoney: 0, // 下注金额换算usdt
      timeData: [],
      time: 0,
      timetwo: 0,
      activetwo: 0, // 选项卡2
      activethree: 0, // 选项卡3
      activefour:0, // 选项卡4
      recordsdata:{ // 下注记录-- 待开奖已开奖
        status: 1,
        limit: 5,
        page: 1
      },
      recordslist: [],
      recordstotal: '',
      perioddata: { // 历史记录
        category: 1,
        limit: 5,
        page: 1
      },
      periodtotal: '',
      periodlist: [],
      periodNumber: [],
      timenull: null, // 每隔三秒的请求
      timenuller: null,
      timenullsan: null,
      timenullsi: null,
      periodurl: '/api/Btc/get_period', // 获取当前期数
      prpourl: '/api/base/prop', // 下注列表
      beturl: '/api/Btc/bet', // 下注
      betrecordsurl: '/api/Btc/bet_records', // 下注记录
      periodhistroyurl: '/api/Btc/period_histroy', // 获取历史记录
      gethuobiurl: '/api/huobi/get_huobi_symbol?symbol=btcusdt', // k线图数据title
      getgametradeurl: '/api/huobi/get_game_trade' // k线图数据
    }
  },
  created() {
    // this.gethuobi() // k线图-title
    // this.gettarde() // k线图
    // this.getperiod()
    // this.getprop()
    // this.getrecords() // 下注记录
	// this.leijibet()
    this.toHome()
  },
  mounted() {

  },
  methods: {
    async toHome(){
      // const res = await getpartyapi()
      const res = await getTeamIndex()
      console.log(res)
      this.shareUrl = res.data.share_url
      this.tema = res.data
      this.rankList = res.data.rank
    },
    onError() {
      console.error("复制失败");
    },
    // 复制
    onCopy() {
      // this.$message.success("复制成功");
      Toast({
        message: '复制成功',
        duration: 1200,
        className: 'noticeWidth' // 所加样式名
      })
    },
	  clickoverlay() {
		  this.bhindex = 1
	  },
    onSelect(action){},
    SwitchMode(e) { // 切换模式
      const that = this
      if(e==0) {
        // that.periodurl = '/api/Btc/get_period' // 获取当前期数
        // that.prpourl = '/api/base/prop' // 下注列表
        that.$router.push('/gift')
        that.beturl = '/api/Btc/bet' // 下注
        // that.betrecordsurl = '/api/Btc/bet_records' // 下注记录
        // that.periodhistroyurl = '/api/Btc/period_histroy' // 获取历史记录
        // that.gethuobiurl = '/api/huobi/get_huobi_symbol?symbol=btcusdt' // k线图数据title
        // that.getgametradeurl = '/api/huobi/get_game_trade' // k线图数据
      }else{
        that.$router.push('/gifter')
        that.beturl = '/api/Btc/bet_active' // 包赔下注
      }
      that.qhindex = e
    },
    kxtabar (index) {
      // console.log(index)
      if(index == 0){
        this.tradedata.period = '1M'
        this.gettarde()
      }else if( index == 1){
        this.tradedata.period = '5M'
        this.gettarde()
      }else if( index == 2){
        this.tradedata.period = '30M'
        this.gettarde()
      }else if( index == 3){
        this.tradedata.period = '60M'
        this.gettarde()
      }
      this.kxindex = index
    },
    // k线图数据title
    gethuobi() {
      const url = this.gethuobiurl
      get_huobi(url).then(res=>{
        // console.log(res, 'k线title')
        this.kxtitle = res.data.tick
      })
    },
    // k线图数据
    gettarde() {
      const that = this
      get_game_trade(that.getgametradeurl,that.tradedata).then(res=>{
        // console.log(res, 'k线')
        that.klist = res.data.data
        if(that.kxindex == 0){
          that.initEcharts()
        }else if(that.kxindex == 1){
          that.gettwok()
        }else if(that.kxindex == 2){
          that.getseck()
        }else{
          that.getfourk()
        }
      })
    },
    // k线图--上证指数
    initEcharts() {
      let this_ = this
				let kbox = document.querySelector('.kbox')
				var chartDom = document.querySelector('.fenmain')
				var myChart = echarts.init(chartDom);
				var option;

				// prettier-ignore

				function calculateMA(dayCount, data) {
					var result = [];
					for (var i = 0, len = data.length; i < len; i++) {
						// alert(111)
						if (i < dayCount) {
							result.push('-');
							continue;
						}
						var sum = 0;
						for (var j = 0; j < dayCount; j++) {
							sum += +data[i - j][1];
						}
						result.push(sum / dayCount);
					}
					return result;
				}
				// console.log(this_.klist, 111);
				const dates = this_.klist.map(function(item) {
					var date = new Date(item.id * 1000);
					var Y = date.getFullYear() + '-';
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
					var D = date.getDate() + ' ';
					var h = date.getHours() + ':';
					var m = date.getMinutes() + ':';
					var s = date.getSeconds();
					// var Y = date.getFullYear() + '/';
					// var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
					// var D = date.getDate();
					item.id = Y + M + D + h + m + s
					return item.id;
				});
				const data = this_.klist.map(function(item) {
					return [+item.open, +item.close, +item.low, +item.high];
				});

				option = {
					legend: {
						data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
						inactiveColor: '#777'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							animation: false,
							type: 'cross',
							lineStyle: {
								color: '#376df4',
								width: 2,
								opacity: 1
							}
						}
					},
					xAxis: {
						type: 'category',
						data: dates,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						}
					},
					yAxis: {
						scale: true,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						},
						splitLine: {
							show: false
						}
					},
					grid: {
						bottom: 80,
						left: 3,
						containLabel: true
					},
					dataZoom: [],
					series: [{
							type: 'candlestick',
							name: 'Day',
							data: data,
							itemStyle: {
								color: '#FD1050',
								color0: '#0CF49B',
								borderColor: '#FD1050',
								borderColor0: '#0CF49B'
							}
						},
						{
							name: '',
							type: 'line',
							data: calculateMA(1, data),
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 1
							}
						},
					]
				};
				option && myChart.setOption(option);
    },
    gettwok() {
      let this_ = this
      let kbox = document.querySelector('.rimain')
      var chartDom = kbox
      var myChart = echarts.init(chartDom);
      var option;

      // prettier-ignore

      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.length; i < len; i++) {
          // alert(111)
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          var sum = 0;
          for (var j = 0; j < dayCount; j++) {
            sum += +data[i - j][1];
          }
          result.push(sum / dayCount);
        }
        return result;
      }
      // console.log(this_.klist);
      const dates = this_.klist.map(function(item) {
        var date = new Date(item.id * 1000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        // var Y = date.getFullYear() + '/';
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        // var D = date.getDate();
        item.id = Y + M + D + h + m + s
        return item.id;
      });
      const data = this_.klist.map(function(item) {
        return [+item.high, +item.close, +item.low, +item.high];
      });

      option = {
        legend: {
          data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
          inactiveColor: '#777'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 2,
              opacity: 1
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          }
        },
        yAxis: {
          scale: true,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          },
          splitLine: {
            show: false
          }
        },
        grid: {
          bottom: 80,
          left: 3,
          containLabel: true
        },
        dataZoom: [],
        series: [{
            type: 'candlestick',
            name: 'Day',
            data: data,
            itemStyle: {
              color: '#FD1050',
              color0: '#0CF49B',
              borderColor: '#FD1050',
              borderColor0: '#0CF49B'
            }
          },
          {
            name: '',
            type: 'line',
            data: calculateMA(1, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
    getseck() {
				let this_ = this
				let kbox = document.querySelector('.zhoumain')
				var chartDom = kbox
				var myChart = echarts.init(chartDom);
				var option;
				// prettier-ignore
				function calculateMA(dayCount, data) {
					var result = [];
					for (var i = 0, len = data.length; i < len; i++) {

						if (i < dayCount) {
							result.push('-');
							continue;
						}
						var sum = 0;
						for (var j = 0; j < dayCount; j++) {
							sum += +data[i - j][1];
						}
						result.push(sum / dayCount);
					}
					return result;
				}
				const dates = this_.klist.map(function(item) {
					var date = new Date(item.id * 1000);
					var Y = date.getFullYear() + '-';
					var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
					var D = date.getDate() + ' ';
					var h = date.getHours() + ':';
					var m = date.getMinutes() + ':';
					var s = date.getSeconds();
					// var Y = date.getFullYear() + '/';
					// var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
					// var D = date.getDate();
					item.id = Y + M + D + h + m + s
					return item.id;
				});
				const data = this_.klist.map(function(item) {
					return [+item.high, +item.close, +item.low, +item.high];
				});
				option = {
					legend: {
						data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
						inactiveColor: '#777'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							animation: false,
							type: 'cross',
							lineStyle: {
								color: '#376df4',
								width: 2,
								opacity: 1
							}
						}
					},
					xAxis: {
						type: 'category',
						data: dates,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						}
					},
					yAxis: {
						scale: true,
						axisLine: {
							lineStyle: {
								color: '#8392A5'
							}
						},
						splitLine: {
							show: false
						}
					},
					grid: {
						bottom: 80,
						left: 3,
						containLabel: true
					},
					dataZoom: [],
					series: [{
							type: 'candlestick',
							name: 'Day',
							data: data,
							itemStyle: {
								color: '#FD1050',
								color0: '#0CF49B',
								borderColor: '#FD1050',
								borderColor0: '#0CF49B'
							}
						},
						{
							name: '',
							type: 'line',
							data: calculateMA(1, data),
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 1
							}
						},
					]
				};
				option && myChart.setOption(option);
		},
    getfourk() {
      let this_ = this
      let kbox = document.querySelector('.yuemain')
      var chartDom = kbox
      var myChart = echarts.init(chartDom);
      var option;

      // prettier-ignore

      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.length; i < len; i++) {
          // alert(111)
          if (i < dayCount) {
            result.push('-');
            continue;
          }
          var sum = 0;
          for (var j = 0; j < dayCount; j++) {
            sum += +data[i - j][1];
          }
          result.push(sum / dayCount);
        }
        return result;
      }
      // console.log(this_.klist);
      const dates = this_.klist.map(function(item) {
        var date = new Date(item.id * 1000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        // var Y = date.getFullYear() + '/';
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
        // var D = date.getDate();
        item.id = Y + M + D + h + m + s
        return item.id;
      });
      const data = this_.klist.map(function(item) {
        return [+item.high, +item.close, +item.low, +item.high];
      });

      option = {
        legend: {
          data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30'],
          inactiveColor: '#777'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            type: 'cross',
            lineStyle: {
              color: '#376df4',
              width: 2,
              opacity: 1
            }
          }
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          }
        },
        yAxis: {
          scale: true,
          axisLine: {
            lineStyle: {
              color: '#8392A5'
            }
          },
          splitLine: {
            show: false
          }
        },
        grid: {
          bottom: 80,
          left: 3,
          containLabel: true
        },
        dataZoom: [],
        series: [{
            type: 'candlestick',
            name: 'Day',
            data: data,
            itemStyle: {
              color: '#FD1050',
              color0: '#0CF49B',
              borderColor: '#FD1050',
              borderColor0: '#0CF49B'
            }
          },
          {
            name: '',
            type: 'line',
            data: calculateMA(1, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
    onperiod() { // 选择1和5
      console.log(this.active)
      if(this.active == 0){
        this.qqperiod.category = 1
        this.prpodata.category = 1
        this.getperiod()
      }else{
        this.qqperiod.category = 2
        this.prpodata.category = 2
        this.getperiod()
      }
    },
    mintueone() { // 1
      this.qqperiod.category = 1
      this.time = 0
      setTimeout(() => {
        this.getperiod()
      }, 1000)
    },
    mintuetwo() { // 5
      this.qqperiod.category = 2
      this.timetwo = 0
      setTimeout(() => {
        this.getperiod()
      }, 1000)
      // this.getperiod()
    },
    // 获取当前期数
    getperiod() {
      const that = this
      get_period(that.periodurl,that.qqperiod).then(res=>{
        // console.log(res)
        if(res.code == 1){
          that.periodarr = res.data
          if( that.active == 0 ){
            that.time = res.data.seconds
          }else if( that.active == 1 ){
            that.timetwo = res.data.seconds
          }
          var sum = res.data.lottery.replace(".", "")
					var lastThree = sum.substring(sum.length - 3)
					that.NumArr = lastThree.split("")

          that.result = res.data.result
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    // 下注列表
    getprop() {
      prop(this.prpourl,this.prop_data).then(res=>{
        // console.log(res, '下注')
        if(res.code == 1){
          this.proplist = res.data
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
    tobet(type,value) { // 打开弹窗
	this.bhindex = 2
	 this.bhvalue = this.kxtitle.close
      if( this.checked == false ){
        Toast({
          message: this.$t('addgift.tsyi'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
	  this.prpodata.amount = ''
	  this.prpomoney=0
      this.prpodata.type = type
      this.prpovalue = value
      this.prposhow = true


    },
    prpo_money(amount) { // 换算usdt
      // console.log(parseFloat(this.proplist.money))
      if( amount > parseFloat(this.proplist.money)){
        this.prpodata.amount = parseFloat(this.proplist.money)
      }
      // return flase
      const value = parseFloat(this.prpodata.amount) - ( parseFloat(this.proplist.bet_fee) / 100 * parseFloat(this.prpodata.amount) )
      this.prpomoney = parseFloat( value * this.prpovalue ).toFixed(2)
    },
    buttonbet() { // 下注
      const that = this
      if( that.prpodata.amount == '' || that.prpodata.amount == 0 ){
        Toast({
          message: this.$t('addgift.tser'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      if( parseFloat(that.prpodata.amount) < parseFloat(that.proplist.bet_min) ){
        Toast({
          message: this.$t('addgift.tssan') + that.proplist.bet_min,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      // console.log(that.prpodata)
      bet(that.beturl,that.prpodata).then(res=>{
        if( res.code == 1 ){
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
          that.prposhow = false
		  this.bhindex = 1
		  // that.prpodata.amount = ''
          // that.$router.go(0)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
	// zzclick() {
	//   that.prpodata.amount = ''
	// },
    // 下注记录
    getrecords() {
      bet_records(this.betrecordsurl,this.recordsdata).then(res=>{
        // console.log(res, '下注记录')
        this.recordstotal = res.data.total
        this.recordslist = res.data.data
        // console.log(this.recordslist)
		this.codeobj=res.code
      })
    },
    chantrade(e) { // 切换下注记录
      console.log(e)
      if( e == 0 ){
        this.recordslist = []
        this.recordsdata.page = 1
        this.recordsdata.status = 1
      }else{
        this.recordslist = []
        this.recordsdata.page = 1
        this.recordsdata.status = 2
      }
      this.getrecords()
    },
    btwochange(e) { // Trade/Historical
      if( e == 0 ){
        this.getrecords()
      }else{
        this.getperiod_histroy()
      }
    },
    chanminute(e) {
      if( e == 0 ){
        this.perioddata.category = 1
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod_histroy()
      }else{
        this.perioddata.category = 2
        this.periodlist = []
        this.perioddata.page = 1
        this.getperiod_histroy()
      }
    },
    getperiod_histroy() { // 获取历史记录
      period_histroy(this.periodhistroyurl,this.perioddata).then(res=>{
        // console.log(res);
        if( res.code == 1 ){
          this.periodtotal = res.data.total
          this.periodlist = res.data.data
          for( var i=0;i<this.periodlist.length;i++ ){
            var sum = this.periodlist[i].lottery.replace(".", "");
            var lastThree = sum.substring(sum.length - 3);
            this.periodlist[i].NumArr = lastThree.split("");
          }
          // console.log(this.periodlist)
        }else{
          Toast({
            message: res.msg,
            duration: 1200,
            className: 'noticeWidth' // 所加样式名
          })
        }
      })
    },
	leijibet() {
		leijibet().then(res=>{
			console.log(res);
		}).catch(err=>{

		})
	},
    giftdrown() { // 触底刷新
      const a = document.documentElement.clientHeight
      const b = document.documentElement.scrollTop
      const c = document.documentElement.scrollHeight
      const that = this
      // console.log(a,b,c)
      // if( a+b >= c ){
      //   console.log('触底了')
      //   if( that.activetwo == 0 ){ // Trade
      //     if( that.activethree == 0 ){ // 下注记录
      //       if( parseInt(that.recordstotal) / parseInt(that.recordsdata.page) < parseInt(that.recordsdata.page) ){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.recordsdata.page++
      //       that.getrecords()
      //     }else{
      //       if( parseInt(that.recordstotal) / parseInt(that.recordsdata.page) < parseInt(that.recordsdata.page) ){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.recordsdata.page++
      //       that.getrecords()
      //     }
      //   }else{ // Historical
      //     if(that.activefour == 0){
      //       if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.perioddata.page++
      //       that.getperiod_histroy()
      //     }else{
      //       if( parseInt(that.periodtotal) / parseInt(that.perioddata.page) < parseInt(that.perioddata.page)){
      //         Toast({
      //           message: 'What the hell is going on',
      //           duration: 1200,
      //           className: 'noticeWidth' // 所加样式名
      //         })
      //         return false
      //       }
      //       that.perioddata.page++
      //       that.getperiod_histroy()
      //     }
      //   }
      // }
    }
  },
  destroyed() {
    // 离开页面关掉轮询
    window.clearInterval(this.timenull)
    window.clearInterval(this.timenuller)
    window.clearInterval(this.timenullsan)
    window.clearInterval(this.timenullsi)
    // 离开页面取消监听
    // window.removeEventListener("scroll", this.giftdrown, false)
  },

}
</script>

<style lang="less" scoped>
  .team{
    padding: 0;
    .t-title{
      background-image: url('../assets/inteBg.png');
      background-size: 100%;
      .cont{
        padding: 4.7rem 3rem;
        .fx{
          color: #ffffff;
          margin-bottom: .8rem;
        }
        .ljz{
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #4E200C;
          border-radius: .5rem;
          margin-bottom: 1rem;
          padding:0 .2rem 0 .8rem;
          height: 4rem;
          .lj{
            color: #ffffff;
          }
          .copy{
            color: #4E200C;
            background: #F6FE19;
            width: 5rem;
            height: 3.5rem;
            text-align: center;
            line-height: 3.5rem;
            border-radius: .5rem;
          }
        }
        .yq{
           color: #ffffff;
        }
      }
    }
    .my{
      padding: auto;
      .my-t{
        margin-top: 1rem;
        height: 4rem;
        line-height: 4rem;
        background-color: #ffffff;
        padding-left: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
      }
      .my-c{
        padding: 0;
        .c-item{
         padding: 2rem;
          .c-u{

            display: flex;
            align-items: center;
            .c-i{
              width: 2.2rem;
              height: 2.2rem;
              margin-right: 1rem;
              .img{
                width: 2.2rem;
                height: 2.2rem;
              }
              .c-t{
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .team{
       padding: auto;
       .team-t{
         margin-top: 1rem;
         height: 4rem;
         line-height: 4rem;
         background-color: #ffffff;
         padding-left: 1rem;
         font-size: 1.5rem;
         font-weight: bold;
       }
       .team-i{
         margin-top: 1rem;
         padding: 0 2rem;
         .bg{
            background: linear-gradient(to bottom, #C2910D, #F7C202 );
            width: 100%;
            height: 1.5rem;
            border-radius: 3rem;
         }
         .t-item{
           margin-top: -1rem;
           padding:0 1rem;
           .com{
             background-color: #ffffff;
              border-radius: .4rem;
              padding: .8rem;
              .t-t{
                display: flex;
                align-items: center;
                font-size: 1.3rem;
                color: #939EAA;
                text-align: center;
                .k{
                  width: 16%;
                  height: 4rem;
                  line-height: 4rem;
                }
                .z{
                  width: 42%;
                  height: 4rem;
                  line-height: 4rem;

                }
                .y{
                  width: 42%;
                  height: 4rem;
                  line-height: 4rem;
                }
                // .s{
                //   width: 28%;
                //   height: 4rem;
                //   line-height: 4rem;
                // }
              }
              .t-s{
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                text-align: center;
                margin-bottom: 1rem;
                .s1{
                  width: 16%;
                  .img{
                    width: 3rem;
                    height: 3rem;
                  }
                }
                .se{
                  display: flex;
                  align-items: center;
                  border-radius: .8rem;
                  width: 84%;
                  background-color: #E9E9E9;
                  height: 5rem;
                  .s2{
                    width: 49%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 1.5rem;
                    .yuan{
                      width: 2rem;
                      height: 2rem;
                      border-radius: 50%;
                      border: 1rpx solid #707070;
                      margin-right: .4rem;
                      .img{
                        width: 2rem;
                        height: 2rem;
                      }
                    }
                  }
                  .s3{
                   width: 49%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .yuan{
                      width: 2rem;
                      height: 2rem;
                      border-radius: 50%;
                      border: 1rpx solid #707070;
                      margin-right: .4rem;
                      .img{
                        width: 2rem;
                        height: 2rem;
                      }
                    }
                  }
                  // .s4{
                  //   width: 33.3%;
                  //   display: flex;
                  //   align-items: center;
                  //   justify-content: center;
                  //   .yuan{
                  //     width: 2rem;
                  //     height: 2rem;
                  //     border-radius: 50%;
                  //     border: 1rpx solid #707070;
                  //     background-color: #ffffff;
                  //     margin-right: .4rem;
                  //   }
                  // }
                }

              }
           }
         }
       }
    }
  }
</style>
