<template>
  <van-dialog :showConfirmButton="false" v-model="isShow">
    <div class="miner-box" v-if="isShow">
      <div class="title">Concurso con premio</div>
      <div class="question-box">
        <div class="rank" v-if="questions">
          <span class="firstNum">{{ step }}</span
          ><span class="endNum">/{{ questions.length }}</span>
        </div>
        <div class="question">
          {{ questions[step - 1].content }}
        </div>

        <van-button :color="answer == 0 ? '#f18a60' : ''" @click="onBtn" round class="question-btn">{{ $t("home.txt24") }}</van-button>
        <van-button :color="answer == 1 ? '#f18a60' : ''" @click="onBtn" round class="question-btn">{{ $t("home.txt25") }}</van-button
        >
      </div>

      <van-button
        @click="onSubmits"
        class="btn"
        round
        v-if="step == questions.length && schedule  == questions.length">{{ $t("home.txt27") }}</van-button>
        <van-button
          @click="onSubmit"
          class="btn"
          round
          v-else>{{ $t("home.txt26") }}</van-button>
    </div>
  </van-dialog>
</template>

<script>
import { Toast } from "vant";
import { startdeviceapi,confirmdeviceapi } from "../api/index";
export default {
  props: ["questions"],
  data() {
    return {
      isShow: false,
      step: 1,
      schedule: 0,
      answer:null,
    };
  },

  methods: {
    showDialog() {
      this.isShow = !this.isShow;
      if (!this.isShow) {
        this.step = 1;
        this.schedule = 0;
      }
    },
    onBtn() {
      if(this.answer==null){
        this.answer = 0
      }else if(this.answer == 0){
        this.answer = 1
      }else{
        this.answer = 0
      }
    },

    async onSubmit() {
      if (this.step > this.questions.length) {
        return;
      }

      if(this.schedule >= this.questions.length) {
        return
      }

      if (this.step == this.questions.length) {
        this.schedule++;
      } else {
        this.step++;
        this.schedule++;
      }
      // const res = await startdeviceapi();
      // Toast({
      //     message: res.msg,
      //     duration: 1200,
      //   });
      // this.showDialog();
    },
    async onSubmits() {
      const res = await confirmdeviceapi();
      Toast({
          message: res.msg,
          duration: 1200,
        });
      this.showDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.miner-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  position: relative;
  background-image: url("../assets/miner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .title {
    text-align: center;
    color: #fff;
    font-weight: bold;
  }

  .question-box {
    margin-top: 1.5rem;
    padding: 1.5rem;
    width: 100%;
    background: linear-gradient(180deg, #dcd8f9 0%, #fffffd 100%);
    border-radius: 2rem;

    .rank {
      margin-bottom: 1.5rem;
      font-size: 1.8rem;
      .firstNum {
        color: #fe6719;
      }

      .endNum {
        color: #000;
      }
    }

    .question {
      font-weight: bold;
      color: #000;
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }

    .question-btn {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .btn {
    margin-top: 1.5rem;
    width: 70%;
  }
}
</style>
