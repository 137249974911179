// 2、创建英文语言包对象
export default {
	// 登录
	  "login": {
	    "txt1": "Hello, please log in to your account",
	    "txt2": "Don't have an account? Please register first",
	    "txt3": "Email",
	    "txt4": "Phone",
	    "txt5": "Please enter your email",
	    "txt6": "Please enter your phone number",
	    "txt7": "Please enter your login password",
	    "txt8": "Register",
	    "txt9": "Forgot your password?",
	    "txt10": "Log in",
	    "txt11": "By clicking to visit, you agree to the Terms of Use and Privacy Policy of XAI.",
	    "txt12": "2021-2024,",
	    "txt13": "XAIGPT_site",
	    "txt14": "Inc or its affiliates",
	    "txt15": "Please enter a valid email address",
	    "txt16": "Please enter the correct password"
	  },

	  // Forgot Password
	  "Forgot": {
	    "txt1": "Forgot Password",
	    "txt2": "Email",
	    "txt3": "Please enter the email you registered with",
	    "txt4": "New Password",
	    "txt5": "Please enter your new password",
	    "txt6": "Confirm New Password",
	    "txt7": "Please re-enter your new password",
	    "txt8": "Verification Code",
	    "txt9": "Please enter the verification code",
	    "txt10": "Get",
	    "txt11": "Confirm"
	  },

	  // Register
	  "register": {
	    "txt1": "Register Account",
	    "txt2": "Email",
	    "txt3": "Please enter your email",
	    "txt4": "Password",
	    "txt5": "Please enter your password",
	    "txt6": "Confirm Password",
	    "txt7": "Please re-enter your password",
	    "txt8": "Email Verification Code",
	    "txt9": "Please enter the email verification code",
	    "txt10": "Get",
	    "txt11": "Confirm",
	    "txt12": "Please enter a valid email address",
	    "txt13": "",
	    "txt14": "",
	    "txt15": "Please enter a valid password",
	    "txt16": "Please enter a valid password",
	    "txt17": "Please enter a valid email verification code",
	    "txt18": "Please enter your username",
	    "txt19": "Username",
	    "txt20": "Phone Number",
	    "txt21": "Please enter your phone number",
	    "txt22": "Please enter a valid phone number",
	    "txt23": "Phone Verification Code",
	    "txt24": "Please enter the phone verification code",
	    "txt25": "Mobile phone verification code"
	  },

	  // Home
	  "home": {
	    "txt1": "Total Assets",
	    "txt2": "Today's Earnings",
	    "txt3": "Accumulated",
	    "txt4": "Bonus",
	    "txt5": "Withdrawable",
	    "txt6": "Deposit",
	    "txt7": "Withdraw",
	    "txt8": "Share",
	    "txt9": "Team",
	    "txt10": "Introduction",
	    "txt11": "Announcements",
	    "txt12": "Records",
	    "txt13": "Start Practice",
	    "txt14": "Beginner",
	    "txt15": "Advanced",
	    "txt16": "Promotion",
	    "txt17": "Product Name",
	    "txt18": "Daily",
	    "txt19": "Term",
	    "txt20": "Total",
	    "txt21": "No products available",
	    "txt22": "Buy",
	    "txt23": "Price",
	    "txt24": "Yes",
	    "txt25": "No",
	    "txt26": "Confirm",
	    "txt27": "Submit",
	    "txt28": "XAI Tokens"
	  },

	  // Announcement
	  "gonggao": {
	    "title": "Announcement"
	  },

	  // Announcement Details
	  "gonggaodetails": {
	    "title": "Announcement Details"
	  },

	  // Sign-in
	  "SignIn": {
	    "title": "Sign-in Center",
	    "profit": "Earn Rewards",
	    "SignIn1": "Day 1",
	    "SignIn2": "Day 2",
	    "SignIn3": "Day 3",
	    "SignIn4": "Day 4",
	    "SignIn5": "Day 5",
	    "SignIn6": "Day 6",
	    "SignIn7": "Day 7",
	    "gift": "Mystery Gift Box",
	    "Sign": "Sign in",
	    "chick": "Signed in today",
	    "txt1": "Continuous check-in",
	    "txt2": "day"
	  },

	  // Check-in Record
	  "CheckinRecord": {
	    "title": "Daily Check-in Record"
	  },

	  // K-line Chart
	  "diagram": {
	    "height": "High",
	    "low": "Low",
	    "quantity": "24H Quantity",
	    "Recharge": "Recharge",
	    "purchase": "Purchase",
	    "min1": "1 Minute",
	    "min5": "5 Minutes",
	    "min30": "30 Minutes",
	    "hour1": "1 Hour",
	    "dayk": "Day K",
	    "Weekk": "Week K"
	  },

	 // Mining Operation
	  "Receive": {
	    "Remaining": "Remaining startup time",
	    "Start": "Start",
	    "Click": "Click to view",
	    "Help": "Help Center",
	    "Team": "Groups",
	    "Totalnumber": "Total number of people",
	    "Todays": "Today's earnings",
	    "available": "Next level benefits",
	    "Recharge": "Recharge",
	    "Withdraw": "Withdraw",
	    "friends": "Invite your friends to join and earn rewards.",
	    "qd": "Machine registration", // New -- Start
	    "yq": "Invite", // Invitation
	    "lrtj": "Earnings statistics", // Earnings statistics
	    "tjsj": "Statistical data", // Statistical data
	    "xj": "Detailed report", // Details
	  },

	  // Help Center
	  "Helpcenter": {
	    "title": "Help Center"
	  },

	  "Helpcenterdetails": {
	    "title": "Help Center Details"
	  },

	  // Team
	  "party": {
	    "title": "My Team",
	    "Totalnumber": "Total number of people",
	    "Todays": "Registration rewards",
	    "available": "Recharge rewards",
	    "team1": "Level 1 Team",
	    "team2": "Level 2 Team",
	    "team3": "Level 3 Team",
	    "Referral": "Referral Code",
	    "Share": "Share link",
	    "Success": "Copy success",
	    "Job": "Professional title",
	    "Income": "Income",
	    "Receive": "Receive",
	    "zczrs": "Total registered users",
	    "czzrs": "Total recharged users",
	    "czyj": "Recharge commission",
	    "zczje": "Total recharged amount"
	  },

	  // Team Levels
	  "Grade": {
	    "title1": "Level 1",
	    "title2": "Level 2",
	    "title3": "Level 3",
	    "Username": "Username",
	    "Phone": "Phone",
	    "Recharge": "Recharge",
	    "qxz": "Please select",
	    "wcz": "Not recharged",
	    "ycz": "Recharged",
	    "cpgq": "Product expired",
	    "zzyx": "Running",
	    "title6": "Serial number"
	  },

	  // Recharge Selection
	  "Recharge": {
	    "title": "Recharge Channel",
	    "recharge": "Recharge",
	    "amount": "Recharge amount",
	    "Please": "Please enter the recharge amount",
	    "confirm": "Confirm",
	    "success": "Selection successful",
	    "less": "The recharge amount cannot be less than one",
	    "txt1": "Select recharge channel"
	  },

	  // Recharge Details
	  "Charge": {
	    "Address": "Copy address",
	    "reminder": "Recharge Reminder",
	    "assets": "Do not add assets to accounts other than the official account; otherwise, assets will be unrecoverable",
	    "amount": "The transfer amount must match the actual order amount.",
	    "Upload": "Upload recharge order",
	    "Please": "Please make sure an image has been selected.",
	    "Success": "Upload successful",
	    "Remark": "Note: Remember to upload a screenshot after recharging"
	  },

	  // Recharge Records
	  "Rechargerecord": {
	    "title": "Recharge Record",
	    "Date": "Date",
	    "Amount": "Amount",
	    "Channel": "Channel",
	    "State": "Status",
	    "recharged": "To be recharged",
	    "successful": "Recharge successful",
	    "Notrecharged": "Not recharged",
	    "shzt": "Audit status",
	    "shz": "Under review"
	  },

	  // Withdrawal
	  "Withdraw": {
	    "title": "Withdraw",
	    "Amount": "Withdrawal amount",
	    "Available": "Available",
	    "All": "All",
	    "address": "ADDRESS",
	    "Submit": "Submit",
	    "Currently": "Currently, two withdrawal methods are supported",
	    "Bank": "Bank withdrawal hours: Withdrawals can be made anytime from Monday to Friday.",
	    "Arrival": "Arrival time: Based on the bank's arrival time, usually between 30 to 48 hours.",
	    "within": "Arrival time is within 72 hours during the tax-free subsidy period.",
	    "withdrawal": "The withdrawal amount cannot be less than 15.",
	    "receiving": "Please link the receiving account before proceeding.",
	    "min": "Min",
	    "max": "Max",
	    "fee": "Fee"
	  },

	  // Mining Page
	  "Mining": {
	    "title": "Business Strategy",
	    "Power": "Power",
	    "progress": "In progress",
	    "Computer": "Computing power",
	    "Purchasing": "Purchase price",
	    "Daily": "Daily income",
	    "Valid": "Validity period",
	    "Days": "Days",
	    "Recharge": "Recharge",
	    "purchase": "Buy now",
	    "Miner": "Miner",
	    "After": "After expiration, the miner can be delivered to you at any time.",
	    "Experience": "Business Strategy",
	    "Price": "Price",
	    "profit": "Daily profit",
	    "Start": "Start date",
	    "End": "End date",
	    "Agree": "Accept",
	    "Escrow": "Escrow Agreement",
	    "Artificial": "AI Mining Solution",
	    "Users": "Users can mine by purchasing mining machines or renting cloud computing power",
	    "Hash": "Hash rate: 1TH/S-100TH/S",
	    "Benefit": "Benefit: Earn 80% of each mined coin, which will be automatically settled into the user's account.",
	    "Withdrawal": "Withdrawal: Withdraw anytime, 24-hour withdrawal",
	    "experience": "(Experience miners are for user experience only)",
	    "Special": "Special note",
	    "calculation": "These data calculations are based on the current coin price and estimated mining pool data, automatically converted to USD by the system. For real-time coin production data, refer to actual verified data.",
	    "machine": "Mining machine and cloud computing power earnings may decrease over time, and specific annual earnings will depend on actual annual returns.",
	    "mining": "Buy a mining machine or rent a cloud computing plan, and the maintenance fee for the production deposit represents 20% of the early-stage dynamic adjustment support cost. The expected daily coin production will not be less than 70%. The current calculation is based on the lowest production value to estimate earnings. The actual annual expectation is above 300%. The actual number of produced coins will prevail.",
	    "Currency": "T+1 coin production time (i.e., within one day after nuclear delivery and deposit into escrow). Refer to the actual official production date for more details.",
	    "Confirm": "Confirm",
	    "first": "Please confirm first to accept the escrow agreement",
	    "purchases": "Number of purchases",
	    "gmsl": "Purchase amount",
	    "yjsy": "Projected income"
	  },
	 // My Page
	  "my": {
	    "txt1": "Deposit",
	    "txt2": "Withdraw",
	    "txt3": "Wallet",
	    "txt4": "Products",
	    "txt5": "Email",
	    "txt6": "Withdrawal Address",
	    "txt7": "Invite Friends",
	    "txt8": "Team",
	    "txt9": "Claim Rewards",
	    "txt10": "Company Video",
	    "txt11": "Security Verification",
	    "txt12": "Language Settings",
	    "txt13": "Download APP",
	    "txt14": "About the Company",
	    "txt15": "Official Support",
	    "txt16": "Log Out",
	    "txt17": "Invitation Link"
	  },

	  // Settings Page
	  "install": {
	    "title": "Install",
	    "switch": "Change Language",
	    "name": "Change Name",
	    "Password": "Change Password",
	    "Phone": "Change Phone Number",
	    "binding": "App Binding"
	  },

	  // Upload Avatar
	  "Headimage": {
	    "Avatar": "Upload Avatar",
	    "Upload": "Upload",
	    "image": "The uploaded image cannot be empty."
	  },

	  // Modify Name
	  "ModifyName": {
	    "name": "Modify Name",
	    "Determine": "Confirm",
	    "cannot": "Name cannot be empty"
	  },

	  // Change Password
	  "ChangePassword": {
	    "title": "Change Password",
	    "Old": "Old Login Password",
	    "New": "New Login Password",
	    "Confirm": "Confirm Password",
	    "Determine": "Confirm",
	    "oldpassword": "Please enter the old password",
	    "newpassword": "Please enter the new password",
	    "Confirmationpassword": "Please enter the password",
	    "twopasswords": "Both passwords must match."
	  },

	  // Change Phone Number
	  "Phonenumber": {
	    "title": "Phone Number",
	    "phone": "Phone Number",
	    "code": "Mobile Verification Code",
	    "codes": "Code",
	    "Determine": "Confirm",
	    "Phonerules": "Phone number cannot be empty",
	    "coderules": "Verification code cannot be empty.",
	    "Please": "Please enter your phone number first."
	  },

	  // All Records
	  "Mypurse": {
	    "title": "My Wallet",
	    "All": "All",
	    "type": "Type",
	    "Recharge": "Recharge",
	    "withdraw": "Withdraw",
	    "product": "Product Purchase",
	    "daily": "Daily Income",
	    "Direct": "Direct Referral Reward",
	    "envelope": "Red Envelope Collection",
	    "fixed": "Fixed Income",
	    "check": "Login",
	    "benefits": "Team Benefits",
	    "Cancel": "Cancel",
	    "Theres": "No more",
	    "Loading": "Loading",
	    "System": "System Settings",
	    "yuebao": "Lower-level Yu'ebao purchase reward",
	    "shouyi": "Yu'ebao subscription error",
	    "amount": "",
	    "qiandao": "",
	    "tuandui": "",
	    "zhuce": "",
	    "keyong": "",
	    "jiangli": ""
	  },

	  // Mining Machine Status
	  "product": {
	    "title": "Buy Product",
	    "progress": "In Progress",
	    "purchases": "Number of Purchases",
	    "Computer": "Computing Power",
	    "price": "Purchase Price",
	    "Daily": "Daily Production Income",
	    "running": "Running",
	    "expired": "Expired",
	    "Expired": "Expired",
	    "start": "Start Time",
	    "end": "End Time"
	  },

	  // Purchase Record
	  "Purchaserecord": {
	    "title": "Purchase History",
	    "types": "Types of",
	    "product": "Product Name",
	    "price": "Product Price",
	    "Daily": "Daily Return",
	    "start": "Start Time",
	    "end": "End Time"
	  },

	  // Address
	  "Address": {
	    "title": "Wallet Address",
	    "already": "You have already added an address. If you need to modify it, please contact the administrator to make changes."
	  },

	  // Add New Address
	  "Addressdetails": {
	    "title": "Add Address",
	    "Address": "ADDRESS",
	    "Determine": "Confirm",
	    "empty": "The address cannot be empty.",
	    "Successfully": "Added successfully"
	  },

	  // Red Envelope Redemption
	  "Redemption": {
	    "title": "Redemption Code",
	    "Please": "Please enter the redemption code",
	    "hbyi": "Number of entries",
	    "hber": "Enter amount",
	    "Determine": "Confirm",
	    "Determineer": "Confirm Redemption",
	    "Determinesan": "I want to send USDT",
	    "Exchange": "The redemption code cannot be empty"
	  },

	  // Video Introduction
	  "Video": {
	    "title": "Video Introduction"
	  },

	  // Company Introduction
	  "company": {
	    "title": "About the Company"
	  },

	  // Activity List
	  "activity": {
	    "title": "Activity",
	    "hot": "Popular Products",
	    "discounted": "Discounted Products"
	  },

	  "mail": {
	    "mailbox": "Mailbox",
	    "yzm": "Code",
	    "srmail": "Please enter your email address",
	    "sryzm": "Enter the code",
	    "sendcode": "Send",
	    "emptyemail": "Email cannot be empty.",
	    "txt1": "Change email"
	  },

	  "profit": {
	    "jrsy": "Today's Earnings",
	    "zcyqjl": "Registration Invitation Rewards",
	    "jqsy": "Machine Earnings",
	    "tdyjjl": "Team Commission Rewards",
	    "yxsr": "Monthly Salary Income",
	    "lrze": "Total Profit",
	    "zzcyqjl": "Total Registration Invitation Rewards",
	    "zjqsy": "Total Machine Earnings",
	    "ztdyjjl": "Total Team Commission Rewards",
	    "zyxsr": "Total Monthly Salary Income",
	    "jrzlr": "Total Profit Today",
	    "zlr": "Total Profit"
	  },
	foot: {
	    home: "Home",
	    yx: "Active",
	    jy: "Game",
	    tm: "Team",
	    my: "My",
	  },

	  rechargettwo: {
	    name: "Name",
	    bank: "Bank",
	    account: "Account",
	    qtjczjt: "Please add a screenshot of the recharge",
	    czje: "Recharge amount",
	  },

	  add: {
	    addemail: "Email",
	    addforbottom:
	      "If email verification fails, click Contact",
	    addforbottomer: "Official Customer Service",
	    addregtitle: "Registration",
	    addregts: "Registration successful, redirecting",
	  },

	  addhome: {
	    inbodyyi: "Deposit",
	    inbodyer: "Recharge",
	    inbodysan: "Withdraw",
	    inbodysi: "Share with Friends",
	    inbodywu: "Groups",
	    inbodyliu: "Reward Center",
	    rw: "Reward",
	    wallet: "My Wallet",
	    walletyi: "Total Assets",
	    walleter: "Available Balance",
	    walletsan: "Earnings",
	    walletsi: "Earnings Today",
	    walletwu: "Total XAI Tokens",
	    walletliu: "Total XAI Value",
	    help: "Help Center",
	    bottomamp: "XAIGPT",
	    bottom:
	      " helps you master the secrets of making money in the AI era!",
	    bottomer:
	      " automatically collects trading signals and makes automated buys and sells. It allows you to earn money easily 24/7 and gain automated trading profits! Independent data analysis, smart trading, and stable profit guarantees! Join us and start your journey towards financial freedom!",
	    bottomtwo: "Safe Operation",
	    bottomtwoday: "Day",
	    bottomtwoer: "Accumulated Earnings",
	    hezuotitle: "Cooperation Platform",
	    hezuoyi: "Binance",
	    hezuoer: "Kraken",
	    hezuosan: "KuCoin",
	    hezuosi: "Bitmark",
	    hezuowu: "Gate.io",
	    hezuoliu: "Coinbase",
	    hezuoqi: "Bybit",
	    hezuoba: "OKX",
	    hezuojiu: "bitget",
	    hezuoshi: "Bitfinex",
	    tcyi: "Mysterious Gift",
	    tcer: "Box",
	    tcsan: "Latest Post",
	    gb: "Close",
	  },

	  addrech: {
	    maxusdt: "The recharge amount cannot exceed",
	    tsyi: "The recharge value cannot exceed 5,000",
	    tser: "Minimum recharge value",
	    tsmessyi1: "Recharge instructions:",
	    tsmessyi2: "Supports cryptocurrency recharge and bank account recharge.",
	    tsmessyi3: "You can get cash rewards from the company by recharging with USDT cryptocurrency. Check with the administrator for specific rewards.",
	    tsmessyi4: "For bank account recharges, get a new account number every time. Do not recharge the old account to avoid financial loss.",
	    tsmessyi5: "If you encounter any recharge issues or haven't received the recharge, please contact the administrator immediately.",
	  },

	  addbank: {
	    banktitle: "Recharge",
	    banktitlevalue: "Enter recharge amount",
	    tsyi: "The recharge amount cannot be zero",
	    tser: "The recharge value cannot be less than",
	    btn: "Submit Recharge",
	    tssan: "Please upload the image first",
	    bankbtn: "Confirm Recharge",
	    address: "",
	    copy: "Copy",
	    reminder: "* Recharge Reminder",
	    txt1: "1. Do not add assets to non-official accounts, otherwise the assets cannot be recovered.",
	    txt2: "2. The transfer amount must exactly match the actual order amount.",
	    txt3: "Note: Remember to upload the loading image after it is loaded.",
	    tips: "",
	    tips2: "Scan the QR code to transfer USDT",
	    address_t: "Wallet Address",
	  },

	  addwith: {
	    address: "Wallet Address",
	    addresser: "Bank Account Address",
	    addresssan: "Withdrawal Type",
	    addresssi: "Bank",
	    tsyi: "The withdrawal amount cannot be less than",
	    tser: "The withdrawal amount cannot exceed",
	    tssan: "The withdrawal amount cannot be less than",
	    jiluyi: "Withdrawal Record",
	    jiluer: "Date",
	    jilusan: "Amount",
	    jilusi: "Channel",
	    jiluwu: "Status",
	  },

	  address: {
	    Bitcoin: "Revert",
	    message:
	      "For your account security, please re-issue the withdrawal 24 hours after modifying account information.",
	    messageer: "Please join first.",
	    usdtyi: "Please enter your wallet address",
	    usdter: "Only TRC20 addresses can be added",
	    usdtsan: "Contact Email",
	    usdtsi: "Email Verification Code",
	    addbankyi: "Account Number",
	    addbanker: "Enter Account",
	    addbanksan: "Bank Name",
	    addbanksi: "Enter Bank Name",
	    addbankwu: "Please enter your name",
	    addbankliu: "Please enter your name",
	    addbankqi: "Contact Email",
	    addbankba: "Contact Email",
	    addbankjiu: "Email Verification Code",
	    addbankp:
	      "To ensure that your withdrawal funds reach your account smoothly, please link your real name and bank account. Otherwise, you may not receive the withdrawal.",
	    tsyi: "Bank cannot be empty.",
	    tser: "The number cannot be empty",
	    tssan: "Email cannot be empty.",
	    tssi: "Verification code cannot be empty.",
	    tswu: "Name cannot be empty",
	    tsliu: "Email format is incorrect.",
	  },

	  addtask: {
	    title: "Reward Center",
	    conright: "Join",
	    conrighter: "Now",
	    tsyi: "Received",
	    tser: "Discarded",
	    titleer: "Share",
	    shareyi: "Upload and submit a photo",
	    shareer: "Please upload the sharing certificate",
	    sharesan: "Send",
	    sharets: "The image has not been uploaded yet.",
	  },

	  addturn: {
	    start: "Time remaining to finish",
	    problenyi: "This draw is not open yet, so stay tuned.",
	    problener:
	      "The spinning draw will first consume points and automatically switch to balance when points are insufficient.",
	    problensan: "Consume ",
	    newproblensan: " points to participate in the draw.",
	    problensi: "Consume ",
	    newproblensi: " balance to participate in the draw.",
	    topboxyi: "Remaining points",
	    topboxer: "Available balance",
	    topboxsan: "Accumulated points",
	    btn: "Draw Records",
	    tsyi: "If the points are less than ",
	    newtsyi: " , you have switched to balance to participate in the draw",
	    tser: "The balance is less than ",
	    newtser: " cannot participate",
	    tssan: "Those with less than ",
	    newtssan: " points cannot participate",
	    tssi: "The balance is less than ",
	    newtssi: " cannot participate",
	    tswu: "The draw has not started yet, stay tuned.",
	    tsliu: "You have not bought a robot and cannot attend the event",
	    tsqi: "The draw has not started yet, stay tuned.",
	    title: "Lucky Wheel",
	    Bitcoin: "Points Consumption",
	    Bitcoiner: "Paste",
	  },
	  addrece: {
	    title: "Groups",
	    tcyi: "Copy Link",
	    tcer: "Share on",
	    tsyi: "Please recharge and activate your account first",
	  },
	"addparty": {
	    "incomeyi": "Refund not received",
	    "incomeer": "Daily income",
	    "incomesan": "Promotional rewards",
	    "lbodyyi": "Total / Valid",
	    "lbodyer": "Recharge/Commission",
	    "level": "Level",
	    "sqyj": "Collect commission",
	    "levelteam": "Level team",

	    "jie1": "Team development rewards:",
	    "jie2": "The number of active teams determines your bonus and income! The bigger your team, the more you'll earn!",
	    "jie3": "Activate your account to enjoy permanent recharge rewards from team levels 123! The basic reward rate is 4%, 2%, and 0.5%. (The higher your account level, the more you'll enjoy the 4%-5% recharge bonus from Level 1 team)",
	    "jie4": "C. The company will periodically grant rewards and subsidies to outstanding team leaders.",
	    "jieshaoliu": "When the active users of your Level 123 team reach different numbers, you can receive bonuses and rewards at different stages, such as weekly and monthly.",

	    "thiszhou": "This week",
	    "thismonth": "This month",
	    "lastmonth": "Last month",
	    "all": "All",
	    "zdytime": "Custom",
	    "tsyi": "Currently, no commissions are available for income",
	    "gradeyi": "Level 1 active",
	    "gradeer": "Level 2 active",
	    "gradesan": "Level 3 active",
	    "gradetitle": "Team level",
	    "jintian": "Today",
	    "kstime": "Start time",
	    "jstime": "End time",
	    "btnqd": "Confirm",
	    "qd": "Confirm",
	    "qx": "Cancel",
	    "qxz": "Please select"
	  },
	  "addpull": {
	    "pullyi": "Pull down to refresh",
	    "puller": "Release to refresh",
	    "pullsan": "Loading..."
	  },
	  "addprofit": {
	    "title": "Profit",
	    "datayi": "Registration reward rate",
	    "dataer": "Machine profit rate",
	    "datasan": "Team commission rate",
	    "datasi": "Monthly salary income rate"
	  },
	  "addmin": {
	    "content": "Return rate",
	    "tcyi": "Calculate",
	    "tsyi": "You currently don't have the number of machines purchased",
	    "tser": "No more quantity available",
	    "tssan": "You currently don't have the number of machines purchased",
	    "tssi": "Purchase amount cannot be zero."
	  },
	  "addgift": {
	    "nav": "BTC/USDT",
	    "normal": "Prediction",
	    "navrightyi": "History",
	    "navrighter": "Rule",
	    "qhyi": "Normal mode",
	    "qher": "Stable mode",
	    "msgyi": "High",
	    "msger": "Low",
	    "msgsan": "24H Volume",
	    "fxk": "Financial privacy policy",
	    "bonex": "Ticket",
	    "btwoyi": "Bet amount:",
	    "datanull": "Currently no data available",
	    "tcyi": "Amount",
	    "tcer": "Expected to be available after profitability.",
	    "tcsan": "Processing fee:",
	    "tcsi": "Amount in USDT",
	    "tcsitwo": "Available balance:",
	    "tcwu": "Send",
	    "tarbaryi": "1 minute",
	    "tarbarer": "5 minutes",
	    "tarbarsan": "Trade",
	    "tabarsi": "Transaction history",
	    "tabarwu": "Pending transaction",
	    "tabarliu": "Transaction log",
	    "tsyi": "Please review the privacy policy first.",
	    "tser": "Amount cannot be empty or zero",
	    "tssan": "Cannot be less than the minimum of",
	    "htitle": "Winning number history",
	    "htsyi": "What's going on?",
	    "rtitle": "Rule",
	    "Ttitle": "Trend analysis",
	    "tbodyyi": "Prediction 28",
	    "tbodyer": "[Signal]",
	    "ycboyi": "Planner",
	    "ycboer": "Ticket",
	    "ycbosan": "Predict",
	    "ycbosi": "Result",
	    "ycbowu": "Win",
	    "ycboliu": "Lose",
	    "ycboki": "Number of buyers:",
	    "ycboba": "Quick selection",
	    "yctcyi": "Order confirmation",
	    "yctcer": "Ticket",
	    "yctcsan": "Bet options",
	    "yctcsi": "Single bet amount",
	    "yctcwu": "Total order",
	    "yctcliu": "Cancel",
	    "yctcqi": "Confirm",
	    "ycts": "The entered amount cannot be empty"
	  },
	"addmy": {
	    "mywyi": "Total assets:",
	    "mywer": "Earnings today: ",
	    "mywsan": "Total income:",
	    "mywsi": "Rewards:",
	    "mywwu": "Available balance:",
	    "mywliu": "Recharge",
	    "mywqi": "Withdraw",
	    "myba": "Duration",
	    "sboyi": "Mysterious gift",
	    "sboer": "Box",
	    "iboyi": "WhatsApp",
	    "iboer": "Telegram",
	    "ibosan": "Email",
	    "xgpassts": "After changing the password, it takes 24 hours to initiate the withdrawal",
	    "appyi": "Link account",
	    "apper": "WhatsApp linked successfully",
	    "tele": "Telegram linked successfully",
	    "yx": "Email linked successfully",
	    "appsan": "Account:",
	    "appsi": "Revert",
	    "appts": "You have already added an account; if you need to modify it, please contact the administrator for operation.",
	    "yxts": "After clicking send, don't forget to check your spam folder. If you still haven't received the verification code, try again later.",
	    "mywts": "Invoice registration details for this week",
	    "codeyi": "USDT",
	    "codeer": "XAI",
	    "duryi": "Duration",
	    "durer": "Online duration",
	    "dursan": "The online duration is 30 minutes.",
	    "mailts": "Only lowercase letters can be entered"
	  },
	  "evaluate": {
	    "one": "User reviews",
	    "tabone": "Latest version",
	    "tabtwo": "I want to post",
	    "input": "Please make sure to review the content of the review!",
	    "imgone": "Review content",
	    "imgtwo": "Upload images",
	    "kong": "The uploaded content cannot be empty"
	  },
	  "hongbao": {
	    "listtitle": "Sending history",
	    "listyi": "All",
	    "lister": "Received",
	    "listsan": "Sent",
	    "tsyi": "The red envelope and amount cannot be empty",
	    "daodil": "It’s the end"
	  },
	  "mlog": {
	    "title": "Machine log",
	    "listtime": "Start time"
	  },
	  "newkx": {
	    "title": "Accumulated order amount",
	    "title1": "Get commission amount"
	  },
	  "jjbuy": {
	    "title": "Wealth appreciation",
	    "title1": "History",
	    "title2": "Purchase limit",
	    "title3": "Buy",
	    "title4": "Daily income",
	    "title5": "Day",
	    "title6": "The term",
	    "title7": "Subscription amount",
	    "title8": "Remaining quantity",
	    "title9": "Distance to end",
	    "title10": "Available balance",
	    "title11": "Recharge",
	    "title12": "Balance refund",
	    "title13": "Are you sure you want to buy the modified product?",
	    "title14": "Confirm",
	    "title15": "Cancel",
	    "title16": "Purchase history",
	    "title17": "Pending review",
	    "title18": "Did not pass",
	    "title19": "Income",
	    "title20": "Completed",
	    "title21": "Application period",
	    "newtitle21": "Approval time",
	    "title22": "If the balance is insufficient, please recharge first.",
	    "text1": "Account balance",
	    "text2": "Recharge",
	    "text3": "Refund"
	  },
	  "newhome": {
	    "title": "The event has ended",
	    "title1": "The event has not started.",
	    "title2": "Please wait for the countdown to finish"
	  },
	  "Receive": {
	    "txt1": "Community dashboard",
	    "txt2": "Corporate events",
	    "txt3": "Mission dispatch",
	    "txt4": "Lucky wheel",
	    "txt5": "Funds"
	  },
	  "Gift": {
	    "txt1": "Share link",
	    "txt2": "Copy",
	    "txt3": "Invite friends to earn rewards",
	    "txt4": "My team",
	    "txt5": "Total team members",
	    "txt6": "Total team rewards",
	    "txt7": "Team earnings ranking",
	    "txt8": "This week",
	    "txt9": "This month"
	  },
	  "Product": {
	    "title": "Purchase history",
	    "txt1": "Name",
	    "txt2": "Price",
	    "txt3": "Validity period",
	    "txt4": "Status",
	    "txt5": "Daily income",
	    "txt6": "In progress",
	    "txt7": "Expired"
	  },
	  "Share": {
	    "txt1": "My level",
	    "txt2": "Share link",
	    "txt3": "Copy",
	    "txt4": "Community entry"
	  },
	  "RedList": {
	    "txt1": "Send user",
	    "txt2": "Receive",
	    "txt3": "I want to send"
	  },
	  "racecar": {
	    "txt1": "Standard panel",
	    "txt2": "Game instructions",
	    "boleto": ["Big", "Small", "Double", "Simple"],
	    "rank": [
	      "Champion",
	      "Runner-up",
	      "Third place",
	      "Fourth place",
	      "Fifth place",
	      "Sixth place",
	      "Seventh place",
	      "Eighth place",
	      "Ninth place",
	      "Tenth place"
	    ],
	    "txt3": "1 minute",
	    "txt4": "5 minutes",
	    "txt5": "Betting",
	    "txt6": "Races",
	    "txt7": "Draw",
	    "txt8": "Enter amount",
	    "txt9": "Recent bets",
	    "txt10": "Bet now",
	    "txt11": "Bet history",
	    "txt12": "Funds history",
	    "txt13": "No more",
	    "txt14": "Loading...",
	    "txt15": "Bet placed",
	    "txt16": "Do you want to bet",
	    "txt17": "amount?",
	    "txt18": "Full trend",
	    "txt55": "The man who got up from bed",
	    "txt77": "Confirmation",
	    "txt88": "Cancellation"
	  },
	  "vip": {
	    "txt1": "Membership level",
	    "txt2": "Level",
	    "txt3": "Refund percentage",
	    "txt4": "Achieved"
	  }
}
