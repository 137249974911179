<template>
  <div class="canpanyEvent-container">
    <van-nav-bar
      :title="$t('Receive.txt2')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="img-box">
      <img src="../assets/conpany.png" class="img" />
      <div class="tt" style="color: #ffffff">{{ $t("Receive.txt2") }}</div>
    </div>

    <div class="event-container">
      <div class="event-item" @click="" v-for="item in companyEventList">
        <div class="title">{{ item.title }}</div>
        <div class="content" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNotice } from "@/api/index";

export default {
  data() {
    return {
      companyEventList: [],
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const res = await getNotice();
      console.log(res);
      this.companyEventList = res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.canpanyEvent-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .img-box {
    position: relative;
    margin-bottom: 2rem;
    .tt {
      position: absolute;
      top: 4rem;
      left: 2rem;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }

  .event-container {
    flex: 1;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;

    .event-item {
      padding-bottom: 2rem;
      border-bottom: 1px solid #cfcfcf;
      width: 100%;
      margin-bottom: 1.4rem;

      .title {
        font-size: 1.8rem;
        font-weight: bold;
      }
      .content {
        overflow: hidden;
        font-size: 1.4rem;
        margin-top: 1.5rem;
        text-indent: 2em;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
