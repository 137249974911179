<template>
  <!-- 矿机状态 -->
  <div class="product">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      :pulling-text="$t('addpull.pullyi')"
      :loosing-text="$t('addpull.puller')"
      :loading-text="$t('addpull.pullsan')"
      pull-distance="100"
    >
      <div style="min-height: 100vh">
        <div class="product-title">
          <van-icon
            @click="$router.back()"
            name="arrow-left"
            size="2rem"
            color="#fff"
          />
          <div class="title">{{ $t("jjbuy.title") }}</div>
          <div class="title" @click="$router.push('/funds')">
            {{ $t("jjbuy.title1") }}
          </div>
          <div class="yueBox">
            <div class="yue-title">{{ $t("jjbuy.text1") }}</div>
            <div class="yue-num">{{ balance }}</div>
          </div>

          <div class="czbox">
            <van-grid :border="false" :column-num="2">
              <van-grid-item @click="$router.push('/recharges?entrance=yuebao')" style="border-right: 1px solid #f7f7f7">
                <img class="grid-img" src="../assets/inbodys.png" alt="" />
                <div class="grid-text">{{ $t("jjbuy.text2") }}</div>
              </van-grid-item>
              <van-grid-item @click="getback_money()">
                <img class="grid-img" src="../assets/inbody2.png" alt="" />
                <div class="grid-text">{{ $t("jjbuy.text3") }}</div>
              </van-grid-item>
            </van-grid>
          </div>
        </div>

        <div
          style="padding: 0rem 1.6rem; padding-bottom: 3rem; margin-top: 10rem"
        >
          <div
            class="Content"
            v-for="(item, index) in Miningmachinelist"
            :key="index"
          >
            <div class="top">
              <div class="img">
                <img :src="item.image" alt="" />
                <div class="newtitle">
                  <div>{{ item.name }}</div>
                  <div class="green">
                    {{ $t("jjbuy.title2") }}：{{ item.buy_time }}
                  </div>
                </div>
              </div>
              <div class="Content-title" @click="buy(item)">
                {{ $t("jjbuy.title3") }}
              </div>
            </div>

            <div class="Content-info">
              <div class="left">
                <div>{{ $t("jjbuy.title4") }}</div>
                <div
                  style="color: #41d6a5; font-weight: 600; margin-bottom: 1rem"
                >
                  {{ item.interest }} USDT
                </div>
              </div>
              <div class="left">
                <div>{{ $t("jjbuy.title6") }}</div>
                <div style="color: #000; font-weight: 600; margin-bottom: 1rem">
                  {{ item.days }} {{ $t("jjbuy.title5") }}
                </div>
              </div>
              <div class="left">
                <div>{{ $t("jjbuy.title7") }}</div>
                <div
                  style="
                    color: #000;
                    font-weight: 600;
                    margin-bottom: 1rem;
                    white-space: nowrap;
                  "
                >
                  {{ item.price }} USDT
                </div>
              </div>
              <div class="left">
                <div>{{ $t("jjbuy.title8") }}</div>
                <div
                  style="color: #ee822f; font-weight: 600; margin-bottom: 1rem"
                >
                  {{ item.left_point }}
                </div>
              </div>
            </div>
            <div class="continfobox">
              {{ $t("jjbuy.title9") }} :
              <van-count-down
                style="margin-left: 0.5rem; color: #fff"
                :time="item.left_time * 1000"
                format="DD : HH : mm : ss "
              />
            </div>
          </div>
        </div>

        <div
          v-if="false"
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #fe6719;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.6rem;
            font-size: 1.4rem;
            color: #fff;
          "
        >
          <div>
            <span style="white-space: nowrap">{{ $t("jjbuy.title10") }}</span
            >：{{ pic }}
          </div>
          <div style="display: flex; align-items: center">
            <div
              style="
                background-color: #ee822f;
                border-radius: 0.5rem;
                width: 9rem;
                height: 4rem;
                text-align: center;
                padding-top: 0.5rem;
              "
              @click="$router.push('/recharges?entrance=yuebao')"
            >
              {{ $t("jjbuy.title11") }}
            </div>
            <div
              style="
                background-color: #92d050;
                border-radius: 0.5rem;
                margin-left: 1.5rem;
                width: 11rem;
                height: 4rem;
                text-align: center;
                line-height: 1.1;
                padding-top: 0.5rem;
              "
              @click="getback_money()"
            >
              {{ $t("jjbuy.title12") }}
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getproducts, getyuebaobuy, getback_money } from "@/api/index";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  name: "FUND",
  data() {
    return {
      // time:0,
      isLoading: false,
      Miningmachinelist: [],
      pic: "",
      isend: "",
      balance: "",
    };
  },
  created() {
    this.getproducts();
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.$router.go(0);
      }, 1000);
    },
    async getproducts() {
      const res = await getproducts();
      this.Miningmachinelist = res.data.data;
      this.pic = res.data.balance;
      this.isend = res.data.is_end;
      this.balance = res.data.balance;
    },
    async getyuebaobuy(id) {
      let data = {
        pro_id: id,
      };
      const res = await getyuebaobuy(data);
      Toast({
        message: res.msg,
        duration: 1200,
        className: "noticeWidth", // 所加样式名
      });
      if (res.code == 1) {
        setTimeout(() => {
          this.$router.go(0);
        }, 1500);
      }
    },
    buy(id) {
      if (parseFloat(this.pic) < parseFloat(id.price)) {
        Dialog.confirm({
          message: this.$t("jjbuy.title22"),
          confirmButtonText: this.$t("jjbuy.title14"),
          cancelButtonText: this.$t("jjbuy.title15"),
        })
          .then(() => {
            // on confirm
            // console.log(1);
            this.$router.push("/recharges?entrance=yuebao");
          })
          .catch(() => {});
      } else {
        Dialog.confirm({
          message: this.$t("jjbuy.title13"),
          confirmButtonText: this.$t("jjbuy.title14"),
          cancelButtonText: this.$t("jjbuy.title15"),
        })
          .then(() => {
            // on confirm
            // console.log(1);
            setTimeout(() => {
              this.getyuebaobuy(id.id);
            }, 500);
          })
          .catch(() => {});
      }
    },
    async getback_money() {
      if (this.isend != 2) {
        Toast({
          message: this.$t("newhome.title2"),
          duration: 1200,
          className: "noticeWidth", // 所加样式名
        });
        return;
      }

      const res = await getback_money();
      Toast({
        message: res.msg,
        duration: 1200,
        className: "noticeWidth", // 所加样式名
      });
      if (res.code == 1) {
        setTimeout(() => {
          this.$router.go(0);
        }, 1500);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.product {
  background-color: #f5f6fa;
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  padding-bottom: 5.8rem;
  width: 100%;
  min-height: 100vh;

  .product-title {
    position: relative;
    background: linear-gradient(135deg, #fe6719 0%, #feaa19 100%);
    background-size: 100% 20rem;
    border-radius: 0rem 0rem 3.5rem 3.5rem;
    background-repeat: no-repeat;
    padding: 0rem 1.6rem;
    padding-top: 2.5rem;
    height: 20rem;
    display: flex;
    justify-content: space-between;

    .yueBox {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      height: 5rem;

      .yue-title {
        font-size: 1.6rem;
        color: #fff;
        text-align: center;
      }

      .yue-num {
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        color: #fff;
        margin-top: 1.3rem;
      }
    }

    .czbox {
      position: absolute;
      bottom: -5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
      height: 8rem;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;

      .grid-img {
        width: 2.5rem;
        height: 2.5rem;
      }

      .grid-text {
        margin-top: 0.5rem;
        font-size: 1.4rem;
        color: #000;
        font-weight: bold;
      }
    }
    // align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      color: #fff;
    }
  }
  ::v-deep .van-tabs__wrap {
    border-radius: 3rem;
  }
  ::v-deep .van-tab {
    line-height: 2rem;
  }
  ::v-deep .van-tab__text--ellipsis {
    font-size: 1.6rem;
  }
  ::v-deep .van-tabs__line {
    width: 4rem;
    height: 0.2rem;
  }
  .Content {
    position: relative;
    padding: 1.6rem;
    width: 100%;
    // height: 39.1rem;
    background: linear-gradient(180deg, #ffffff 0%, #ffeadf 100%);
    // margin-top: 2.7rem;
    border-radius: 1rem;
    // padding-bottom: 1.55rem;
    margin-bottom: 2rem;
    box-shadow: 0px 2px 3px rgba(70, 64, 244, 0.16);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .img {
        display: flex;
        align-items: center;
        img {
          width: 5rem;
          height: 5rem;
          // background-color: red;
          border-radius: 0.8rem;
          vertical-align: middle;
          margin-right: 1rem;
          border-radius: 50%;
        }
        .newtitle {
          font-size: 1.3rem;
          font-weight: 600;
          .green {
            margin-top: 1rem;
          }
        }
      }
      .Content-title {
        background-color: #fe6719;
        border-radius: 2rem;
        padding: 0.8rem 2rem;
        font-size: 1.3rem;
        color: #fff;
      }
    }

    .Content-info {
      width: 100%;
      border-radius: 4px 4px 4px 4px;
      border: 1.5px solid #d9d9d9;
      display: flex;
      flex-direction: column;
      // align-items: center;
      margin-top: 1.5rem;
      padding: 1rem 1rem;
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        font-size: 1.3rem;
        color: #a7a7a7;
      }
    }
    .continfobox {
      position: absolute;
      left: 50%;
	  bottom: -3.2rem;
      transform: translateX(-50%);
      height: 4rem;
      line-height: 4rem;
      background: #fe6719;
      font-size: 1.3rem;
      border-radius: 0.5rem;
      margin-top: 1.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
    }

    .tips {
      color: #fb8815;
      font-size: 1.1rem;
      margin-left: 1.3rem;
      margin-top: 1.5rem;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin: 0rem 1.3rem;
      margin-top: 1.75rem;
      .left,
      .right {
        width: 14.9rem;
        height: 4.4rem;
        background-color: #fe6719;
        border-radius: 0.7rem;
        text-align: center;
        line-height: 4.4rem;
        color: #fff;
        font-size: 1.3rem;
        font-weight: 600;
      }
      .right {
        background-color: #fb8815;
      }
    }
  }
  ::v-deep .van-action-sheet {
    background-color: #1f1f1f;
    padding: 0rem 1.6rem;
    .title {
      margin-top: 2rem;
    }
    .power {
      padding: 1.6rem 0rem;
      display: flex;
      justify-content: space-between;
      color: rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      font-size: 1.2rem;
      .Price {
        font-size: 1.4rem;
        color: #fff;
      }
    }
    .agreement {
      display: flex;
      color: #fff;
      font-size: 1.2rem;
      margin-top: 1.9rem;
      .Agree {
        margin-left: 1.3rem;
      }
    }
    // ::v-deep .van-radio__icon {
    //   width: 2rem;
    //   height: 2rem;
    //   border: 1px solid #787373;
    //   margin-right: 1.2rem;
    //   margin-top: 0.3rem;
    // }
    .btn {
      width: 34.3rem;
      height: 4.4rem;
      background-color: #fe6719;
      margin: 0 auto;
      margin-top: 20rem;
      border-radius: 2.75rem;
      text-align: center;
      line-height: 4.4rem;
      color: #fff;
      margin-bottom: 5rem;
    }
  }
}
</style>
