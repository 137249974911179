<template>
<!-- 签到 -->
  <div class="SignIn" >
    <div style="background-image: linear-gradient(to right,#FE6719,#FEAA19 );padding: 0 2rem;padding-bottom: 2rem;
    height: 25rem;border-radius: 0 0 8rem 8rem;">
      <div class="SignIn-title">
        <van-icon
          name="arrow-left"
          size="2rem"
          color="#fff"
          @click="$router.back()"/>
        <div class="title">{{$t('SignIn.title')}}</div>
        <div class="img" @click="toCheckinRecord">
          <img src="../assets/record.png" alt="" />
        </div>
      </div>
      <div class="SignIn-money">
        <div class="t-f">
           <div class="tt">
              <img src="" alt="" />
           </div>
           <div class="qd">
              {{$t('SignIn.txt1')}} 0 {{$t('SignIn.txt2')}}
           </div>
        </div>
        <div class="mm">
          <div class="moneyimg">
            <img src="../assets/Venus.png" alt="" />
          </div>
          <div class="money" >{{money||'0'}}</div>
        </div>
      </div>
      <!-- <div class="Gotten">{{$t('SignIn.profit')}}</div> -->
    </div>
    <div class="SignIn-content">
      <div class="content-box">
          <div class="box" :class="{bgcolor:bgcolor>=1}">
          <div class="day">{{$t('SignIn.SignIn1')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
          <div class="box" :class="{bgcolor:bgcolor>=2}">
          <div class="day">{{$t('SignIn.SignIn2')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
          <div class="box" :class="{bgcolor:bgcolor>=3}">
          <div class="day">{{$t('SignIn.SignIn3')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
          <div class="box" :class="{bgcolor:bgcolor>=4}">
          <div class="day">{{$t('SignIn.SignIn4')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
          <div class="box" :class="{bgcolor:bgcolor>=5}">
          <div class="day">{{$t('SignIn.SignIn5')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
          <div class="box" :class="{bgcolor:bgcolor>=6}">
          <div class="day">{{$t('SignIn.SignIn6')}}</div>
          <div class="img">
            <img src="../assets/Venus.png" alt="" />
          </div>
        </div>
        <div class="Seven" :class="{bgSeven:bgcolor>=7}" style="padding: 0.5rem;align-items: center;">
          <div class="info" style="text-align: center;">
            <div class="tian" style="width: 100%;">
              <div class="day" style="width: 100%;">{{$t('SignIn.SignIn7')}}</div>
              <!-- <div style="font-size: 1rem;">Mysterious package</div> -->
            </div>
            <div class="title" style="margin-top: 0;">{{$t('addmy.sboyi')}}<br />{{$t('addmy.sboer')}}</div>
          </div>
          <div class="img">
            <img src="../assets/gift.png" alt="" />
          </div>
        </div>
      </div>
      <div class="button">
        <button class="btn" @click="receive">{{chick}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getSignin, getreceive } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      bgcolor: 0,
      day: 0,
      money: '',
      chick: this.$t('SignIn.Sign'),
      status: 0
    }
  },
  created () {
    // this.Signin()
  },
  methods: {
    // 签到天数
    async Signin () {
      const res = await getSignin()
      console.log('签到', res)
      this.bgcolor = res.data.sign_count
      this.money = res.data.sign_income.toFixed(2)
      this.status = res.data.status
      if (res.data.status === 0) {
        this.chick = this.$t('SignIn.Sign')
      } else {
        this.chick = this.$t('SignIn.chick')
      }
    },
    // 签到按钮
    async receive () {
      const res = await getreceive()
      // console.log('777', res)
      if (res.code === 1) {
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.Signin()
      } else {
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
      }
    },
    // 去签到记录
    toCheckinRecord () {
      this.$router.push('/checkinrecord')
    }
  }
}
</script>

<style lang="less" scoped>
.SignIn {
  background-color: #F5F6FA;
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  background-size: cover;
  // padding: 0rem 1.6rem;
  // padding-top: 2.5rem;
  width: 100%;
  min-height: 100vh;
  .SignIn-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #fff;
      font-size: 1.6rem;
    }
    .img {
      width: 1.55rem;
      height: 1.75rem;
    }
  }
  .SignIn-money {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    .t-f{
      display: flex;
      align-items: center;
      .tt{
        width: 5rem;
        border-radius: 50%;
        height: 5rem;
        background-color: #ffffff;
      }
      .qd{
        margin-left: 10px;
        font-size: 1.3rem;
        color: #ffffff;
      }
    }
    .mm{
      display: flex;
      align-items: center;
      .moneyimg {
        width: 3rem;
        height: 3rem;
        margin-right: 0.6rem;
      }
      .money {
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
      }
    }

  }
  .Gotten {
    color: rgba(255, 255, 255, 0.4);
    font-size: 1.3rem;
    margin-top: 1.3rem;
    text-align: center;
  }
  .SignIn-content {
    width: 34.3rem;
    height: 29.15rem;
    background-color: #Ffffff;
    margin-top: -10rem;
    margin-left: 1.5rem;
    padding: 1.7rem 1rem;
    border-radius: 1rem;
    .content-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box,
      .bgcolor {
        width: 7.4rem;
        height: 9rem;
        background-color: #F4F6F8;
        text-align: center;
        margin-bottom: 0.9rem;
        border-radius: 0.5rem;
        color: #333;
        .day {
          // color: #333333;
          font-weight: 600;
          margin-top: 1rem;
        }
        .img {
          width: 5rem;
          height: 5rem;
          margin: 0 auto;
          margin-top: 1.1rem;
        }
      }
      .bgcolor {
        background-color: #f68520;
        color: #fff !important;
      }
      .Seven,
      .bgSeven {
        position: relative;
        width: 15.7rem;
        height: 9rem;
        background-color: #D5D5D5;
        padding: .8rem;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        border-radius: 0.5rem;
        .info {
          font-size: 1.2rem;
          color: #333333;
          font-weight: 600;
          .day {
            width: 4rem;
          }
          .tian {
            // display: flex;
            // justify-content: space-between;
          }
          .title {
            width: 10rem;
            color: #949494;
            margin-top: 1rem;
          }
        }
        .img {
          position: absolute;
          bottom: 2.2rem;
          right: 1rem;
          width: 33%;
          height: 5rem;
        }
      }
      .bgSeven {
        background-color: #f68520;
      }
    }
    .button {
      width: 100%;
      text-align: center;
      margin-top: 1.3rem;
      .btn {
        background-color: #f68520;
        border: none;
        width: 25.9rem;
        height: 4.4rem;
        border-radius: 2.2rem;
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
        font-weight: bold;
        line-height: 4.4rem;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 5.8rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    margin-top: 2.5rem;
    padding: 1.9rem 1.4rem;
    display: flex;
    justify-content: space-between;
    .bottom-left {
      display: flex;
      align-items: center;
      .bottom-img {
        width: 2rem;
        height: 2rem;
      }
      .bottom-title {
        color: #fff;
        margin-left: 1rem;
        font-size: 1.4rem;
      }
    }
    .bottom-right {
      width: 0.65rem;
      height: 1.1rem;
    }
  }
}
</style>
