import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/plugins/vant'
import '../public/less/media.less'
import 'vant/es/toast/style'
import '@/fontstyle/font.css'
// i8n国际化
import i18n from '@/i18n/index'

import Model from '@/common/Model'
// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
// 全局tabbar
import Tabbar from '@/components/Foot.vue'

// 复制粘贴板的插件
import VueClipboards from 'vue-clipboard2'
Vue.prototype.$Model = Model

Vue.use(VueLuckyCanvas)
// 图片的url前面拼接的地址
Vue.prototype.$imgUrl = 'https://mxxai.com'
Vue.component('TabBar', Tabbar)
Vue.use(VueClipboards)

Vue.config.productionTip = false

localStorage.setItem('cookie', 'think_var=en-us')

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
