<template>
  <div class="level">
    <div class="header">
      <van-nav-bar
        :title="$t('vip.txt1')"
        left-arrow
        @click-left="$router.back()"
      />
    </div>

    <div class="content" v-if="user">
      <div class="box1">
        <div class="left">
          <div class="text1">VIP V{{ user.level }}</div>
          <div class="text2">{{ $t('vip.txt4') }}</div>
        </div>
        <div class="right">
          <img src="../assets/level.png" alt="" />
        </div>
      </div>
      <div class="title">VIP {{ $t("vip.txt3") }}</div>

      <div class="box2">
        <div class="title">
          <div class="left">{{ $t("vip.txt2") }}</div>
          <div class="right">{{ $t("vip.txt3") }}</div>
        </div>
        <div class="level-item" v-for="item in levelList">
          <div class="left">{{ item.name }}</div>
          <div class="right">{{ item.bate }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { myuserapi } from "../api/index";
export default {
  data() {
    return {
      levelList: [],
      user: undefined,
      bate:''
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const res = await myuserapi();
      this.levelList = res.data.level_list;
      this.user = res.data.user
      this.levelList.forEach((i) => {
        if (i.level == this.user.level) {
            this.bate = i.bate
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.level {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;

  .content {
    padding: 0 2rem;
    flex: 1;

    .box1 {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding: 1.5rem;
      margin-top: 3.5rem;
      height: 15rem;
      width: 100%;
      border: 2px solid #ffffd9;
      border-radius: 1.2rem;

      .left {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: start;

        .text1 {
          font-weight: bold;
          font-size: 2.5rem;
        }

        .text2 {
          margin-top: 0.5rem;
          font-size: 1.2rem;
          color: #B1B1B1;
        }
      }

      .right {
        width: 12rem;
        height: 12rem;
      }
    }

    .title {
      margin: 1.5rem 0;
      margin-left: 1rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #fff;
    }
    .box2 {
      padding: 0 2rem;
      border: 2px solid #ffffd9;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .level-item {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
        padding: 1.2rem 0;
        font-size: 1.4rem;

        .left {
          font-weight: bold;
        }

        .right {
          color: #fe6719;
        }
      }

      .level-item:last-child {
        border: unset;
      }
    }
  }
}
</style>
