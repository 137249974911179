<template>
  <!-- 公告 -->
  <div class="Announcement">
    <div class="Announcement-title">
      <van-icon
        name="arrow-left"
        size="2rem"
        color="#000"
        @click="$router.back()"
      />
      <div class="title">{{$t('gonggao.title')}}</div>
      <div></div>
    </div>
    <div v-if="id==0">
      <div class="info" v-for="(i, v) in gonggaolist" :key="v" @click="todetails(i.id)">
          <div class="left">
            <div class="left-title">{{i.title}}</div>
            <p class="time">{{ i.createtime }}</p>
          </div>
          <div class="right">
            <img src="../assets/RightArrow.png" alt="" />
          </div>
      </div>
    </div>
    <div v-if="id==1">
      <div class="info" v-for="(i, v) in gonggaolist" :key="v" @click="todetails(i.id)" v-if="i.type == 2 ">
          <div class="left">
            <div class="left-title">{{i.title}}</div>
            <p class="time">{{ i.createtime }}</p>
          </div>
          <div class="right">
            <img src="../assets/RightArrow.png" alt="" />
          </div>
      </div>
    </div>

    <!-- <div class="suoyou">
      <img src="../assets/suoyou.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import { Noticelistapi } from '@/api/index'
export default {
  name: 'AnnounCement',
  data () {
    return {
      gonggaolist: [],
      id:''
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.Noticelist()
  },
  methods: {
    async Noticelist () {
      const res = await Noticelistapi()
      // console.log(res)
      this.gonggaolist = res.data
    },
    // 去公告详情
    todetails (id) {
      // const data = {
      //   id: id
      // }
      this.$router.push({
        path: '/gonggaodetails',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.Announcement {
  // background: url('../assets/ampbk.png') no-repeat;
  // background-size: 100% 25rem;
  // background-size: cover;
  padding: 0rem 1.6rem;
  padding-bottom: 4rem;
  // padding-top: 2.5rem;
   width: 100%;
  min-height: 100vh;
  .Announcement-title {
     padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
     z-index: 9 !important;
    .title {
      color: #000;
      font-size: 1.8rem;
    }
    ::v-deep .van-icon-arrow-left:before {
      margin-top: 0.4rem;

    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #CFCFCF;
    .left {
      color: #000;
      font-size: 1.2rem;
      .left-title {
        width: 28.5rem;
      }
      .time {
        font-size: .9rem;
        margin-top: 1.5rem;
        color: #858585;
      }
    }
    .right {
      width: 1rem;
      height: 1.1rem;
      line-height: 7rem;
      margin-top: -1.3rem;
    }
  }
  // .suoyou {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 1;
  // }
}
</style>
