<template>
    <div class="HistoricalLottery">
        <!-- 自定义nav-->
        <div class="Phonenumber-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                color="#000"
                @click="$router.go(-1)" />
            <div class="title">{{$t('addgift.rtitle')}}</div>
            <div></div>
        </div>
        <!-- 内容-->
        <!-- <div class="tab">
          <div v-html="rulelist"></div>
        </div> -->
        <div class="upItem1">
           <div class="i-l">
             <div class="us">USDT available balance</div>
             <div class="ds">6.25</div>
           </div>
           <div class="i-r">
             <div class="uu"><div class="btb"><img src="../assets/btb.png" alt="" class="img"/></div><div class="to">Today's price</div></div>
             <div class="dd">1USDT : 32.2TWD</div>
           </div>
        </div>
        <div class="pp">
          <p class="p1">Platform account</p>
          <p class="p1">China Trust（822）</p>
          <p class="p">Account number 5611523656</p>
        </div>
        <div class="pp">
            <p class="p1">Transfer to account</p>
            <p class="p1">Account Name</p>
            <p class="p1">HUA NAN BANK（008）</p>
            <p class="p1">Branch</p>
            <p class="p">Account number 1561655631</p>
        </div>
        <div class="tts">
           <p class="tt">Withdrawal amount</p>
        </div>
        <div class="tx">
          <div class="t-f">
            <div class="t-l">1000</div>
            <div class="t-r">TWD</div>
          </div>
          <div class="t-f">
            <div class="t-l">1000</div>
            <div class="t-r">USDT</div>
          </div>
        </div>
        <div class="confirm" @click="confirmshow">
          {{ $t("Recharge.confirm") }}
        </div>
    </div>
  </template>

  <script>
  import { prop,teamdetailsapi } from '@/api/index'
  import { Toast } from 'vant'
  export default {
    name: 'RuleDisplay',
    data() {
      return {
        rulelist: ''
      }
    },
    created() {
      this.getrule()
    },
    methods: {
      async getrule() {
        const res = await teamdetailsapi()
        // const url = '/api/base/prop'
        // await teamdetailsapi().then(res=>{
        //   if(res.code == 1){
        //     this.rulelist = res.data.game_rule
        //     // console.log(this.rulelist)
        //   }else{
        //     Toast({
        //       message: res.msg,
        //       duration: 1200,
        //       className: 'noticeWidth' // 所加样式名
        //     })
        //   }
        // })
      }
    }
  }
  </script>

  <style lang="less" scoped>
  .HistoricalLottery{
    background-color:#ffffff;
    min-height: 100vh;
    padding: 0 1.5rem;
    .Phonenumber-title {
        padding: 1.5rem 0rem 0rem 0rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.5rem;
        .title {
          color: #000;
          font-size: 1.8rem;
          // margin-left: 9rem;
          font-weight: 700;
        }
        ::v-deep .van-icon-arrow-left:before {
          margin-top: 0.3rem;
        }
    }
    .upItem1{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      background: linear-gradient( 133deg, #F6C4B0 0%, #FE6719 100%);
      border-radius: 1.2rem;
      margin-bottom: 2rem;
      .i-l{
        .us{
          font-size: 1.2rem;
          color: #ffffff;
          margin-bottom: 2rem;
        }
        .ds{
          text-align: center;
           font-size: 1.2rem;
            color: #FFC67D;
        }
      }
      .i-r{
        .uu{
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          .btb{
            margin-right: .6rem;
            .img{
              width: 2rem;
              height: 2rem;
            }
          }
          .to{
            font-size: 1.2rem;
            color: #ffffff;
          }
        }
        .dd{
          font-size: 1.4rem;
          color: #FFC67D;
        }
      }
    }
    .pp{

     background: #FFFFFF;
     box-shadow: 0px 3px 12px 1px rgba(66,91,141,0.2);
      border-radius: 1.2rem;
      padding: 2rem;
      margin-bottom: 2rem;
      .p1{
        font-size: 1.4rem;
        margin-bottom: 1.4rem;
      }
      .p{
        font-size: 1.4rem;
      }
    }
    .tts{
       .tt{
         font-size: 1.6rem;
         font-weight: bold;
         margin-bottom: 1rem;
       }
    }
    .tx{
      .t-f{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:1.5rem 1rem;
        background: #FFFFFF;
        box-shadow: 0px 3px 12px 1px rgba(66,91,141,0.2);
        margin-bottom: 1rem;
        border-radius: 1rem;
      }
    }
    .confirm {
      width: 100%;
      height: 4.4rem;
      background-color: #FE6719;
      margin: 5rem auto 0 auto;
      border-radius: 1rem;
      text-align: center;
      line-height: 4.4rem;
      font-size: 1.4rem;
      color: #fff;
      font-weight: 600;
    }
    .tab{
      margin: 2rem;
    }
  }
  </style>
