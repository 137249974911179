<template>
    <div class="RechargeTwo">
        <div class="Addressdetails-title">
            <van-icon
                name="arrow-left"
                size="2rem"
                color="#ffffff"
                @click="$router.back()"
            />
            <div class="title">{{ title }}</div>
            <div></div>
        </div>
        <!-- 内容-->
        <div class="body">

          <div class="tips">
            <img class="tipsicon" src="../assets/tips_icon.png" alt="">
            <div class="tips_desc">{{$t('addbank.tips')}}</div>
          </div>

          <div class="cont">
            <div class="add">
              <div class="tips2">
                {{ $t('addbank.tips2') }}
              </div>
               <div class="ewms">
                  <div class="ewm"></div>
               </div>
               <div class="address-t">{{ $t('addbank.address_t') }}</div>
               <div class="address">{{ $t('addbank.address') }}</div>
               <div class="copy">
                  <div class="img">
                    <img src="./pic/220.png" alt="" style="width: 17px;height: 17px;"/>
                  </div>
                  <div class="tt">{{ $t('addbank.copy') }}</div>
               </div>
            </div>
            <div class="pp">
              <p class="p1">{{$t('addbank.reminder')}}</p>
              <p class="p2">{{ $t('addbank.txt1') }}</p>
              <p class="p2">{{ $t('addbank.txt1') }}</p>
            </div>
          </div>
          <div class="xian"></div>
          <div class="pingz">
              <!-- <div class="pingztitle">{{$t('rechargettwo.qtjczjt')}}</div> -->
              <div class="pingzbody">
                  <van-uploader :after-read="afterRead" v-if="imgurl == ''">
                      <!-- <van-button icon="plus" type="primary">上传文件</van-button> -->
                      <div><img src="./pic/230.png" alt="" style="width: 47px;height: 40px;margin-bottom: 2rem;"/></div>
                  </van-uploader>
                  <img v-if="imgurl != ''" :src="imgurl" style="width: 100%;height: 100%;"/>
              </div>
              <div class="bei">{{$t('addbank.txt3')}}</div>
              <div class="pingzbutton" @click="topostoffl">{{$t('addbank.btn')}}</div>
          </div>
        </div>
    </div>
</template>

<script>
import { getoffline, uploadimg, postoffline } from '@/api/index'
import { Toast } from 'vant'
export default {
  data () {
    return {
      title: this.$t('addbank.banktitle'),
      qqdata: {
        pay_id: '',
        entrance: '',
        money: '',
        image: '',
        order_no: '',
        type: 2
      },
      fileList: [],
      offlinearr: '',
      imgurl: ''
    }
  },
  created () {
    this.qqdata.pay_id = this.$route.query.pay_id
    this.qqdata.entrance = this.$route.query.entrance
    this.qqdata.money = this.$route.query.money
    this.qqdata.order_no = this.$route.query.order_no
    // console.log(this.$route.query)
    // this.getoffl()
  },
  methods: {
    async afterRead (file) {
    //   const formData = new FormData()
    //   formData.append('file', file.file)
    //   console.log(formData.get('file'))
    //   this.files = file.file
      const formData = new FormData()
      formData.append('file', file.file)
      const res = await uploadimg(formData)
      this.imgurl = res.data.fullurl
      this.qqdata.image = res.data.url
      console.log(res, 'img')
    },
    async getoffl () {
      const url = '/api/recharge/offline?pay_id=' + this.qqdata.pay_id + '&money=' + this.qqdata.money
      // console.log(data)
      const res = await getoffline(url)
      this.offlinearr = res.data
      console.log(res)
    },
    async topostoffl () {
      if (this.qqdata.image == '') { // eslint-disable-line
        Toast({
          message: this.$t('addbank.tssan'),
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        return false
      }
      const res = await postoffline(this.qqdata)
      console.log(res, 'post')
      if (res.code == 1) { // eslint-disable-line
        Toast({
          message: res.msg,
          duration: 1200,
          className: 'noticeWidth' // 所加样式名
        })
        this.$router.push('/record')
      }
    },
    share (e) { // 复制
      setTimeout(()=>{
		  this.$copyText(e).then()
		  Toast({
		    message: this.$t('Charge.Success'),
		    duration: 1200,
		    className: 'noticeWidth' // 所加样式名
		  })
	  },500)
    }
  }
}
</script>

<style lang="less" scoped>
.RechargeTwo{
    color: #000;
    // background-image: url("../assets/indexback.png");
    background-color: #009A6A;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: #ffffff;
    .Addressdetails-title {
        padding-top: 1.5rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 2rem;
        .title {
            font-size: 1.8rem;
            font-weight: 700;
        }
        ::v-deep .van-icon-arrow-left:before {
        margin-top: 0.4rem;
        // color: red;
        }
    }
    .body{
        margin: 0 2rem;
        font-size: 1.5rem;

        .tips {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          height: 3.5rem;
          width: 100%;
          border-radius: .8rem;
          background-color: #ff6b1e;
          opacity: 0.38;
          padding: 0 1.8rem;
          .tipsicon {
            width: 1.8rem;
            height: 1.8rem;
          }
          .tips_desc {
            margin-left: 1rem;
            color: #FFF16E;
            font-size: 1.3rem;
          }
        }

        .cont{
           .add{
            padding-top: 3.5rem;
             background-color: #ffffff;
             border-radius: 3rem;
             text-align: center;
             margin-bottom: 2rem;
             .tips2 {
              color: #919191;
              font-size: 1.3rem;
             }
             .ewms{
               padding: 3rem 0;
               width: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
                .ewm{
                  width: 11rem;
                  height: 11rem;
                  background-color: #000000;
                  border-radius: 1rem;
                }
             }
             .address-t {
              color: #919191;
              font-size: 1.3rem;
             }
             .address{
               font-size: 1rem;
               color: #000000;
                margin: 0rem 0 3rem 0;
             }
             .copy{
               padding: 1.5rem 0;
               display: flex;
               align-items: center;
               justify-content: center;
               background-color: #EDF9F2;
               border-radius:0  0 3rem 3rem;
               color: #000000;
               .tt{
                 margin-left: 0.5rem;
                 font-size: 1.5rem;
               }
             }
           }
           .pp{
             .p1{
               margin-bottom: 2rem;
             }
             .p2{
               color: rgba(255, 255, 255, .6);
                font-size: 1.2rem;
                margin-bottom: 2rem;
             }
           }
        }


        .content{
            .contentone{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 1rem;
                .rightflex{
                    display: flex;
                    font-size: 1.2rem;
                    color: #333;
                    .rightimg{
                        width: 2rem;
                        margin-left: 1rem;
                    }
                    .bule{
                        color: #40B3D2;
                    }
                }
            }
        }
        .xian{
            // border-bottom: 1px solid #393A48;
            margin: 2rem 0;
            margin-top: 3rem;
        }
        .pingz{
          width: 100%;
            .pingztitle{
                color: #333;
                font-size: 1.5rem;
                font-weight: 700;
                margin-bottom: 2rem;
            }
            .pingzbody{
                width: 15rem;
                height: 12rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #F4F6F8;
                font-size: 9rem;
                font-weight: 700;
                border-radius: 3rem;
                margin: 0 auto;
            }
            .bei{
              font-size: 1.2rem;
              text-align: center;
              color: #ffffff;
              margin: 2rem 0 5rem 0;
            }
            .pingzbutton{
                width: 100%;
                background: #ffffff;
                color: #000;
                display: flex;
                justify-content: center;
                border-radius: 1rem;
                padding: 1.5rem 0;
                margin-top: 3rem;
            }
        }
        .bodyimg{
            width: 10rem;
            margin: 5rem 0;
            margin-left: 12rem;
        }
    }
}
.contentone div:nth-child(1){
    color: #8D8D8D;
}
</style>
