import { render, staticRenderFns } from "./Announcement.vue?vue&type=template&id=2fa56edf&scoped=true&"
import script from "./Announcement.vue?vue&type=script&lang=js&"
export * from "./Announcement.vue?vue&type=script&lang=js&"
import style0 from "./Announcement.vue?vue&type=style&index=0&id=2fa56edf&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa56edf",
  null
  
)

export default component.exports